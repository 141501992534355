import { ChangeEventHandler, FocusEventHandler } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import styles from "./ChildAgeSelect.module.css";
import Select, { Option } from "./Select";

interface ChildAgeProps {
  childIndex: number;
  options: Option[];
  selectProps: {
    name: string;
    disabled?: boolean;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
    onBlur?: FocusEventHandler<HTMLSelectElement>;
  };
}

const ChildAgeSelect = ({
  options,
  childIndex,
  selectProps,
}: ChildAgeProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.childrenAgeWrapper}>
      <Select
        label={i18n.childAgeSelect.childrenAge(childIndex + 1)}
        className={styles.childrenAgeSelect}
        classNameIcon={styles.childrenAgeSelectIcon}
        placeholder={i18n.childAgeSelect.selectAge}
        placeholderValue={-1}
        options={options}
        {...selectProps}
      />
    </div>
  );
};

export default ChildAgeSelect;
