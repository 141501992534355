import { AutoOccupancy, Occupancy, RoomConfiguration } from "../@types";

export const adultsSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[adults]`
    : `occupancies[${occupancyIndex}][adults]`;

export const childrenSearchParamKey: {
  (occupancyIndex: number, childrenIndex: number): string;
  (childrenIndex: number): string;
} = (occupancyIndex: number, childrenIndex?: number): string => {
  if (childrenIndex === undefined) {
    return `auto_occupancy[children][${occupancyIndex}]`;
  }
  return `occupancies[${occupancyIndex}][children][${childrenIndex}]`;
};

const roomTypeIdSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[room_type_id]`
    : `occupancies[${occupancyIndex}][room_type_id]`;

const ratePlanIdSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[rate_plan_id]`
    : `occupancies[${occupancyIndex}][rate_plan_id]`;

const boardTypeSearchParamKey = (occupancyIndex?: number): string =>
  occupancyIndex === undefined
    ? `auto_occupancy[board_type]`
    : `occupancies[${occupancyIndex}][board_type]`;

interface SetOccupancySearchParamsArguments {
  autoOccupancy?: AutoOccupancy | null | undefined;
  occupancies?: Occupancy[] | undefined;
  roomTypeId?: string | null | undefined;
  ratePlanId?: string | null | undefined;
}
export const setOccupancySearchParams = (
  searchParams: URLSearchParams,
  {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  }: SetOccupancySearchParamsArguments,
) => {
  if (autoOccupancy) {
    const occupancy = autoOccupancy;
    if (!occupancy.adults) {
      return;
    }

    searchParams.set(adultsSearchParamKey(), occupancy.adults.toString());
    occupancy.children.forEach((childAge, j) => {
      searchParams.set(childrenSearchParamKey(j), childAge.toString());
    });
  } else {
    occupancies?.forEach((occupancy, i) => {
      if (!occupancy.adults) {
        return;
      }

      searchParams.set(adultsSearchParamKey(i), occupancy.adults.toString());
      occupancy.children.forEach((childAge, j) => {
        searchParams.set(childrenSearchParamKey(i, j), childAge.toString());
      });

      if (roomTypeId) {
        searchParams.set(roomTypeIdSearchParamKey(i), roomTypeId);
      }

      if (ratePlanId) {
        searchParams.set(ratePlanIdSearchParamKey(i), ratePlanId);
      }
    });
  }
};

interface SetOccupancySearchParamsArgumentsByRoomConfigurations {
  roomConfigurations: RoomConfiguration[];
  roomTypeId?: string | null | undefined;
  ratePlanId?: string | null | undefined;
}
export const setOccupancySearchParamsByRoomConfigurations = (
  searchParams: URLSearchParams,
  {
    roomConfigurations,
    roomTypeId,
    ratePlanId,
  }: SetOccupancySearchParamsArgumentsByRoomConfigurations,
) => {
  roomConfigurations.forEach((roomConfiguration, i) => {
    if (!roomConfiguration.adults) {
      return;
    }

    searchParams.set(
      adultsSearchParamKey(i),
      roomConfiguration.adults.toString(),
    );
    roomConfiguration.children?.forEach((childAge, j) => {
      searchParams.set(childrenSearchParamKey(i, j), childAge.toString());
    });

    const roomTypeIdParam = roomTypeId ?? roomConfiguration.roomTypeId;
    if (roomTypeIdParam) {
      searchParams.set(roomTypeIdSearchParamKey(i), roomTypeIdParam);
    }
    const ratePlanIdParam = ratePlanId ?? roomConfiguration.ratePlanId;
    if (ratePlanIdParam) {
      searchParams.set(ratePlanIdSearchParamKey(i), ratePlanIdParam);
    }
    if (roomConfiguration.boardType) {
      searchParams.set(boardTypeSearchParamKey(i), roomConfiguration.boardType);
    }
  });
};
