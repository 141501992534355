import { Controller } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/room";
import { translate } from "../../i18n";
import NumberInput from "../../ui/NumberInput";
import { site } from "../../utils/site";
import CollapsibleRoomOccupancy from "./CollapsibleRoomOccupancy";
import OccupancyChildren from "./OccupancyChildren";
import styles from "./OccupancyRoom.module.css";
import { useOccupancyFormContext } from "./utils";

interface OccupancyRoomProps {
  open: boolean;
  index: number;
  disabled?: boolean;
  roomType: Room | undefined;
  onOpen: (index: number) => void;
  onDelete: () => void;
}

const OccupancyRoom = ({
  open,
  index,
  disabled = false,
  roomType,
  onOpen,
  onDelete,
}: OccupancyRoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { setValue, control, watch, formState } = useOccupancyFormContext();
  const visualIndexValue = index + 1;
  const [occupancies, adults, children] = watch([
    "occupancies",
    `occupancies.${index}.adults`,
    `occupancies.${index}.children`,
  ]);

  const minOccupancy = roomType?.occupancy.min ?? 1;
  const maxOccupancy = roomType?.occupancy.max ?? site.max_guests_per_room;

  const minAdults = Math.max(minOccupancy - children.length, 1);
  const maxAdults = maxOccupancy - children.length;
  const minChildren = Math.max(
    site.children_allowed ? minOccupancy - adults : 0,
    0,
  );
  const maxChildren = Math.max(
    site.children_allowed ? maxOccupancy - adults : 0,
    0,
  );

  const guestCount = adults + children.length;
  const roomSummaryParts: string[] = [];
  if (!open && guestCount > 0) {
    roomSummaryParts.push(i18n.occupancy.guestsCountSummary(guestCount));
  }
  if (roomType) {
    roomSummaryParts.push(roomType.name);
  }

  return (
    <CollapsibleRoomOccupancy
      index={index}
      open={open}
      onOpen={onOpen}
      openText={i18n.occupancy.roomIndex(site.room_term, visualIndexValue)}
      toggleDisabled={!formState.isValid}
      summary={
        roomSummaryParts.length > 0
          ? `: ${roomSummaryParts.join(", ")}`
          : undefined
      }
      onDelete={occupancies.length > 1 ? onDelete : false}
    >
      <div className={styles.content}>
        <Controller
          name={`occupancies.${index}.adults`}
          control={control}
          rules={{ min: minAdults, max: maxAdults }}
          render={({ field: { value } }) => (
            <NumberInput
              title={i18n.occupancy.adults}
              value={value}
              min={1}
              max={maxAdults}
              description={i18n.occupancy.adultsInfo}
              disabled={disabled}
              onChange={(adults) =>
                setValue(`occupancies.${index}.adults`, adults, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            />
          )}
        />
        {site.children_allowed && (
          <OccupancyChildren
            occupancyIndex={index}
            minChildrenCount={minChildren}
            maxChildrenCount={maxChildren}
            childrenMinAge={site.children_min_age}
            disabled={disabled}
          />
        )}
      </div>
    </CollapsibleRoomOccupancy>
  );
};

export default OccupancyRoom;
