import { Fieldset, Legend, RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FieldSetting } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useCheckoutFormStore } from "../../hooks/useCheckoutFormStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Gender, PrepaymentType } from "../../http/booking";
import { useCountries } from "../../http/country";
import { LegalTextType } from "../../http/legalTexts";
import { SelectedOfferResponse } from "../../http/offer";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Checkbox from "../../ui/Checkbox";
import FormError from "../../ui/FormError";
import FormSelect from "../../ui/FormSelect";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import Input from "../../ui/Input";
import Radio from "../../ui/Radio";
import Skeleton from "../../ui/Skeleton";
import Textarea from "../../ui/Textarea";
import { zeroPrice } from "../../utils/constants";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import { sanitize } from "../../utils/string";
import ExtraSuggestion from "../extra/ExtraSuggestion";
import { LegalTextMenuItem } from "../legalText/LegalTextMenuItem";
import styles from "./CheckoutForm.module.css";
import CheckoutVoucher from "./CheckoutVoucher";
import { CheckoutFormType, GuestType } from "./utils";

interface CheckoutFormProps {
  checkoutData: SelectedOfferResponse | null;
  isSubmitting: boolean;
}

const CheckoutForm = ({ checkoutData, isSubmitting }: CheckoutFormProps) => {
  const language = useBookingStore((state) => state.language);
  const updateData = useCheckoutFormStore((state) => state.actions.updateData);
  const { data: countries, isLoading: loadingCountries } = useCountries();
  const i18n = translate(language);

  const {
    register,
    formState: { errors },
    watch,
    control,
  } = useFormContext<CheckoutFormType>();

  useEffect(() => {
    const { unsubscribe } = watch((values) => {
      updateData(values);
    });
    return () => unsubscribe();
  }, [updateData, watch]);

  const insuranceModalIdentifier = `CheckoutForm_Insurance`;
  const setShowInsuranceModal = useModalHistoryToggle(insuranceModalIdentifier);
  const showInsuranceModal = useModalHistoryStore(
    isModalActive(insuranceModalIdentifier),
  );

  const reservationPoliciesModalIdentifier = `CheckoutForm_ReservationPolicies`;
  const setShowReservationPoliciesModal = useModalHistoryToggle(
    reservationPoliciesModalIdentifier,
  );
  const showReservationPoliciesModal = useModalHistoryStore(
    isModalActive(reservationPoliciesModalIdentifier),
  );

  const guestType = watch("general.guestType");

  return (
    <div className={styles.form}>
      <Headline
        as="h2"
        size={2}
        title={i18n.checkout.formHeadline}
        className={styles.headline}
      />
      <Fieldset className={styles.block}>
        <Legend className={styles.blockHeadline}>
          {i18n.checkout.yourData[site.guest_interaction]}
        </Legend>
        <div className={styles.fields}>
          <FormSelect
            label={i18n.checkout.gender.gender}
            isEmpty={!watch("guest.gender")}
            error={errors.guest?.gender}
            options={[
              { key: Gender.Male, value: i18n.checkout.gender[Gender.Male] },
              {
                key: Gender.Female,
                value: i18n.checkout.gender[Gender.Female],
              },
            ]}
            disabled={
              site.guest_field_settings.gender === FieldSetting.Disabled
            }
            required={
              site.guest_field_settings.gender === FieldSetting.Required
            }
            {...register("guest.gender", {
              required: {
                value:
                  site.guest_field_settings.gender === FieldSetting.Required,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            label={i18n.checkout.firstName}
            error={errors.guest?.first_name}
            isEmpty={!watch("guest.first_name")}
            autoComplete="given-name"
            required
            {...register("guest.first_name", {
              required: {
                value: true,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            label={i18n.checkout.lastName}
            error={errors.guest?.last_name}
            isEmpty={!watch("guest.last_name")}
            autoComplete="family-name"
            required
            {...register("guest.last_name", {
              required: {
                value: true,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            type="email"
            label={i18n.checkout.email}
            error={errors.guest?.email_address}
            isEmpty={!watch("guest.email_address")}
            autoComplete="email"
            required
            {...register("guest.email_address", {
              required: {
                value: true,
                message: i18n.general.form.errors.required,
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: i18n.general.form.errors.email[site.guest_interaction],
              },
            })}
          />
          <Input
            type="tel"
            label={i18n.checkout.phone}
            error={errors.guest?.phone_number}
            isEmpty={!watch("guest.phone_number")}
            autoComplete="tel"
            disabled={
              site.guest_field_settings.phone_number === FieldSetting.Disabled
            }
            required={
              site.guest_field_settings.phone_number === FieldSetting.Required
            }
            {...register("guest.phone_number", {
              required: {
                value:
                  site.guest_field_settings.phone_number ===
                  FieldSetting.Required,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            label={i18n.checkout.street}
            error={errors.guest?.street}
            isEmpty={!watch("guest.street")}
            autoComplete="street-address"
            disabled={
              site.guest_field_settings.street === FieldSetting.Disabled
            }
            required={
              site.guest_field_settings.street === FieldSetting.Required
            }
            {...register("guest.street", {
              required: {
                value:
                  site.guest_field_settings.street === FieldSetting.Required,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            label={i18n.checkout.postalCode}
            error={errors.guest?.postal_code}
            isEmpty={!watch("guest.postal_code")}
            autoComplete="postal-code"
            disabled={
              site.guest_field_settings.postal_code === FieldSetting.Disabled
            }
            required={
              site.guest_field_settings.postal_code === FieldSetting.Required
            }
            {...register("guest.postal_code", {
              required: {
                value:
                  site.guest_field_settings.postal_code ===
                  FieldSetting.Required,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          <Input
            label={i18n.checkout.municipality}
            error={errors.guest?.municipality}
            isEmpty={!watch("guest.municipality")}
            autoComplete="address-level2"
            disabled={
              site.guest_field_settings.municipality === FieldSetting.Disabled
            }
            required={
              site.guest_field_settings.municipality === FieldSetting.Required
            }
            {...register("guest.municipality", {
              required: {
                value:
                  site.guest_field_settings.municipality ===
                  FieldSetting.Required,
                message: i18n.general.form.errors.required,
              },
            })}
          />
          {loadingCountries && (
            <Skeleton desktopItems={1} mobileItems={1} layout="single" />
          )}
          {countries?.countries && (
            <FormSelect
              label={i18n.checkout.country}
              error={errors.guest?.country}
              isEmpty={!watch("guest.country")}
              autoComplete="country"
              options={countries.countries.map((country) => ({
                key: country.code,
                value: country.name,
              }))}
              disabled={
                site.guest_field_settings.country === FieldSetting.Disabled
              }
              required={
                site.guest_field_settings.country === FieldSetting.Required
              }
              {...register("guest.country", {
                required: {
                  value:
                    site.guest_field_settings.country === FieldSetting.Required,
                  message: i18n.general.form.errors.required,
                },
              })}
            />
          )}
        </div>
      </Fieldset>
      <Fieldset className={styles.block}>
        <Legend className={styles.blockHeadline}>
          {i18n.checkout.company.isBusinessTrip[site.guest_interaction]}
        </Legend>
        <Controller
          control={control}
          name="general.guestType"
          render={({ field }) => (
            <RadioGroup className={styles.radioButtons} {...field}>
              <Radio
                label={i18n.checkout.company.yes}
                value={GuestType.Company}
              />
              <Radio
                label={i18n.checkout.company.no}
                value={GuestType.Private}
              />
            </RadioGroup>
          )}
        />
      </Fieldset>
      {guestType === GuestType.Company && (
        <Fieldset className={clsx(styles.block, styles.smallSpacing)}>
          <Legend className={styles.blockHeadline}>
            {i18n.checkout.companyInvoiceData[site.guest_interaction]}
          </Legend>
          <div className={styles.companyFields}>
            <Input
              label={i18n.checkout.company.name}
              error={errors.company?.name}
              isEmpty={!watch("company.name")}
              autoComplete="billing organization"
              required
              {...register("company.name", {
                required: {
                  value: true,
                  message: i18n.general.form.errors.required,
                },
              })}
            />
            <Input
              label={i18n.checkout.company.vatNumber}
              error={errors.company?.vat_number}
              isEmpty={!watch("company.vat_number")}
              required
              {...register("company.vat_number", {
                required: {
                  value: true,
                  message: i18n.general.form.errors.required,
                },
              })}
            />
            <Input
              label={i18n.checkout.company.recipientCode}
              isEmpty={!watch("company.recipient_code")}
              {...register("company.recipient_code")}
            />
            <Input
              label={i18n.checkout.company.street}
              isEmpty={!watch("company.street")}
              autoComplete="billing street-address"
              {...register("company.street")}
            />
            <Input
              label={i18n.checkout.company.postalCode}
              isEmpty={!watch("company.postal_code")}
              autoComplete="billing postal-code"
              {...register("company.postal_code")}
            />
            <Input
              label={i18n.checkout.company.municipality}
              isEmpty={!watch("company.municipality")}
              autoComplete="billing address-level2"
              {...register("company.municipality")}
            />
            {countries?.countries && (
              <FormSelect
                label={i18n.checkout.company.country}
                isEmpty={!watch("company.country")}
                autoComplete="billing country"
                options={countries.countries.map((country) => ({
                  key: country.code,
                  value: country.name,
                }))}
                {...register("company.country")}
              />
            )}
          </div>
        </Fieldset>
      )}
      <Fieldset className={styles.block}>
        <Legend className={styles.blockHeadline}>
          {i18n.checkout.voucher[site.guest_interaction]}
        </Legend>
        <CheckoutVoucher />
      </Fieldset>
      {(checkoutData?.extra_suggestions.length ?? 0) > 0 && (
        <>
          <div className={clsx(styles.block, styles.extraBlock)}>
            <Headline
              as="div"
              size={3}
              title={i18n.checkout.extraSuggestions[site.guest_interaction]}
            />
          </div>
          {checkoutData?.extra_suggestions.map((extraSuggestion) => (
            <ExtraSuggestion
              key={extraSuggestion.id}
              extraSuggestion={extraSuggestion}
            />
          ))}
        </>
      )}
      <Fieldset className={styles.block}>
        <Legend className={styles.blockHeadline}>{i18n.checkout.note}</Legend>
        <Headline
          size={4}
          title={i18n.checkout.noteDescription[site.guest_interaction]}
        />
        <Textarea
          className={styles.noteField}
          label={i18n.checkout.noteLabel}
          isEmpty={!watch("guest.note")}
          {...register("guest.note")}
        />
      </Fieldset>
      <Fieldset className={styles.block}>
        <Legend className={styles.blockHeadline}>
          {i18n.checkout.paymentInfo}
        </Legend>
        <Headline
          size={4}
          title={i18n.checkout.paymentAmount(
            formatMoney(checkoutData?.payment.price ?? zeroPrice, language),
            checkoutData?.payment.percentage ?? 0,
          )}
        />
      </Fieldset>
      {checkoutData?.payment.type === PrepaymentType.Prepayment && (
        <Fieldset className={clsx(styles.block, styles.smallSpacing)}>
          <Legend className={styles.blockHeadline}>
            {`${i18n.checkout.paymentType}*`}
          </Legend>
          <Controller
            control={control}
            name="general.prepayment_method"
            rules={{
              required: {
                value: true,
                message: i18n.general.form.errors.required,
              },
            }}
            render={({ field: { ref, ...field }, fieldState }) => (
              <>
                {/*
                  headless ui does not put the ref on a focusable element (if its setting it at all),
                  hence focus on error is not possible as the ref element is not focusable.
                  for now -> dummy element for focus on error
                  better would probably be to add the ref on the first radio element,
                  but since we are looping, setting only on the first is not possible as setting the ref explicitly to undefined ref={undefined} is not allowed (typescript)
                  */}
                <span tabIndex={-1} ref={ref} />
                <RadioGroup
                  className={styles.radioButtons}
                  {...field}
                  aria-required
                  aria-invalid={!!fieldState.error}
                  aria-errormessage={
                    fieldState.error ? "prepayment_method_error" : undefined
                  }
                >
                  {checkoutData.payment.prepayment_methods.map((method) => (
                    <Radio
                      key={method}
                      label={i18n.checkout.paymentMethod[method]}
                      value={method}
                    />
                  ))}
                </RadioGroup>
                <FormError
                  id="prepayment_method_error"
                  errorMessage={fieldState.error?.message ?? ""}
                />
              </>
            )}
          />
        </Fieldset>
      )}
      {checkoutData?.insurance && (
        <Fieldset className={styles.block}>
          <Legend className={styles.blockHeadline}>
            {i18n.checkout.insurance}
          </Legend>
          <Controller
            control={control}
            name="general.insurance"
            render={({ field }) => (
              <Checkbox
                label={i18n.checkout.insuranceLabel(
                  checkoutData.insurance?.name ?? "",
                  formatMoney(
                    checkoutData.insurance?.price ?? zeroPrice,
                    language,
                  ),
                )}
                {...field}
              />
            )}
          />
          {checkoutData.insurance.description && (
            <>
              <Button
                layout="link"
                buttonProps={{
                  className: styles.showMoreInsuranceInfosButton,
                  onClick: () => setShowInsuranceModal(true),
                }}
              >
                {i18n.checkout.showMoreInsuranceDetails}
              </Button>
              <FullscreenModal
                title={checkoutData.insurance.name}
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitize(checkoutData.insurance.description),
                    }}
                  />
                }
                isOpen={showInsuranceModal}
                onClose={() => setShowInsuranceModal(false)}
              />
            </>
          )}
        </Fieldset>
      )}
      {checkoutData?.reservation_policies && (
        <div className={styles.block}>
          <Headline
            as="div"
            size={3}
            title={i18n.checkout.reservationPolicies}
            className={styles.blockHeadline}
          />
          <ul className={styles.reservationPolicies}>
            {checkoutData.reservation_policies.summary.map(
              (condition, index) => (
                <li key={index}>{condition}</li>
              ),
            )}
          </ul>
          <Button
            layout="link"
            buttonProps={{
              className: styles.showMoreReservationPoliciesButton,
              onClick: () => setShowReservationPoliciesModal(true),
            }}
          >
            {i18n.ratePlans.moreDetails}
          </Button>
          <FullscreenModal
            isOpen={showReservationPoliciesModal}
            onClose={() => setShowReservationPoliciesModal(false)}
            title={i18n.ratePlans.reservationPolicies}
            content={
              <ul className={styles.reservationPolicies}>
                {checkoutData.reservation_policies.full.map(
                  (condition, index) => (
                    <li key={index}>{condition}</li>
                  ),
                )}
              </ul>
            }
          />
        </div>
      )}
      <div className={clsx(styles.block, styles.buttonBlock)}>
        <Headline
          size={2}
          title={i18n.checkout.bookingSummary[site.guest_interaction]}
          className={styles.bookingButtonHeadline}
        />
        <Headline
          size={1}
          title={formatMoney(checkoutData?.prices.total ?? zeroPrice, language)}
          className={styles.bookingAmount}
        />
        <Controller
          control={control}
          name="general.privacyPolicy"
          rules={{
            required: {
              value: true,
              message: i18n.general.form.errors.required,
            },
          }}
          render={({ field }) => (
            <Checkbox
              className={styles.privacyCheckbox}
              // Interactive elements like buttons inside labels harm
              // accessibility and violate the HTML specification, see
              // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/label#interactive_content.
              // Our team deliberately chose to ignore these accessibility
              // issues in favor of a more concise checkbox label and
              // better-looking form design.
              label={i18n.checkout.acceptPrivacyPolicyAndTermsLabel(
                <LegalTextMenuItem
                  buttonClassName={styles.legalLink}
                  type={LegalTextType.Privacy}
                  label={i18n.checkout.privacy}
                />,
                <LegalTextMenuItem
                  buttonClassName={styles.legalLink}
                  type={LegalTextType.Terms}
                  label={i18n.checkout.terms}
                />,
              )}
              error={errors.general?.privacyPolicy}
              required
              {...field}
            />
          )}
        />
        <Button
          loading={isSubmitting}
          buttonProps={{ type: "submit", className: styles.bookingButton }}
        >
          {i18n.checkout.book}
        </Button>
      </div>
    </div>
  );
};

export default CheckoutForm;
