import { createPortal } from "react-dom";
import { useAlertStore } from "../hooks/useAlertStore";
import AlertMessage from "./AlertMessage";

const AlertMessages = () => {
  const alerts = useAlertStore((state) => state.alerts);

  return createPortal(
    <>
      {alerts.map((x) => (
        <AlertMessage key={x.id} alert={x} />
      ))}
    </>,
    document.body,
  );
};

export default AlertMessages;
