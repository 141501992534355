import { create } from "zustand";

interface ModalHistoryStoreState {
  identifiersMap: Map<string, string>;
}

interface ModalHistoryStoreActions {
  toggle: (identifier: string, show: boolean, identifierValue?: string) => void;
  updateStateFromHistory: (
    identifiers: Record<string, string> | undefined,
  ) => void;
  reset: () => void;
}

type ModalHistoryStore = ModalHistoryStoreState & ModalHistoryStoreActions;

const getDefaultState = () => ({ identifiersMap: new Map<string, string>() });

export interface HistoryStateWithModalHistoryIdentifiers {
  modalHistoryIdentifiers?: Record<string, string>;
}

export const useModalHistoryStore = create<ModalHistoryStore>()((set) => ({
  ...getDefaultState(),
  toggle: (identifier: string, show: boolean, identifierValue?: string) =>
    set((state) => {
      const identifiersMap = new Map(state.identifiersMap);
      if (show) {
        identifiersMap.set(identifier, identifierValue ?? identifier);
      } else {
        identifiersMap.delete(identifier);
      }

      history.pushState(
        {
          ...history.state,
          modalHistoryIdentifiers: Object.fromEntries(identifiersMap),
        },
        "",
      );

      return { identifiersMap };
    }),
  updateStateFromHistory: (identifiers: Record<string, string> | undefined) =>
    set(() => {
      if (!identifiers) {
        return getDefaultState();
      }

      return { identifiersMap: new Map(Object.entries(identifiers)) };
    }),
  reset: () =>
    set(() => {
      const defaultState = getDefaultState();
      history.replaceState(
        {
          ...history.state,
          modalHistoryIdentifiers: Object.fromEntries(
            defaultState.identifiersMap,
          ),
        },
        "",
      );
      return defaultState;
    }),
}));

export const useModalHistoryToggle = (
  identifier: string,
  identifierValue?: string,
) => {
  const toggle = useModalHistoryStore((state) => state.toggle);
  return (show: boolean, value?: string) =>
    toggle(identifier, show, value ?? identifierValue);
};

export const isModalActive =
  (identifier: string) => (state: ModalHistoryStoreState) =>
    state.identifiersMap.has(identifier);

export const selectModalIdentifierValue =
  (identifier: string) => (state: ModalHistoryStoreState) =>
    state.identifiersMap.get(identifier);
