import { Select } from "@headlessui/react";
import { FocusEventHandler, Ref } from "react";
import { FieldError } from "react-hook-form";
import FormField from "./FormField";

interface Option {
  key: string;
  value: string;
  selected?: boolean;
}

interface FormSelectProps {
  label: string;
  options: Option[];
  required?: boolean;
  isEmpty?: boolean;
  autoComplete?: string;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: FocusEventHandler<HTMLSelectElement>;
  ref: Ref<HTMLSelectElement>;
}

const FormSelect = ({
  label,
  options,
  required,
  isEmpty,
  autoComplete,
  className,
  error,
  onBlur,
  ref,
  ...rest
}: FormSelectProps) => (
  <FormField
    label={label}
    required={required}
    isEmpty={isEmpty}
    className={className}
    error={error}
  >
    {({ inputClassName, errorId, setFocused, onAnimationStart }) => (
      <Select
        {...rest}
        ref={ref}
        autoComplete={autoComplete}
        className={inputClassName}
        aria-invalid={!!error}
        aria-required={required}
        aria-errormessage={error ? errorId : undefined}
        onFocus={() => setFocused(true)}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
        /* required to trigger animation on autofill preview which then can be detected via javascript */
        onAnimationStart={onAnimationStart}
      >
        {options.map((option: Option) => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </Select>
    )}
  </FormField>
);

export default FormSelect;
