import { RangeCalendarState } from "@react-stately/calendar";
import { useCalendarGrid } from "react-aria";
import Headline from "../../ui/Headline";
import styles from "./CalendarWeekdays.module.css";

interface CalendarWeekdaysProps {
  state: RangeCalendarState;
}

const CalendarWeekdays = ({ state }: CalendarWeekdaysProps) => {
  const { weekDays } = useCalendarGrid({ weekdayStyle: "short" }, state);
  return (
    <div className={styles.header}>
      {weekDays.map((weekday) => (
        <Headline
          className={styles.day}
          size={7}
          title={weekday}
          key={weekday}
        />
      ))}
    </div>
  );
};

export default CalendarWeekdays;
