import { FieldErrors, Ref, useFormContext } from "react-hook-form";
import { AutoOccupancy } from "../../@types";
import { AutoOfferRoom } from "../../http/offer";
import { Room } from "../../http/room";
import { site } from "../../utils/site";

interface FormDataRoomConfigurationOption {
  adults: number;
  children: number;
  childAgeIndices: number[];
}

interface FormDataRoomConfiguration {
  roomTypeId: string | null;
  adults: number;
  children: number;
  childAgeIndices: number[];
  options?: Record<string, FormDataRoomConfigurationOption>;
}

export interface FormDataRoomConfigurations {
  roomConfigurations: FormDataRoomConfiguration[];
  unassignedOccupancy: { adults: number; children: number };
}

export const useIndividualAssignmentFormContext = () =>
  useFormContext<FormDataRoomConfigurations>();

export interface GroupedOfferRoom {
  count: number;
  roomTypeId: string;
  name: string;
  price: number;
  currency: string;
  adults: number;
  children: number;
  occupancyCount: number;
}

export const groupAutoOfferRooms = (rooms: AutoOfferRoom[]) => {
  const groupMap = rooms.reduce((roomsMap, room) => {
    const key = `${room.room_type_id}_${room.occupancy.adults}_${
      room.occupancy.children?.length ?? 0
    }_${room.cheapest_prices.min.currency}`;

    const existingGroup = roomsMap.get(key);
    if (existingGroup) {
      roomsMap.set(key, {
        count: existingGroup.count + 1,
        roomTypeId: room.room_type_id,
        name: room.name,
        price: existingGroup.price + room.cheapest_prices.min.amount,
        currency: room.cheapest_prices.min.currency,
        adults: existingGroup.adults,
        children: existingGroup.children,
        occupancyCount: existingGroup.occupancyCount,
      });
    } else {
      const adults = room.occupancy.adults;
      const children = room.occupancy.children?.length ?? 0;
      roomsMap.set(key, {
        count: 1,
        roomTypeId: room.room_type_id,
        name: room.name,
        price: room.cheapest_prices.min.amount,
        currency: room.cheapest_prices.min.currency,
        adults,
        children,
        occupancyCount: adults + children,
      });
    }
    return roomsMap;
  }, new Map<string, GroupedOfferRoom>());

  return [...groupMap.values()];
};

interface AssignedOccupancy {
  adults: number;
  children: number;
}

export const getUnassignedOccupancy = (
  roomConfigs: (
    | { adults?: number; children?: number | number[] }
    | undefined
  )[],
  adults: number,
  children: number,
) =>
  roomConfigs.reduce(
    (data: AssignedOccupancy, roomConfig) => {
      if (!roomConfig) {
        return data;
      }

      data.adults -= roomConfig.adults ?? 0;
      data.children -= Array.isArray(roomConfig.children)
        ? roomConfig.children.length
        : (roomConfig.children ?? 0);
      return data;
    },
    { adults, children },
  );

export const getOccupancyLimits = (
  room: Room | undefined,
  totalOccupancy: AutoOccupancy | null,
  currentOccupancy: AssignedOccupancy,
  alreadyAssignedOccupancy: AssignedOccupancy,
) => {
  const totalAdults = totalOccupancy?.adults ?? 0;
  const totalChildren = totalOccupancy?.children ?? [];
  const minOccupancy = room?.occupancy.min ?? 1;
  const maxOccupancy = room?.occupancy.max ?? site.max_guests_per_room;
  const remainingAdults = totalAdults - alreadyAssignedOccupancy.adults;
  const remainingChildren =
    totalChildren.length - alreadyAssignedOccupancy.children;

  // force room min occupancy even when no more unassigned guests are available
  const minAdults =
    remainingAdults + remainingChildren < minOccupancy
      ? minOccupancy
      : Math.max(
          Math.min(remainingAdults, minOccupancy - currentOccupancy.children),
          1,
        );

  const minChildren = Math.max(
    Math.min(remainingChildren, minOccupancy - currentOccupancy.adults),
    0,
  );

  const maxAdults = Math.max(
    Math.min(remainingAdults, maxOccupancy - currentOccupancy.children),
    1,
  );

  const maxChildren = Math.max(
    Math.min(remainingChildren, maxOccupancy - currentOccupancy.adults),
    0,
  );

  return { minAdults, maxAdults, minChildren, maxChildren };
};

export const getRoomConfigurationError = (
  errors: FieldErrors<FormDataRoomConfigurations>,
  roomCount: number,
) => {
  for (let index = 0; index < roomCount; index++) {
    if (errors.roomConfigurations?.[index]) {
      return { error: errors.roomConfigurations[index], index };
    }
  }

  return { error: null, index: -1 };
};

export const scrollToFirstError = (inputElementRef: Ref | null) => {
  if (!inputElementRef?.parentElement) {
    return;
  }

  const firstError = inputElementRef.parentElement.querySelector(
    "div[data-invalid='true']",
  );
  if (firstError) {
    firstError.scrollIntoView({ block: "center", behavior: "smooth" });
  }
};
