import wretch from "wretch";
import { FetcherOptions } from "../@types";
import { formatDateNullable } from "../utils/date";

export const api = wretch("/example-data");

export const fetcher = <Response>({ url, language }: FetcherOptions) =>
  api
    .url(url)
    .headers(language ? { "Accept-Language": language } : {})
    .get()
    .json<Response>();

export const buildURL = (baseURL: string, searchParams: URLSearchParams) => {
  const searchParamsString = searchParams.toString();
  return baseURL + (searchParamsString ? `?${searchParamsString}` : "");
};

export const setArrivalSearchParams = (
  searchParams: URLSearchParams,
  arrival: Date | null,
) => {
  const formattedDate = formatDateNullable(arrival);
  if (!formattedDate) {
    return false;
  }
  searchParams.set("arrival", formattedDate);
  return true;
};

export const setDepartureSearchParams = (
  searchParams: URLSearchParams,
  departure: Date | null,
) => {
  const formattedDate = formatDateNullable(departure);
  if (!formattedDate) {
    return false;
  }
  searchParams.set("departure", formattedDate);
  return true;
};

export const setArrivalAndDepartureSearchParams = (
  searchParams: URLSearchParams,
  arrival: Date | null,
  departure: Date | null,
) => {
  if (!setArrivalSearchParams(searchParams, arrival)) {
    return false;
  }
  if (!setDepartureSearchParams(searchParams, departure)) {
    return false;
  }

  return true;
};
