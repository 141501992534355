import { HTMLProps, useState } from "react";
import { Photo } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import Grid from "./icon/grid.svg?react";
import LightGallery from "./LightGallery";
import styles from "./PhotoSlider.module.css";
import ResponsiveImage from "./ResponsiveImage";
import Slider from "./Slider";

interface PhotoSliderProps extends HTMLProps<HTMLDivElement> {
  photos: Photo[];
  imageSizes: string;
  dots?: boolean;
  arrows?: boolean;
  showAllButton?: boolean;
}

const PhotoSlider = ({
  photos,
  imageSizes,
  dots = photos.length > 1,
  arrows = photos.length > 1,
  showAllButton = false,
  ...htmlProps
}: PhotoSliderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showRoomPhotos, setShowRoomPhotos] = useState(false);

  return (
    <div {...htmlProps}>
      <LightGallery
        photos={photos}
        isOpen={showRoomPhotos}
        onClose={() => setShowRoomPhotos(false)}
      />
      <Slider
        dots={dots}
        arrows={arrows}
        customControls={
          showAllButton &&
          photos.length > 1 && (
            <Button
              layout="secondary"
              buttonProps={{
                onClick: () => setShowRoomPhotos(true),
                className: styles.lightboxOpener,
              }}
              glyph={Grid}
            >
              {i18n.photoSlider.showAllImages}
            </Button>
          )
        }
      >
        {photos.map((photo, index) => (
          <a
            className={styles.slide}
            aria-label={i18n.general.openLightbox}
            key={index}
            href={photo.derivatives[0]?.url}
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              setShowRoomPhotos(true);
            }}
          >
            <ResponsiveImage
              srcSet={photo.derivatives}
              alt={photo.description}
              sizes={imageSizes}
              className={styles.sliderImage}
              lazyLoad
            />
          </a>
        ))}
      </Slider>
    </div>
  );
};

export default PhotoSlider;
