import { useEffect, useState } from "react";
import { Price } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { formatMoney } from "../utils/number";

interface PriceCounterProps {
  price: Price;
}

const PriceCounter = ({ price }: PriceCounterProps) => {
  const language = useBookingStore((state) => state.language);
  const endValue = price.amount / 100;
  const duration = 500;
  const [currentValue, setCurrentValue] = useState(endValue);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (currentValue === endValue) return;
    if (processing) return;

    setProcessing(true);

    const startTime = performance.now();
    const initialValue = currentValue;

    const animateCounter = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const updatedValue = initialValue + (endValue - initialValue) * progress;

      setCurrentValue(updatedValue);

      if (progress < 1) {
        requestAnimationFrame(animateCounter);
      } else {
        setProcessing(false);
      }
    };

    requestAnimationFrame(animateCounter);
  }, [price.amount, currentValue, endValue, processing]);

  return formatMoney(
    { ...price, amount: Math.round(currentValue * 100) },
    language,
  );
};

export default PriceCounter;
