import { useFieldArray, useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import ChildAgeSelect from "../../ui/ChildAgeSelect";
import ChildrenAssignment from "../../ui/ChildrenAssignment";
import Date from "../../ui/Date";
import { formatIntlDate, parseDate } from "../../utils/date";
import { ExtraFormQuantity, ExtraFormType } from "./utils";

interface ExtraPerGuestAndDayChildrenProps {
  quantity: ExtraFormQuantity;
  quantityIndex: number;
  childrenAges: number[];
  maxChildrenCount: number;
  className?: string;
}

const ExtraPerGuestAndDayChildren = ({
  quantity,
  quantityIndex,
  childrenAges,
  maxChildrenCount,
  className = "",
}: ExtraPerGuestAndDayChildrenProps) => {
  const language = useBookingStore((state) => state.language);

  const { fields, append, remove } = useFieldArray({
    name: `quantities.${quantityIndex}.children`,
  });

  return (
    <ChildrenAssignment
      title={
        <Date
          date={parseDate(quantity.key)}
          formatter={formatIntlDate(language)}
        />
      }
      fields={fields}
      max={maxChildrenCount}
      className={className}
      onIncrease={() => append({ age: -1 })}
      onDecrease={() => remove(fields.length - 1)}
    >
      {({ index }) => (
        <ChildAge
          quantityIndex={quantityIndex}
          childrenAges={childrenAges}
          index={index}
        />
      )}
    </ChildrenAssignment>
  );
};

interface ChildAgeProps {
  quantityIndex: number;
  childrenAges: number[];
  index: number;
}

const ChildAge = ({ childrenAges, quantityIndex, index }: ChildAgeProps) => {
  const { register, watch } = useFormContext<ExtraFormType>();

  const registerProps = register(
    `quantities.${quantityIndex}.children.${index}.age`,
    { valueAsNumber: true, validate: (value) => value >= 0 },
  );

  const [selectedChildren, currentAge] = watch([
    `quantities.${quantityIndex}.children`,
    `quantities.${quantityIndex}.children.${index}.age`,
  ]);

  const unusedChildrenAges = [...childrenAges];
  selectedChildren?.forEach((x) => {
    const index = unusedChildrenAges.indexOf(x.age);
    if (index !== -1) {
      unusedChildrenAges.splice(index, 1);
    }
  });

  const options = childrenAges
    .filter((x, index, array) => array.indexOf(x) === index)
    .sort()
    .map((x) => ({
      key: `${x}`,
      value: `${x}`,
      disabled: !(x === currentAge || unusedChildrenAges.includes(x)),
    }));

  return (
    <ChildAgeSelect
      childIndex={index}
      options={options}
      selectProps={registerProps}
    />
  );
};

export default ExtraPerGuestAndDayChildren;
