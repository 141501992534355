import clsx from "clsx";
import { ElementType, JSX, PropsWithChildren } from "react";
import styles from "./Button.module.css";
import ArrowDown from "./icon/arrow-down.svg?react";
import ArrowForward from "./icon/arrow-forward.svg?react";

type ButtonLayout =
  | "primary"
  | "secondary"
  | "link"
  | "arrow-options"
  | "icon"
  | "round"
  | "checkout";

export type ButtonProps = PropsWithChildren<{
  layout?: ButtonLayout;
  buttonProps?: Omit<JSX.IntrinsicElements["button"], "children">;
  active?: boolean;
  loading?: boolean;
  glyph?: ElementType;
  disabledStyle?: boolean;
}>;

const createClassNames = ({
  layout = "primary",
  buttonProps,
  active,
  loading,
  glyph,
  disabledStyle,
}: ButtonProps) =>
  clsx(buttonProps?.className, styles.btn, {
    [styles.disabled]: buttonProps?.disabled || disabledStyle,
    [styles.primary]: layout === "primary",
    [styles.secondary]: layout === "secondary",
    [styles.arrowOptions]: layout === "arrow-options",
    [styles.arrowOptionsActive]: layout === "arrow-options" && active,
    [styles.checkout]: layout === "checkout",
    [styles.link]: layout === "link",
    [styles.iconButton]: layout === "icon",
    [styles.round]: layout === "round",
    [styles.loading]: loading,
    [styles.roundActive]: layout === "round" && active,
    [styles.withIcon]:
      glyph && (layout === "primary" || layout === "secondary"),
  });

const Button = (props: ButtonProps) => {
  const {
    children,
    buttonProps,
    glyph,
    layout,
    active,
    loading,
    disabledStyle,
  } = props;
  const GlyphComponent = glyph;

  return (
    <button
      type="button"
      {...buttonProps}
      className={createClassNames(props)}
      disabled={buttonProps?.disabled || loading}
      aria-disabled={disabledStyle}
    >
      {GlyphComponent && (
        <GlyphComponent
          className={clsx({
            [styles.iconArrowOptions]: layout === "arrow-options",
            [styles.iconArrowOptionsActive]:
              layout === "arrow-options" && active,
            [styles.iconButtonIcon]: layout === "icon",
            [styles.icon]: layout !== "arrow-options" && layout !== "icon",
          })}
        />
      )}
      {children}
      {layout === "arrow-options" && (
        <ArrowForward
          aria-hidden
          className={clsx(styles.arrowIcon, {
            [styles.arrowIconActive]: active,
          })}
        />
      )}
      {layout === "checkout" && (
        <ArrowDown className={styles.arrowDown} aria-hidden />
      )}
    </button>
  );
};

export default Button;
