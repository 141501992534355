import { Controller, useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import NumberInput from "../../ui/NumberInput";
import { maxExtraQuantity } from "../../utils/constants";
import { ExtraFormType } from "./utils";

const ExtraPerStay = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { control, setValue } = useFormContext<ExtraFormType>();

  return (
    <Controller
      name="quantities.0.quantity"
      control={control}
      rules={{ min: 0, max: maxExtraQuantity }}
      render={({ field: { value } }) => (
        <NumberInput
          title={i18n.extras.quantity}
          value={value ?? 0}
          min={0}
          max={maxExtraQuantity}
          onChange={(quantity) =>
            setValue("quantities.0.quantity", quantity, {
              shouldDirty: true,
              shouldTouch: true,
              shouldValidate: true,
            })
          }
        />
      )}
    />
  );
};

export default ExtraPerStay;
