import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import { CenteredIcon } from "./CenteredIcon";
import Headline from "./Headline";
import styles from "./Modal.module.css";
import Close from "./icon/close.svg?react";

interface ModalProps {
  isOpen: boolean;
  title?: string;
  underPageHeader?: boolean;
  roundedCorners?: boolean;
  children: ReactNode;
  onClose: () => void;
}

const Modal = ({
  isOpen,
  title,
  underPageHeader = false,
  roundedCorners = true,
  children,
  onClose,
}: ModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={clsx(styles.overlay, {
        [styles.overlayUnderPageHeader]: underPageHeader,
      })}
    >
      <DialogPanel
        className={clsx(styles.dialog, {
          [styles.dialogUnderPageHeader]: underPageHeader,
          [styles.dialogWithoutPageHeader]: !underPageHeader,
        })}
      >
        <div
          className={clsx(styles.inner, {
            [styles.innerRounded]: roundedCorners,
          })}
        >
          <DialogTitle
            as="div"
            className={clsx(styles.header, {
              [styles.headerWithoutTitle]: !title,
            })}
          >
            {title && (
              <Headline
                as="h1"
                size={2}
                className={styles.title}
                title={title}
              />
            )}
            <Button
              layout="link"
              buttonProps={{
                onClick: onClose,
                "aria-label": i18n.general.close,
                className: clsx(styles.closeButton, {
                  [styles.closeButtonWithTitle]: !!title,
                }),
              }}
            >
              <CenteredIcon icon={Close} />
            </Button>
          </DialogTitle>
          {children}
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default Modal;
