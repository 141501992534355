import { useFieldArray } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import ChildAgeSelect from "../../ui/ChildAgeSelect";
import ChildrenAssignment from "../../ui/ChildrenAssignment";
import { maxChildrenAge } from "../../utils/constants";
import { useOccupancyFormContext } from "./utils";

interface OccupancyChildrenProps {
  occupancyIndex: number;
  minChildrenCount: number;
  maxChildrenCount: number;
  childrenMinAge: number;
  disabled?: boolean | undefined;
}

const OccupancyChildren = ({
  occupancyIndex,
  minChildrenCount,
  maxChildrenCount,
  childrenMinAge,
  disabled,
}: OccupancyChildrenProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { fields, append, remove } = useFieldArray({
    name: `occupancies.${occupancyIndex}.children`,
  });

  return (
    <ChildrenAssignment
      title={i18n.occupancy.children}
      description={i18n.occupancy.childrenInfo(childrenMinAge)}
      fields={fields}
      min={minChildrenCount}
      max={maxChildrenCount}
      disabled={disabled}
      onIncrease={() => append({ age: -1 })}
      onDecrease={() => remove(fields.length - 1)}
    >
      {({ index }) => (
        <ChildAge
          occupancyIndex={occupancyIndex}
          index={index}
          childrenMinAge={childrenMinAge}
          disabled={disabled}
        />
      )}
    </ChildrenAssignment>
  );
};

interface ChildAgeProps {
  occupancyIndex: number;
  index: number;
  childrenMinAge: number;
  disabled?: boolean | undefined;
}

const ChildAge = ({
  occupancyIndex,
  index,
  childrenMinAge,
  disabled = false,
}: ChildAgeProps) => {
  const { register } = useOccupancyFormContext();

  const registerProps = register(
    `occupancies.${occupancyIndex}.children.${index}.age`,
    {
      valueAsNumber: true,
      validate: (value) => value >= childrenMinAge && value <= maxChildrenAge,
    },
  );

  const options = Array.from(
    { length: maxChildrenAge - childrenMinAge + 1 },
    (_, index) => ({
      key: `${childrenMinAge + index}`,
      value: `${childrenMinAge + index}`,
    }),
  );

  return (
    <ChildAgeSelect
      childIndex={index}
      options={options}
      selectProps={{ ...registerProps, disabled }}
    />
  );
};

export default OccupancyChildren;
