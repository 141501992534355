/* eslint css-modules/no-unused-class: ["error", { markAsUsed: ["small", "medium", "large", "left", "center", "right"] }] */

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import clsx from "clsx";
import { PropsWithChildren, ReactNode, Ref, UIEvent } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import { CenteredIcon } from "./CenteredIcon";
import Headline from "./Headline";
import styles from "./MobileModal.module.css";
import Close from "./icon/close.svg?react";

type MobileModalProps = PropsWithChildren<{
  isOpen: boolean;
  align?: "left" | "center" | "right";
  size?: "small" | "medium" | "large";
  onClose: () => void;
}>;

const MobileModal = ({
  isOpen,
  children,
  align = "left",
  size = "medium",
  onClose,
}: MobileModalProps) => (
  <Dialog open={isOpen} onClose={onClose} className={styles.overlay}>
    <div className={styles.dialogWrapper}>
      <MobileModalDialogContent align={align} size={size}>
        {children}
      </MobileModalDialogContent>
    </div>
  </Dialog>
);

type MobileModalDialogContentProps = PropsWithChildren<{
  align?: "left" | "center" | "right";
  size?: "small" | "medium" | "large";
}>;
const MobileModalDialogContent = ({
  children,
  align = "left",
  size = "medium",
}: MobileModalDialogContentProps) => (
  <DialogPanel className={clsx(styles.dialog, styles[size], styles[align])}>
    <div className={styles.inner}>{children}</div>
  </DialogPanel>
);

type MobileModalHeaderProps = PropsWithChildren<{
  title: ReactNode;
  onClose: () => void;
  hideOnDesktop?: boolean;
  titleFooter?: ReactNode;
}>;

export const MobileModalHeader = ({
  title,
  onClose,
  hideOnDesktop = true,
  titleFooter,
}: MobileModalHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <DialogTitle
      as="div"
      className={clsx(styles.header, { [styles.hideOnDesktop]: hideOnDesktop })}
    >
      <div className={styles.headerContainer}>
        <Headline as="h1" size={2} className={styles.title} title={title} />
        <Button
          layout="link"
          buttonProps={{
            "aria-label": i18n.general.close,
            onClick: onClose,
            className: styles.closeButton,
          }}
        >
          <CenteredIcon icon={Close} />
        </Button>
      </div>
      {titleFooter && titleFooter}
    </DialogTitle>
  );
};

type MobileModalContentProps = PropsWithChildren<{
  onScroll?: (event: UIEvent) => void;
  className?: string;
  ref?: Ref<HTMLDivElement>;
}>;

export const MobileModalContent = ({
  children,
  onScroll,
  className,
  ref,
}: MobileModalContentProps) => (
  <div
    ref={ref}
    onScroll={onScroll}
    className={clsx(styles.content, className)}
  >
    {children}
  </div>
);

export default MobileModal;
