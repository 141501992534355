import { create } from "zustand";

let _idCount = 1;

const getId = () => `alert-${_idCount++}`;

export enum AlertType {
  Info = "info",
  Success = "success",
  Warning = "warning",
  Error = "error",
}

export interface Alert {
  id: string;
  key?: string;
  type: AlertType;
  title: string;
  description?: string | undefined;
}

interface AlertStoreState {
  alerts: Alert[];
}

interface AlertStoreActions {
  addAlert: (alert: Omit<Alert, "id">) => void;
  removeAlert: (alertId: string) => void;
  clear: () => void;
}

type AlertStore = AlertStoreState & AlertStoreActions;

const defaultState: AlertStoreState = { alerts: [] };

export const useAlertStore = create<AlertStore>()((set) => ({
  ...defaultState,
  addAlert: (alert: Omit<Alert, "id">) =>
    set((state) => {
      if (alert.key) {
        const existingAlertIndex = state.alerts.findIndex(
          (x) => x.key === alert.key && x.type === alert.type,
        );
        const existingAlert = state.alerts[existingAlertIndex];
        if (existingAlert) {
          const alerts = [...state.alerts];
          alerts[existingAlertIndex] = { ...alert, id: existingAlert.id };

          return { alerts };
        }
      }

      return { alerts: [...state.alerts, { ...alert, id: getId() }] };
    }),
  removeAlert: (alertId: string) =>
    set((state) => ({ alerts: state.alerts.filter((x) => x.id !== alertId) })),
  clear: () => set(() => ({ alerts: [] })),
}));
