import { Field, Textarea as HeadlessTextarea } from "@headlessui/react";
import clsx from "clsx";
import { FocusEventHandler, Ref, useId, useState } from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import { FormLabel } from "./FormLabel";
import styles from "./Textarea.module.css";

interface TextareaProps {
  label: string;
  required?: boolean;
  isEmpty?: boolean;
  className?: string;
  error?: FieldError | undefined;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  ref: Ref<HTMLTextAreaElement>;
}

const Textarea = ({
  label,
  required,
  isEmpty,
  className,
  error,
  onBlur,
  ref,
  ...rest
}: TextareaProps) => {
  const [focused, setFocused] = useState(false);
  const errorId = useId();

  return (
    <Field
      className={clsx(styles.textareaWrapper, className, {
        [styles.isInvalid]: !!error,
      })}
    >
      <FormLabel
        className={clsx(styles.label, {
          [styles.focused]: focused,
          [styles.filled]: !isEmpty,
        })}
        required={required}
      >
        {label}
      </FormLabel>
      <HeadlessTextarea
        {...rest}
        ref={ref}
        className={styles.textarea}
        aria-invalid={!!error}
        aria-required={required}
        aria-errormessage={error ? errorId : undefined}
        onFocus={() => setFocused(true)}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
      />
      <FormError id={errorId} errorMessage={error?.message ?? ""} />
    </Field>
  );
};

export default Textarea;
