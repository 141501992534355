import defaultTranslations from "../i18n/de";

export type I18n = typeof defaultTranslations;

export enum Language {
  German = "de",
  Italian = "it",
  English = "en",
}

export type DeepPartial<T> = {
  [K in keyof T]?: T[K] extends object ? DeepPartial<T[K]> : T[K];
};

export enum BookingStep {
  Init = "init",
  Start = "start",
  Occupancy = "occupancy",
  Calendar = "calendar",
  AutoOccupancy = "autoOccupancy",
  RoomConfigurations = "room-configurations",
  Rooms = "rooms",
  RatePlansAutoOffer = "rate-plans-auto-offer",
  RatePlans = "rate-plans",
  RatePlan = "rate-plan",
  Boards = "boards",
  Extras = "extras",
  Checkout = "checkout",
}

export enum HeaderButtonCategory {
  None = "none",
  Occupancy = "occupancy",
  Calendar = "calendar",
  Checkout = "checkout",
}

export enum GuestInteraction {
  Formal = "formal",
  Informal = "informal",
}

export enum RoomTerm {
  Room = "room",
  Apartment = "apartment",
  Suite = "suite",
}

export interface FetcherOptions {
  url: string;
  language?: Language;
}

export interface Prices {
  min: Price;
  min_per_night: Price;
  base: Price;
  discount: number;
}

export interface Price {
  currency: string;
  amount: number;
}

export interface TotalPrices {
  total_without_vat: Price;
  total: Price;
  tourist_tax: Price;
}

export interface ReservationPolicies {
  summary: string[];
  full: string[];
}

export interface Image {
  url: string;
  width: number;
  height: number;
}

export interface Photo {
  copyright: string;
  description: string;
  derivatives: Image[];
}

export enum ExtraType {
  PerDay = "per_day",
  PerGuestAndDay = "per_guest_and_day",
  PerRoom = "per_room",
  PerStay = "per_stay",
}

export type { ExtraConfiguration } from "./extraConfiguration";
export type { AutoOccupancy, Occupancy } from "./occupancy";
export { OrderDirection } from "./orderDirection";
export type { RoomConfiguration } from "./roomConfiguration";
export type { SelectedExtra, SelectedExtraAmount } from "./selectedExtra";

export interface Colors {
  primary: string;
  primary_light: string;
}

export interface Site {
  id: string;
  property_id: number;
  property_name: string;
  phone_number: string;
  email: string;
  website_url: string;
  address: {
    street: string;
    postal_code: string;
    municipality: string;
    province: string;
  };
  children_allowed: boolean;
  children_min_age: number;
  max_guests_per_room: number;
  check_in: TimeWindow;
  check_out: TimeWindow;
  logo_light: Image;
  logo_dark: Image;
  background_image: {
    derivatives: Image[];
    copyright: string;
    description: { de: string; en: string; it: string; fr: string };
  };
  colors: Colors;
  guest_interaction: GuestInteraction;
  room_term: RoomTerm;
  guest_field_settings: {
    gender: FieldSetting;
    phone_number: FieldSetting;
    street: FieldSetting;
    postal_code: FieldSetting;
    municipality: FieldSetting;
    country: FieldSetting;
  };
  /**
   * The current date in the timezone where the property is located.
   */
  current_date: string;
}

/**
 * A time window with the times in the format hh:mm.
 */
interface TimeWindow {
  from: string;
  to: string;
}

export enum FieldSetting {
  Disabled = "disabled",
  Optional = "optional",
  Required = "required",
}
