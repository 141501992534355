import clsx from "clsx";
import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/room";
import { translate } from "../../i18n";
import Guests from "../../ui/icon/guests.svg?react";
import Size from "../../ui/icon/qm.svg?react";
import { Label } from "../../ui/Label";
import styles from "./RoomInfos.module.css";

interface RoomInfosProps {
  room: Room;
  prices?: ReactNode;
  className?: string;
}

const RoomInfos = ({ room, prices, className }: RoomInfosProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <>
      <Label className={clsx(styles.roomInfos, className)}>
        <div className={styles.roomInfo}>
          <Guests
            title={i18n.rooms.occupancy}
            role="img"
            className={styles.roomDetailIcon}
          />
          {room.occupancy.min}
          {room.occupancy.max > room.occupancy.min && `–${room.occupancy.max}`}
        </div>
        {room.size && (
          <div className={styles.roomInfo}>
            <Size
              title={i18n.rooms.size}
              role="img"
              className={styles.roomDetailIcon}
            />
            <div>{room.size}&nbsp;m²</div>
          </div>
        )}
      </Label>
      {prices}
    </>
  );
};

export default RoomInfos;
