import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { ExtraSuggestion as ExtraSuggestionType } from "../../http/offer";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import Plus from "../../ui/icon/plus.svg?react";
import { ReadMore } from "../../ui/ReadMore";
import { formatMoney } from "../../utils/number";
import ExtraConfigurationForm from "./ExtraConfigurationForm";
import ExtraPriceBadge from "./ExtraPriceBadge";
import styles from "./ExtraSuggestion.module.css";

interface ExtraSuggestionProps {
  extraSuggestion: ExtraSuggestionType;
}

const ExtraSuggestion = ({ extraSuggestion }: ExtraSuggestionProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const modalIdentifier = `ExtraSuggestion_${extraSuggestion.id}`;
  const toggleDetailModal = useModalHistoryToggle(modalIdentifier);
  const showDetailModal = useModalHistoryStore(isModalActive(modalIdentifier));

  return (
    <>
      <FullscreenModal
        fullWidth
        title={extraSuggestion.title}
        isOpen={showDetailModal}
        headerContent={
          <ExtraPriceBadge price={extraSuggestion.price_per_unit} />
        }
        content={
          <ExtraConfigurationForm
            extra={{ ...extraSuggestion, category_id: "", photos: [] }}
            onSubmit={() => toggleDetailModal(false)}
          />
        }
        onClose={() => toggleDetailModal(false)}
      />
      <div className={styles.extraSuggestionBlock}>
        <Headline as="h3" size={3} title={extraSuggestion.title} />
        <ReadMore text={extraSuggestion.description} lines={1} />
        <Button
          glyph={Plus}
          buttonProps={{
            onClick: () => toggleDetailModal(true),
            className: styles.addExtraButton,
          }}
        >
          {i18n.checkout.addExtra(
            formatMoney(extraSuggestion.price_per_unit, language),
          )}
        </Button>
      </div>
    </>
  );
};

export default ExtraSuggestion;
