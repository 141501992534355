import { ErrorBoundary } from "react-error-boundary";
import { SWRConfig } from "swr";
import { useColorTheme } from "./hooks/useColorTheme";
import Error from "./pages/Error";
import Router from "./Router";
import AlertMessages from "./ui/AlertMessages";
import { site } from "./utils/site";

const App = () => {
  useColorTheme(site.colors);

  return (
    <ErrorBoundary fallback={<Error />}>
      <main>
        <SWRConfig
          value={{
            shouldRetryOnError: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
          }}
        >
          <Router />
          <AlertMessages />
        </SWRConfig>
      </main>
    </ErrorBoundary>
  );
};

export default App;
