import clsx from "clsx";
import { PropsWithChildren, ReactNode } from "react";
import styles from "./Card.module.css";

type CardProps = PropsWithChildren<{
  header?: ReactNode;
  className?: string;
  contentClassName?: string;
  footerClassName?: string;
  footer?: ReactNode;
}>;

export const Card = ({
  header,
  className,
  contentClassName,
  footerClassName,
  footer,
  children,
}: CardProps) => (
  <div className={clsx(className, styles.card)}>
    {header && header}
    <div className={clsx(contentClassName, styles.content)}>
      {children}
      {footer && (
        <div className={clsx(footerClassName, styles.footer)}>{footer}</div>
      )}
    </div>
  </div>
);
