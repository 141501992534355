import Button from "../ui/Button";
import Headline from "../ui/Headline";
import ArrowLeft from "../ui/icon/arrow-left.svg?react";
import styles from "./PageHeader.module.css";

interface PageHeaderProps {
  headline: string;
  backButton: { label: string; onClick: () => void };
}

const PageHeader = ({ headline, backButton }: PageHeaderProps) => {
  return (
    <div className={styles.header}>
      <Button
        layout="link"
        buttonProps={{
          onClick: backButton.onClick,
          className: styles.backButton,
        }}
      >
        <ArrowLeft className={styles.backButtonIcon} />
        <span className={styles.backButtonTitle}>{backButton.label}</span>
      </Button>

      <Headline
        className={styles.headerHeadline}
        as="h1"
        size={2}
        title={headline}
      />
    </div>
  );
};

export default PageHeader;
