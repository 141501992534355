import { useShallow } from "zustand/react/shallow";
import { BookingStep } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useOffers } from "../../http/offer";
import { useRoomTypes } from "../../http/room";
import Error from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import Prices from "../../ui/Prices";
import Skeleton from "../../ui/Skeleton";
import RatePlans from "../ratePlan/RatePlans";
import RoomDetailModal from "./RoomDetailModal";

const RoomDetailRatePlans = () => {
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();
  const { offersMap, isLoading: isLoadingOffers } = useOffers();
  const { roomTypesMap, isLoading: isLoadingRooms } = useRoomTypes();

  if (!currentRoomConfiguration?.roomTypeId) {
    return <Error />;
  }

  const room = roomTypesMap?.[currentRoomConfiguration.roomTypeId];
  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];

  return (
    <PageWithHeader>
      <CenterWrapper>
        {room && offer && (
          <RoomDetailModal
            room={room}
            prices={
              <Prices
                basePrice={offer.cheapest_prices.base}
                minPrice={offer.cheapest_prices.min}
                minPricePerNight={offer.cheapest_prices.min_per_night}
                discount={offer.cheapest_prices.discount}
              />
            }
            open
            onClose={() => {
              updateRoomConfiguration({ roomTypeId: null });
              setStep(BookingStep.Rooms);
            }}
            availableRooms={offer.available_rooms}
            footer={() => <RatePlans offer={offer} />}
          />
        )}
        {(isLoadingOffers || isLoadingRooms) && (
          <Skeleton desktopItems={1} mobileItems={1} />
        )}
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default RoomDetailRatePlans;
