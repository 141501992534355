import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/room";
import { translate } from "../../i18n";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import Share from "../../ui/icon/share.svg?react";
import PhotoSlider from "../../ui/PhotoSlider";
import ShareButton from "../../ui/ShareButton";
import FavoredAmenities from "../amenity/FavoredAmenities";
import styles from "./RoomDetail.module.css";
import RoomInfos from "./RoomInfos";

interface RoomDetailProps {
  room: Room;
  prices?: ReactNode;
  footer?: ReactNode;
  shareView?: boolean;
}

const RoomDetail = ({
  room,
  prices,
  footer,
  shareView = false,
}: RoomDetailProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <>
      <CenterWrapper className={styles.roomDetails}>
        <div className={styles.roomName}>
          <Headline size={2} title={room.name} as="h1" />
          <ShareButton
            shareData={{
              title: room.name,
              text: room.description,
              url: `${location.origin}/${language}/room-types/${room.id}`,
            }}
            layout="icon"
            buttonProps={{
              "aria-label": i18n.general.share,
              className: styles.shareButton,
            }}
            glyph={Share}
          />
        </div>
        <RoomInfos room={room} prices={prices} />
        <FavoredAmenities room={room} shareView={shareView} />
      </CenterWrapper>
      <PhotoSlider
        photos={room.photos}
        imageSizes="(min-width: 1125px) 718px, 100vw"
        showAllButton
        className={styles.sliderContainer}
      />
      {footer && (
        <CenterWrapper className={styles.footer}>{footer}</CenterWrapper>
      )}
    </>
  );
};

export default RoomDetail;
