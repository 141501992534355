import { GregorianCalendar } from "@internationalized/date";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { useFormContext } from "react-hook-form";

export interface DateRange {
  start: Date | null;
  end: Date | null;
}

export const createCalendar = (identifier: string) => {
  switch (identifier) {
    case "gregory": {
      return new GregorianCalendar();
    }
    default: {
      throw new Error(`Unsupported calendar ${identifier}`);
    }
  }
};

export const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export enum InvalidDayReasonType {
  None,
  DepartureBeforeArrival,
  MinStay,
  MaxStay,
  ArrivalNotAvailable,
  DepartureNotAvailable,
}

export type InvalidDayReason =
  | {
      type:
        | InvalidDayReasonType.None
        | InvalidDayReasonType.DepartureBeforeArrival
        | InvalidDayReasonType.ArrivalNotAvailable
        | InvalidDayReasonType.DepartureNotAvailable;
    }
  | {
      type: InvalidDayReasonType.MinStay | InvalidDayReasonType.MaxStay;
      value: number;
    };

interface IsDayInvalidArguments {
  currentDate: Date;
  arrival: Date | null;
  departure: Date | null;
  arrivalDays: Date[];
  departureDays: Date[];
  minStay: number;
  maxStay: number;
  minDepartureDate: Date;
  maxDepartureDate: Date;
}
export const isDayInvalid = (
  day: Date,
  {
    currentDate,
    arrival,
    departure,
    arrivalDays,
    departureDays,
    minStay,
    maxStay,
    minDepartureDate,
    maxDepartureDate,
  }: IsDayInvalidArguments,
): false | InvalidDayReason => {
  if (!arrival || departure) {
    if (isBefore(day, currentDate)) {
      return { type: InvalidDayReasonType.ArrivalNotAvailable };
    }

    const arrivalPossible = arrivalDays.some((ad) => isSameDay(day, ad));
    if (arrivalPossible) {
      return false;
    }

    return { type: InvalidDayReasonType.ArrivalNotAvailable };
  }

  if (isSameDay(day, arrival)) {
    return false;
  }

  if (isBefore(day, arrival)) {
    return { type: InvalidDayReasonType.DepartureBeforeArrival };
  }

  if (isBefore(day, minDepartureDate)) {
    return { type: InvalidDayReasonType.MinStay, value: minStay };
  }

  if (isAfter(day, maxDepartureDate)) {
    return { type: InvalidDayReasonType.MaxStay, value: maxStay };
  }

  if (departureDays.every((dd) => !isSameDay(day, dd))) {
    return { type: InvalidDayReasonType.DepartureNotAvailable };
  }

  return false;
};

export interface FormDataCalendar {
  arrival: Date | null;
  departure: Date | null;
  stayExtended: boolean;
}

export const useCalendarFormContext = () => useFormContext<FormDataCalendar>();
