import { useCallback, useState } from "react";
import { HeaderButtonCategory } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useButtonGroupStore } from "../../hooks/useButtonGroupStore";
import { useRatePlans } from "../../http/ratePlan";
import { useRoomTypes } from "../../http/room";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import DateComponent from "../../ui/Date";
import { MobileModalContent, MobileModalHeader } from "../../ui/MobileModal";
import { formatIntlDate } from "../../utils/date";
import Calendar from "./Calendar";
import CalendarFilter from "./CalendarFilter";
import CalendarFooter from "./CalendarFooter";
import styles from "./CalendarForm.module.css";
import { useCalendarFormContext } from "./utils";

interface CalendarFormProps {
  isDesktop: boolean;
}

const CalendarForm = ({ isDesktop }: CalendarFormProps) => {
  const [showStayExtendedSuccess, setShowStayExtendedSuccess] = useState(false);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const setRoomTypeId = useBookingStore((state) => state.setRoomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const setRatePlanId = useBookingStore((state) => state.setRatePlanId);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const setButtonGroupArrival = useButtonGroupStore(
    (state) => state.setArrival,
  );
  const setButtonGroupDeparture = useButtonGroupStore(
    (state) => state.setDeparture,
  );

  const { roomTypesMap } = useRoomTypes({ skipFetch: !roomTypeId });
  const { ratePlansMap } = useRatePlans({ skipFetch: !ratePlanId });

  const roomType = roomTypesMap?.[roomTypeId ?? ""];
  const ratePlan = ratePlansMap?.[ratePlanId ?? ""];

  const { register, setValue, watch } = useCalendarFormContext();
  const [arrival, departure, stayExtended] = watch([
    "arrival",
    "departure",
    "stayExtended",
  ]);

  const setFormArrivalAndDeparture = useCallback(
    (newArrival: Date | null, newDeparture: Date | null) => {
      if (newArrival?.getTime() !== arrival?.getTime()) {
        setValue("arrival", newArrival, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        setButtonGroupArrival(newArrival);
      }

      if (newDeparture?.getTime() !== departure?.getTime()) {
        setValue("departure", newDeparture, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
        setButtonGroupDeparture(newDeparture);
      }
    },
    [
      arrival,
      departure,
      setButtonGroupArrival,
      setButtonGroupDeparture,
      setValue,
    ],
  );

  return (
    <>
      <MobileModalHeader
        title={
          <>
            {arrival ? (
              <DateComponent
                date={arrival}
                formatter={formatIntlDate(language)}
              />
            ) : (
              <span className={styles.highlighted}>{i18n.start.arrival}</span>
            )}
            &nbsp;--&gt;&nbsp;
            {departure ? (
              <DateComponent
                date={departure}
                formatter={formatIntlDate(language)}
              />
            ) : (
              <span className={arrival ? styles.highlighted : undefined}>
                {i18n.start.departure}
              </span>
            )}
          </>
        }
        onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
      />
      <MobileModalContent className={styles.container}>
        <input hidden disabled {...register("arrival", { required: true })} />
        <input hidden disabled {...register("departure", { required: true })} />
        <input hidden disabled {...register("stayExtended")} />
        <div className={styles.calendarContainer}>
          {roomType && (
            <CalendarFilter
              label="Zimmer"
              text={roomType.name}
              onRemove={() => setRoomTypeId(null)}
            />
          )}
          {ratePlan && (
            <CalendarFilter
              label="Rate"
              text={ratePlan.title}
              onRemove={() => setRatePlanId(null)}
            />
          )}
          <Calendar
            arrival={arrival}
            departure={departure}
            locale={language}
            hideNavigation={!isDesktop}
            scrollMode={!isDesktop}
            onSelect={(range) => {
              setValue("stayExtended", false);
              setShowStayExtendedSuccess(false);
              setFormArrivalAndDeparture(range.start, range.end);
            }}
          />
        </div>
      </MobileModalContent>
      {arrival && departure && (
        <ActionFooter className={styles.actionFooter}>
          <CalendarFooter
            arrival={arrival}
            departure={departure}
            stayExtended={stayExtended}
            success={showStayExtendedSuccess}
            onSelect={(range) => {
              setValue("stayExtended", true);
              setShowStayExtendedSuccess(true);
              setFormArrivalAndDeparture(range.start, range.end);
            }}
          />
        </ActionFooter>
      )}
    </>
  );
};

export default CalendarForm;
