import { Field, Input, Label } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { validateVoucher, VoucherStatus } from "../../http/voucher";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import { formatDate } from "../../utils/date";
import styles from "./CheckoutVoucher.module.css";

const CheckoutVoucher = () => {
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const language = useBookingStore((state) => state.language);
  const voucherCode = useBookingStore((state) => state.voucherCode);
  const setVoucherCode = useBookingStore((state) => state.setVoucherCode);
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const i18n = translate(language);
  const [localVoucherCode, setLocalVoucherCode] = useState(voucherCode ?? "");
  const [error, setError] = useState("");
  const [focused, setFocused] = useState(false);
  const [redeemable, setRedeemable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkVoucher = async () => {
    setError("");
    setRedeemable(false);

    if (!localVoucherCode || !arrival || !departure) {
      setError(i18n.checkout.voucherInvalidCode);
      return;
    }

    setIsSubmitting(true);

    try {
      const voucherValidateResponse = await validateVoucher({
        code: localVoucherCode,
        arrival: formatDate(arrival),
        departure: formatDate(departure),
      });

      let isRedeemable = false;

      switch (voucherValidateResponse.status) {
        case VoucherStatus.AlreadyRedeemed: {
          isRedeemable = false;
          setError(i18n.checkout.voucherAlreadyRedeemed);
          break;
        }
        case VoucherStatus.Cancelled: {
          isRedeemable = false;
          setError(i18n.checkout.voucherCancelled);
          break;
        }
        case VoucherStatus.Expired: {
          isRedeemable = false;
          setError(i18n.checkout.voucherExpired);
          break;
        }
        case VoucherStatus.Redeemable: {
          isRedeemable = true;
          setRedeemable(true);
          break;
        }
        default: {
          isRedeemable = false;
          setError(i18n.checkout.voucherInvalidCode);
          break;
        }
      }

      if (isRedeemable !== redeemable) {
        setRedeemable(isRedeemable);
        if (voucherCode !== localVoucherCode) {
          setVoucherCode(isRedeemable ? localVoucherCode : null);
          updateCheckoutData();
        }
      }
    } catch {
      setError(i18n.checkout.voucherInvalidCode);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Field
      className={clsx(styles.voucherWrapper, {
        [styles.isInvalid]: !!error,
        [styles.redeemable]: redeemable,
      })}
    >
      <div className={styles.inputWrapper}>
        <Label
          className={clsx(styles.label, {
            [styles.focused]: focused,
            [styles.filled]: localVoucherCode,
          })}
        >
          {i18n.checkout.insertVoucher}
        </Label>
        <Input
          type="text"
          defaultValue={localVoucherCode}
          className={styles.input}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => {
            setRedeemable(false);
            setLocalVoucherCode(e.target.value);
          }}
        />
        <Button
          loading={isSubmitting}
          buttonProps={{
            className: styles.button,
            onClick: () => {
              checkVoucher();
            },
          }}
        >
          {i18n.checkout.applyVoucher}
        </Button>
      </div>
      {error && <Headline size={6} title={error} />}
      {redeemable && localVoucherCode && (
        <Headline
          size={6}
          className={styles.redeemableText}
          title={i18n.checkout.voucherRedeemable(localVoucherCode)}
        />
      )}
    </Field>
  );
};

export default CheckoutVoucher;
