import { RefObject, useEffect } from "react";

type Event = MouseEvent | TouchEvent | KeyboardEvent;

export const useOnInteractionOutside = <T extends HTMLElement | null>(
  ref: RefObject<T>,
  handler: (e: Event) => void,
) => {
  useEffect(() => {
    const listener = (e: Event) => {
      if (ref.current?.contains(e.target as Node)) {
        return;
      }
      handler(e);
    };

    document.addEventListener("pointerdown", listener, true);
    document.addEventListener("keydown", listener, true);

    return () => {
      document.removeEventListener("pointerdown", listener, true);
      document.removeEventListener("keydown", listener, true);
    };
  }, [ref, handler]);
};
