import useSWR from "swr";
import { useBookingStore } from "../hooks/useBookingStore";
import { useRequestFailedAlert } from "../hooks/useRequestFailedAlert";
import { apiRequestDedupingIntervals } from "../utils/constants";
import { fetcher } from "./utils";

interface Country {
  code: string;
  name: string;
}

interface Countries {
  countries: Country[];
}

// TODO: replace with live url: `/countries`
const countriesURL = "/countries-de.json";

export const useCountries = () => {
  const language = useBookingStore((state) => state.language);
  const addRequestFailedAlert = useRequestFailedAlert("getCountries");

  const result = useSWR(
    { url: countriesURL, language },
    (opts) => fetcher<Countries>(opts),
    { dedupingInterval: apiRequestDedupingIntervals.long },
  );

  if (result.error) {
    addRequestFailedAlert();
  }

  return result;
};
