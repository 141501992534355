import { Company, Guest, PrepaymentMethod } from "../../http/booking";

export const toUndefinedIfEmpty = <T>(
  val: T | null | undefined,
): T | undefined => {
  if (val === "") {
    return undefined;
  }

  return val ?? undefined;
};

export enum CheckoutStep {
  Summary = 1,
  Form = 2,
}

export interface CheckoutFormType {
  guest: Omit<Guest, "company">;
  company: Company;
  general: {
    insurance: boolean;
    voucher_code?: string;
    prepayment_method: PrepaymentMethod | "";
    guestType: GuestType;
    privacyPolicy: boolean;
  };
}

export enum GuestType {
  Private = "private",
  Company = "company",
}
