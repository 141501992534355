import { useShallow } from "zustand/react/shallow";
import { BookingStep } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { useAutoOffers } from "../../http/offer";
import { useRatePlans } from "../../http/ratePlan";
import { translate } from "../../i18n";
import PageHeader from "../../layouts/PageHeader";
import Error from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import Skeleton from "../../ui/Skeleton";
import RatePlan from "./RatePlan";
import styles from "./RatePlansAutoOffer.module.css";

const RatePlansAutoOffer = () => {
  const { ratePlansMap, isLoading: isRatePlansLoading } = useRatePlans();
  const { autoOffers, isLoading: isAutoOffersLoading } = useAutoOffers();
  const setStep = useBookingStoreSetStep();
  const language = useBookingStore((state) => state.language);
  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );
  const i18n = translate(language);

  if (autoOfferIndex === null || !currentRoomConfiguration) {
    return <Error />;
  }

  const rates =
    autoOffers?.[autoOfferIndex]?.roomsMap[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ]?.rates;

  return (
    <PageWithHeader
      header={
        <PageHeader
          headline={i18n.ratePlans.selectRate}
          backButton={{
            label: i18n.ratePlans.back,
            onClick: () => setStep(BookingStep.AutoOccupancy),
          }}
        />
      }
    >
      <CenterWrapper>
        <div className={styles.ratePlans}>
          {(isAutoOffersLoading || isRatePlansLoading) && (
            <Skeleton desktopItems={3} />
          )}
          {rates?.map((rate, index) => (
            <RatePlan
              key={index}
              prices={rate.prices}
              ratePlan={ratePlansMap?.[rate.rate_plan_id]}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

export default RatePlansAutoOffer;
