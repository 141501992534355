import { useBookingStore } from "../../hooks/useBookingStore";
import useToggle from "../../hooks/useToggle";
import { LegalTextType, useLegalTexts } from "../../http/legalTexts";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import CommonModal from "../../ui/CommonModal";
import Headline from "../../ui/Headline";
import Skeleton from "../../ui/Skeleton";
import { sanitize } from "../../utils/string";
import styles from "./LegalTextMenuItem.module.css";

interface LegalTextMenuItemProps {
  type: LegalTextType;
  buttonClassName?: string;
  label: string;
}

export const LegalTextMenuItem = ({
  type,
  buttonClassName,
  label,
}: LegalTextMenuItemProps) => {
  const { language } = useBookingStore();
  const i18n = translate(language);
  const modal = useToggle();
  const { legalTexts, isLoading } = useLegalTexts(modal.isOpen);

  return (
    <>
      <Button
        buttonProps={{
          className: buttonClassName,
          onClick: (e) => {
            e.preventDefault();
            modal.open();
          },
        }}
        layout="link"
      >
        {label}
      </Button>
      <CommonModal
        title={i18n.start.legalMenu[type]}
        isOpen={modal.isOpen}
        onClose={modal.close}
      >
        {isLoading && (
          <Skeleton
            desktopItems={1}
            mobileItems={1}
            className={styles.skeleton}
          />
        )}
        {legalTexts && (
          <>
            <div
              dangerouslySetInnerHTML={{ __html: sanitize(legalTexts[type]) }}
            />
            {type === LegalTextType.Imprint && (
              <section>
                <Headline
                  size={3}
                  title={i18n.start.legalMenu.imageSources}
                  as="h2"
                />
                <ul className={styles.images}>
                  {legalTexts.images.map(
                    ({ url, description, copyright, width, height }) => (
                      <li key={url}>
                        <figure className={styles.image}>
                          <img
                            src={url}
                            alt={description}
                            width={width}
                            height={height}
                            loading="lazy"
                            className={styles.img}
                          />
                          <figcaption className={styles.copyright}>
                            © {copyright}
                          </figcaption>
                        </figure>
                      </li>
                    ),
                  )}
                </ul>
              </section>
            )}
          </>
        )}
      </CommonModal>
    </>
  );
};
