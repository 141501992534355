import { useFormContext } from "react-hook-form";
import { Occupancy, Site } from "../../@types";
import { maxChildrenAge } from "../../utils/constants";

interface FormDataChildrenAge {
  age: number;
}

interface FormDataOccupancy {
  adults: number;
  children: FormDataChildrenAge[];
}

export interface FormDataOccupancies {
  useAutoOccupancy: boolean;
  occupancies: FormDataOccupancy[];
}

export const getFormDataOccupancy = (
  occupancy: Occupancy,
  site: Site,
): FormDataOccupancy => ({
  adults: occupancy.adults,
  children: occupancy.children.map((age) => ({
    age: age < site.children_min_age || age > maxChildrenAge ? -1 : age,
  })),
});

export const useOccupancyFormContext = () =>
  useFormContext<FormDataOccupancies>();
