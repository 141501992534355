import clsx from "clsx";
import { PropsWithChildren, ReactNode, useRef, useState } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "./Button";
import { CenteredIcon } from "./CenteredIcon";
import Headline from "./Headline";
import Minus from "./icon/minus.svg?react";
import Plus from "./icon/plus.svg?react";
import styles from "./NumberInput.module.css";
import Tooltip from "./Tooltip";

type NumberInputProps = PropsWithChildren<{
  title: ReactNode;
  value: number;
  min: number;
  max: number;
  onChange: (number: number) => void;
  description?: string | undefined;
  className?: string;
  disabled?: boolean;
  invalid?: boolean;
  maxReachedTooltip?: ReactNode;
}>;

const NumberInput = ({
  title,
  value,
  min,
  max,
  onChange,
  description,
  className,
  disabled,
  invalid,
  maxReachedTooltip,
  children,
}: NumberInputProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);

  const onIncrement = () => {
    if (value < max && !disabled) {
      onChange(value + 1);
    } else if (maxReachedTooltip) {
      setTooltipVisible(true);
    }
  };

  return (
    <>
      <div
        className={clsx(styles.numberInput, className, {
          [styles.disabled]: disabled,
          [styles.invalid]: invalid,
        })}
        data-invalid={invalid && !disabled}
      >
        <div className={styles.text}>
          <Headline size={2} title={title} />
          {description}
        </div>
        <div className={styles.input}>
          <Button
            layout="link"
            buttonProps={{
              onClick: () => (value > min ? onChange(value - 1) : ""),
              className: styles.button,
              "aria-label": i18n.general.less,
              disabled: value <= min || disabled,
            }}
          >
            <CenteredIcon icon={Minus} aria-hidden />
          </Button>
          <input
            type="text"
            inputMode="numeric"
            disabled
            value={value}
            className={styles.value}
          />
          <Button
            layout="link"
            disabledStyle={
              ((value >= max || disabled) && !!maxReachedTooltip) ?? false
            }
            buttonProps={{
              ref,
              onClick: onIncrement,
              className: styles.button,
              "aria-label": i18n.general.more,
              disabled: (value >= max || disabled) && !maxReachedTooltip,
            }}
          >
            <CenteredIcon icon={Plus} aria-hidden />
          </Button>
        </div>
        {children && <div className={styles.content}>{children}</div>}
      </div>
      {maxReachedTooltip && (
        <Tooltip
          isVisible={tooltipVisible}
          anchor={ref.current}
          title={maxReachedTooltip}
          onClose={() => setTooltipVisible(false)}
          duration={6000}
          className={styles.tooltip}
          onIsPositionChange={(floating) => {
            floating.scrollIntoView({ block: "nearest", behavior: "smooth" });
          }}
        />
      )}
    </>
  );
};

export default NumberInput;
