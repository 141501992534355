import { api } from "./utils";

export enum VoucherStatus {
  AlreadyRedeemed = "already_redeemed",
  Cancelled = "cancelled",
  Expired = "expired",
  InvalidCode = "invalid_code",
  Redeemable = "redeemable",
}

interface VoucherResponse {
  status: VoucherStatus;
}

interface VoucherBody {
  code: string;
  arrival: string;
  departure: string;
}

// TODO: replace with live url: `/properties/${propertyId}/vouchers`
const vouchersURL = "/vouchers.json";

export const validateVoucher = (_body: VoucherBody) =>
  // TODO: replace with `api.url(vouchersURL).post(body).json<VoucherResponse>();`
  api.url(vouchersURL).get().json<VoucherResponse>();
