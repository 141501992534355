import { FieldArrayWithId } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useRoomTypes } from "../../http/room";
import OccupancyRoom from "./OccupancyRoom";
import { FormDataOccupancies } from "./utils";

interface OccupancyRoomsProps {
  fields: FieldArrayWithId<FormDataOccupancies, "occupancies">[];
  openedRoom: number;
  onOpen: (index: number) => void;
  onDelete: (index: number) => void;
}

const OccupancyRooms = ({
  fields,
  openedRoom,
  onOpen,
  onDelete,
}: OccupancyRoomsProps) => {
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const { roomTypesMap, isLoading: isRoomTypesLoading } = useRoomTypes({
    skipFetch: !roomTypeId,
  });

  const roomType = roomTypesMap?.[roomTypeId ?? ""];

  return (
    <div>
      {fields.map((occupancy, index) => (
        <OccupancyRoom
          key={occupancy.id}
          index={index}
          open={index === openedRoom}
          roomType={roomType}
          disabled={isRoomTypesLoading}
          onOpen={onOpen}
          onDelete={() => onDelete(index)}
        />
      ))}
    </div>
  );
};

export default OccupancyRooms;
