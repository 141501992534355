import { FunctionComponent, SVGProps } from "react";
import { BoardType } from "../../http/ratePlan";
import AllInclusive from "../../ui/icon/all-inclusive.svg?react";
import BedAndBreakfast from "../../ui/icon/bed-and-breakfast.svg?react";
import FullBoard from "../../ui/icon/full-board.svg?react";
import HalfBoard from "../../ui/icon/half-board.svg?react";
import OvernightStayOnly from "../../ui/icon/overnight-stay-only.svg?react";
import ThreeQuarterBoard from "../../ui/icon/three-quarter-board.svg?react";

const BoardIcons: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>> = {
  [BoardType.OvernightStayOnly]: OvernightStayOnly,
  [BoardType.BedAndBreakfast]: BedAndBreakfast,
  [BoardType.HalfBoard]: HalfBoard,
  [BoardType.ThreeQuarterBoard]: ThreeQuarterBoard,
  [BoardType.FullBoard]: FullBoard,
  [BoardType.AllInclusive]: AllInclusive,
};

export default BoardIcons;
