/* eslint css-modules/no-unused-class: ["error", { markAsUsed: ["lines1", "lines3"] }] */

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import { sanitize } from "../utils/string";
import Button from "./Button";
import styles from "./ReadMore.module.css";

interface ReadMoreProps {
  text: string;
  lines?: 1 | 3;
  className?: string;
}

export const ReadMore = ({ text, lines = 3, className }: ReadMoreProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const elementRef = useRef<HTMLDivElement>(null);
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    const observeTarget = elementRef.current;
    const observer = new ResizeObserver(() => {
      if (observeTarget) {
        setClamped(observeTarget.scrollHeight > observeTarget.clientHeight);
      }
    });

    if (observeTarget) {
      observer.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        observer.unobserve(observeTarget);
      }
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <div
        ref={elementRef}
        className={clsx(className, styles.readMore, styles[`lines${lines}`], {
          [styles.expanded]: isExpanded,
        })}
        dangerouslySetInnerHTML={{ __html: sanitize(text) }}
      />
      {isClamped && !isExpanded && (
        <Button
          layout="link"
          buttonProps={{
            className: styles.button,
            onClick: () => setExpanded(!isExpanded),
          }}
        >
          {i18n.general.readMore}
        </Button>
      )}
    </>
  );
};
