import {
  CalendarDate,
  DayOfWeek,
  endOfMonth,
  isSameDay,
  startOfMonth,
} from "@internationalized/date";
import {
  RangeCalendarState,
  RangeCalendarStateOptions,
  useRangeCalendarState,
} from "@react-stately/calendar";
import clsx from "clsx";
import { addDays, differenceInCalendarMonths } from "date-fns";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRangeCalendar } from "react-aria";
import {
  Virtuoso,
  ItemProps as VirtuosoItemProps,
  ListProps as VirtuosoListProps,
} from "react-virtuoso";
import { useDepartureAvailabilities } from "../../http/availability";
import ArrowLeft from "../../ui/icon/arrow-left.svg?react";
import {
  calendarMonthLoadingThreshold,
  desktopNumberOfMonths,
  maxMonths,
} from "../../utils/constants";
import { parseDate } from "../../utils/date";
import { site } from "../../utils/site";
import styles from "./Calendar.module.css";
import CalendarButton from "./CalendarButton";
import CalendarGrid from "./CalendarGrid";
import CalendarWeekdays from "./CalendarWeekdays";
import { createCalendar, DateRange, isDayInvalid, timeZone } from "./utils";

interface MonthData {
  offset: { months: number };
}

interface VirtuosoContext {
  state: RangeCalendarState;
  scrollMode?: boolean;
}

interface CalendarProps {
  locale: string;
  firstDayOfWeek?: DayOfWeek;
  arrival: Date | null;
  departure: Date | null;
  scrollMode?: boolean;
  hideNavigation?: boolean;
  onSelect?: (range: DateRange) => void;
}

const getCalendarDateFromDate = (date: Date) =>
  new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());

const Calendar = ({
  locale,
  arrival,
  departure,
  scrollMode = false,
  hideNavigation = false,
  onSelect,
  ...props
}: CalendarProps) => {
  const currentDate = useMemo(() => parseDate(site.current_date), []);
  const currentCalendarDate = getCalendarDateFromDate(currentDate);
  const arrivalCalendarDate = arrival ? getCalendarDateFromDate(arrival) : null;
  const departureCalendarDate = departure
    ? getCalendarDateFromDate(departure)
    : null;
  const defaultFocusedDate = scrollMode
    ? currentCalendarDate
    : (arrivalCalendarDate ?? currentCalendarDate);
  const initialTopMostItemIndex = scrollMode
    ? differenceInCalendarMonths(arrival ?? currentDate, currentDate)
    : 0;

  const [visibleMonths, setVisibleMonths] = useState(
    Math.max(
      scrollMode ? desktopNumberOfMonths + 1 : desktopNumberOfMonths,
      initialTopMostItemIndex + 1,
    ),
  );

  const [months, setMonths] = useState<MonthData[]>(
    Array.from({ length: visibleMonths }).map((_, i) => ({
      offset: { months: i },
    })),
  );

  // departure availabilities
  const {
    data: departureAvailabilities,
    isLoading: isDepartureLoading,
    isValidating: isDepartureValidating,
  } = useDepartureAvailabilities(arrival);

  const {
    departureDays,
    minStay,
    maxStay,
    minDepartureDate,
    maxDepartureDate,
  } = useMemo(() => {
    const minStay = departureAvailabilities?.min_stay ?? 1;
    const maxStay = departureAvailabilities?.max_stay ?? 1;
    const minDepartureDate = addDays(arrival ?? currentDate, minStay);
    const maxDepartureDate = addDays(arrival ?? currentDate, maxStay);

    return {
      departureDays:
        departureAvailabilities?.departure_days.map((day) => parseDate(day)) ??
        [],
      minStay,
      maxStay,
      minDepartureDate,
      maxDepartureDate,
    };
  }, [
    arrival,
    departureAvailabilities?.departure_days,
    departureAvailabilities?.max_stay,
    departureAvailabilities?.min_stay,
    currentDate,
  ]);

  const isDayInvalidMemoized = useCallback(
    (day: Date, arrivalDays: Date[]) =>
      isDayInvalid(day, {
        arrival,
        arrivalDays,
        currentDate,
        departure,
        departureDays,
        minStay,
        maxStay,
        maxDepartureDate,
        minDepartureDate,
      }),
    [
      arrival,
      currentDate,
      departure,
      departureDays,
      maxDepartureDate,
      maxStay,
      minDepartureDate,
      minStay,
    ],
  );

  const stateOptions: RangeCalendarStateOptions = {
    ...props,
    minValue: startOfMonth(getCalendarDateFromDate(currentDate)),
    maxValue: endOfMonth(
      getCalendarDateFromDate(currentDate).add({ months: maxMonths }),
    ),
    value: arrivalCalendarDate &&
      departureCalendarDate && {
        start: arrivalCalendarDate,
        end: departureCalendarDate,
      },
    locale,
    visibleDuration: { months: visibleMonths },
    pageBehavior: "single",
    defaultFocusedValue: defaultFocusedDate,
    createCalendar,
    onChange: (value) => {
      const startDate = value.start.toDate(timeZone);
      const endDate = value.end.toDate(timeZone);
      if (isSameDay(value.start, value.end)) {
        onSelect?.({ start: null, end: null });
        return;
      }

      if (arrivalCalendarDate && value.start.compare(arrivalCalendarDate) < 0) {
        onSelect?.({ start: startDate, end: null });
        return;
      }

      if (
        isDayInvalid(endDate, {
          currentDate,
          arrival,
          departure: null,
          arrivalDays: [],
          departureDays,
          minStay,
          maxStay,
          minDepartureDate,
          maxDepartureDate,
        })
      ) {
        onSelect?.({ start: null, end: null });
        return;
      }

      onSelect?.({ start: startDate, end: endDate });
    },
  };

  const state = useRangeCalendarState({ ...stateOptions });

  if (arrivalCalendarDate && !departureCalendarDate && !state.anchorDate) {
    state.setAnchorDate(arrivalCalendarDate);
  }

  useEffect(() => {
    if (!state.anchorDate) {
      return;
    }

    if (
      arrivalCalendarDate &&
      isSameDay(state.anchorDate, arrivalCalendarDate)
    ) {
      return;
    }

    onSelect?.({ start: state.anchorDate.toDate(timeZone), end: null });
  }, [state.anchorDate, arrivalCalendarDate, state, onSelect]);

  const ref = useRef<HTMLDivElement | null>(null);
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
    { ...props },
    state,
    ref,
  );

  return (
    <div {...calendarProps} ref={ref} className={styles.calendar}>
      {!hideNavigation && (
        <div className={styles.navContainer}>
          <div className={styles.navigation}>
            <CalendarButton
              {...prevButtonProps}
              className={clsx(styles.navButton, styles.navButtonPrev)}
            >
              <ArrowLeft className={styles.buttonIcon} aria-hidden />
            </CalendarButton>
            <CalendarButton
              {...nextButtonProps}
              className={clsx(styles.navButton, styles.navButtonNext)}
            >
              <ArrowLeft className={styles.buttonIcon} aria-hidden />
            </CalendarButton>
          </div>
        </div>
      )}
      <div className={styles.monthsContainer}>
        {scrollMode ? (
          <Virtuoso<MonthData, VirtuosoContext>
            components={{
              List: VirtuosoMonthsList,
              Item: VirtuosoMonthWrapper,
            }}
            className={styles.scroller}
            style={{ height: "100%" }}
            data={months}
            context={{ state, scrollMode }}
            itemContent={(_index, data) => (
              <CalendarGrid
                state={state}
                locale={locale}
                hideWeekdays={scrollMode}
                offset={data.offset}
                minStay={departureDays.length ? minStay : 0}
                isDayInvalid={isDayInvalidMemoized}
                isLoading={isDepartureLoading || isDepartureValidating}
              />
            )}
            endReached={() => {
              setVisibleMonths((value) => Math.min(value + 1, maxMonths));
              setMonths((prev) => {
                if (prev.length >= maxMonths) {
                  return prev;
                }

                return [...prev, { offset: { months: prev.length } }];
              });
            }}
            increaseViewportBy={calendarMonthLoadingThreshold}
            initialTopMostItemIndex={initialTopMostItemIndex}
          />
        ) : (
          <div className={styles.months}>
            {months.map((data) => (
              <div key={data.offset.months} className={styles.monthContainer}>
                <CalendarGrid
                  state={state}
                  locale={locale}
                  hideWeekdays={scrollMode}
                  offset={data.offset}
                  minStay={departureDays.length ? minStay : 0}
                  isDayInvalid={isDayInvalidMemoized}
                  isLoading={isDepartureLoading || isDepartureValidating}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const VirtuosoMonthsList = ({
  children,
  context,
  ...rest
}: VirtuosoListProps & { context: VirtuosoContext }) => (
  <div className={styles.months} {...rest}>
    {context.scrollMode && <CalendarWeekdays state={context.state} />}
    {children}
  </div>
);

const VirtuosoMonthWrapper = ({
  children,
  item: _,
  ...rest
}: VirtuosoItemProps<unknown>) => (
  <div className={styles.monthContainer} {...rest}>
    {children}
  </div>
);

export default Calendar;
