import { GuestInteraction, RoomTerm, Site } from "../@types";
import { showGenericError } from "./error";

const getSite = (): Site => {
  const jsonString = document.querySelector("#site-data")?.textContent;
  if (!jsonString) {
    showGenericError();
    throw new Error("#site-data element not found");
  }

  const site = JSON.parse(jsonString) as Site;

  // TODO: These URL parameters were only added for testing and checking the
  // translations. Remove them after testing is complete by reverting the commit
  // that added them.
  const searchParams = new URLSearchParams(location.search);
  const guestInteractionParam = searchParams.get("guest_interaction");
  const guestInteraction = Object.values(GuestInteraction).find(
    (v) => v === guestInteractionParam,
  );
  const roomTermParam = searchParams.get("room_term");
  const roomTerm = Object.values(RoomTerm).find((v) => v === roomTermParam);

  return {
    ...site,
    guest_interaction: guestInteraction ?? site.guest_interaction,
    room_term: roomTerm ?? site.room_term,
  };
};

export const site = getSite();
