import clsx from "clsx";
import { useShallow } from "zustand/react/shallow";
import { HeaderButtonCategory } from "../@types";
import {
  getOccupancySummary,
  selectBookingAmount,
  selectNightsFromArrivalAndDeparture,
  useBookingStore,
} from "../hooks/useBookingStore";
import { useButtonGroupStore } from "../hooks/useButtonGroupStore";
import { translate } from "../i18n";
import Date from "../ui/Date";
import Calendar from "../ui/icon/calendar.svg?react";
import Guests from "../ui/icon/guests.svg?react";
import Luggage from "../ui/icon/luggage.svg?react";
import { formatIntlDate } from "../utils/date";
import { site } from "../utils/site";
import Button from "./Button";
import styles from "./ButtonGroup.module.css";
import CenterWrapper from "./CenterWrapper";
import PriceCounter from "./PriceCounter";

interface ButtonGroupProps {
  className?: string;
}

const ButtonGroup = ({ className }: ButtonGroupProps) => {
  const language = useBookingStore((state) => state.language);
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const bookingAmount = useBookingStore(selectBookingAmount);
  const i18n = translate(language);

  const occupancySummary = getOccupancySummary(
    useBookingStore(
      useShallow((state) => ({
        autoOccupancy: state.autoOccupancy,
        occupancies: state.occupancies,
      })),
    ),
  );

  return (
    <div className={clsx(styles.buttonGroup, className)}>
      <CenterWrapper className={styles.inner}>
        <Button
          glyph={Guests}
          layout="arrow-options"
          active={activeHeaderButton === HeaderButtonCategory.Occupancy}
          buttonProps={{
            className: styles.button,
            onClick: () =>
              setActiveHeaderButton(
                activeHeaderButton === HeaderButtonCategory.Occupancy
                  ? HeaderButtonCategory.None
                  : HeaderButtonCategory.Occupancy,
              ),
          }}
        >
          <span className={styles.buttonContent}>
            <span>{i18n.start.guestsAndRooms(site.room_term)}</span>
            <span className={styles.title}>
              {occupancySummary.children <= 0
                ? i18n.start.adultsCountSummary(occupancySummary.adults)
                : i18n.start.guestsCountSummary(
                    occupancySummary.adults + occupancySummary.children,
                  )}
              {occupancySummary.rooms > 0 && (
                <>
                  ,&nbsp;
                  {i18n.start.roomsCountSummary(
                    occupancySummary.rooms,
                    site.room_term,
                  )}
                </>
              )}
            </span>
          </span>
        </Button>
        <CalendarButton />
        <Button
          glyph={Luggage}
          layout="arrow-options"
          active={activeHeaderButton === HeaderButtonCategory.Checkout}
          buttonProps={{
            className: styles.button,
            onClick: () =>
              setActiveHeaderButton(
                activeHeaderButton === HeaderButtonCategory.Checkout
                  ? HeaderButtonCategory.None
                  : HeaderButtonCategory.Checkout,
              ),
          }}
        >
          <span className={styles.buttonContent}>
            <span>{i18n.start.yourBooking[site.guest_interaction]}</span>
            <span className={styles.title}>
              <PriceCounter price={bookingAmount} />
            </span>
          </span>
        </Button>
      </CenterWrapper>
    </div>
  );
};

const CalendarButton = () => {
  const language = useBookingStore((state) => state.language);
  const arrivalFromBookingStore = useBookingStore((state) => state.arrival);
  const departureFromBookingStore = useBookingStore((state) => state.departure);
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const arrivalFromButtonGroupStore = useButtonGroupStore(
    (state) => state.arrival,
  );
  const departureFromButtonGroupStore = useButtonGroupStore(
    (state) => state.departure,
  );
  const i18n = translate(language);
  const active = activeHeaderButton === HeaderButtonCategory.Calendar;
  const arrival = active
    ? arrivalFromButtonGroupStore
    : arrivalFromBookingStore;
  const departure = active
    ? departureFromButtonGroupStore
    : departureFromBookingStore;
  const nights = selectNightsFromArrivalAndDeparture(arrival, departure);
  const title = nights ? ` ${i18n.start.nights(nights)}` : "";

  return (
    <Button
      glyph={Calendar}
      layout="arrow-options"
      active={active}
      buttonProps={{
        className: styles.button,
        onClick: () =>
          setActiveHeaderButton(
            activeHeaderButton === HeaderButtonCategory.Calendar
              ? HeaderButtonCategory.None
              : HeaderButtonCategory.Calendar,
          ),
      }}
    >
      <span className={styles.buttonContent}>
        <span>{i18n.start.travelPeriod + title}</span>
        <span className={styles.title}>
          {arrival ? (
            <Date date={arrival} formatter={formatIntlDate(language)} />
          ) : (
            <span className={clsx({ [styles.highlighted]: active })}>
              {i18n.start.arrival}
            </span>
          )}
          &nbsp;--&gt;&nbsp;
          {departure ? (
            <Date date={departure} formatter={formatIntlDate(language)} />
          ) : (
            <span className={clsx({ [styles.highlighted]: active && arrival })}>
              {i18n.start.departure}
            </span>
          )}
        </span>
      </span>
    </Button>
  );
};

export default ButtonGroup;
