/* eslint css-modules/no-unused-class: ["error", { markAsUsed: ["error", "warning", "info", "success"] }] */

import clsx from "clsx";
import { ReactNode } from "react";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "../ui/Button";
import CenterWrapper from "../ui/CenterWrapper";
import Headline from "../ui/Headline";
import IconError from "../ui/icon/error.svg?react";
import IconInfo from "../ui/icon/info-alert.svg?react";
import IconSuccess from "../ui/icon/success.svg?react";
import IconWarning from "../ui/icon/warning.svg?react";
import styles from "./Error.module.css";
import StaticPage from "./StaticPage";

export enum ErrorType {
  Error = "error",
  Warning = "warning",
  Info = "info",
  Success = "success",
}

interface ErrorProps {
  title?: ReactNode;
  description?: string;
  buttonTitle?: string;
  buttonOnClick?: () => void;
  type?: ErrorType;
}

const Error = ({
  title,
  description,
  buttonTitle,
  buttonOnClick,
  type = ErrorType.Error,
}: ErrorProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const icon = {
    [ErrorType.Error]: <IconError className={styles.icon} />,
    [ErrorType.Warning]: <IconWarning className={styles.icon} />,
    [ErrorType.Info]: <IconInfo className={styles.icon} />,
    [ErrorType.Success]: <IconSuccess className={styles.icon} />,
  };

  return (
    <StaticPage>
      <CenterWrapper className={clsx(styles.content, styles[type])}>
        <div className={styles.inner}>
          {icon[type]}
          <Headline
            as="h1"
            size={2}
            title={title ?? i18n.general.errorPage.title}
            className={styles.title}
          />
          <Headline
            size={4}
            title={description ?? i18n.general.errorPage.description}
            className={styles.description}
          />
          <Button
            buttonProps={{
              onClick: buttonOnClick ?? (() => globalThis.location.reload()),
              className: styles.button,
            }}
          >
            {buttonTitle ?? i18n.general.errorPage.button}
          </Button>
        </div>
      </CenterWrapper>
    </StaticPage>
  );
};

export default Error;
