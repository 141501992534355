import { ReactNode } from "react";
import styles from "./CommonModal.module.css";
import Modal from "./Modal";

interface CommonModalProps {
  isOpen: boolean;
  title: string;
  underPageHeader?: boolean;
  children: ReactNode;
  onClose: () => void;
}

const CommonModal = ({
  isOpen,
  title,
  underPageHeader = false,
  children,
  onClose,
}: CommonModalProps) => (
  <Modal
    title={title}
    isOpen={isOpen}
    underPageHeader={underPageHeader}
    onClose={onClose}
  >
    <div className={styles.content}>{children}</div>
  </Modal>
);

export default CommonModal;
