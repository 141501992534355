import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { BackButton } from "./BackButton";
import CenterWrapper from "./CenterWrapper";
import styles from "./FullscreenModal.module.css";
import Headline from "./Headline";

interface FullscreenModalProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  headerContent?: ReactNode;
  content?: ReactNode;
  footer?: ReactNode;
  shareView?: boolean;
  fullWidth?: boolean;
}

const FullscreenModal = ({
  isOpen,
  title,
  onClose,
  headerContent,
  content,
  footer,
  shareView,
  fullWidth,
}: FullscreenModalProps) => (
  <Dialog open={isOpen} onClose={onClose} className={styles.modal}>
    <DialogPanel
      className={clsx(styles.dialog, { [styles.shareView]: shareView })}
    >
      <div className={styles.innerDialog}>
        <DialogTitle as="div" className={styles.header}>
          <div className={styles.topHeader}>
            <Headline
              size={2}
              title={title}
              as="div"
              className={styles.headerTitle}
            />
            <BackButton onClick={onClose} />
          </div>
          {headerContent && (
            <div className={styles.headerContent}>{headerContent}</div>
          )}
        </DialogTitle>
        <div className={styles.dialogContent}>
          <Headline
            size={2}
            title={title}
            as="h1"
            className={styles.headline}
          />
          {headerContent && (
            <div className={styles.headerContentDesktop}>{headerContent}</div>
          )}
          <CenterWrapper
            className={clsx(styles.content, { [styles.fullWidth]: fullWidth })}
          >
            {content}
          </CenterWrapper>
        </div>
        {footer && (
          <div className={styles.footerWrapper}>
            <CenterWrapper>{footer}</CenterWrapper>
          </div>
        )}
      </div>
    </DialogPanel>
  </Dialog>
);

export default FullscreenModal;
