import { format, parse } from "date-fns";
import { Language } from "../@types";

const defaultFormatString = "yyyy-MM-dd";

export const parseDate = (
  dateString: string,
  formatString: string = defaultFormatString,
): Date => parse(dateString, formatString, new Date());

export const parseDateNullable = (
  dateString: string,
  formatString: string = defaultFormatString,
): Date | null => {
  const date = parseDate(dateString, formatString);
  if (Number.isNaN(date.getTime())) {
    return null;
  }

  return date;
};

export const formatDate = (
  date: Date,
  formatString: string = defaultFormatString,
): string => format(date, formatString);

export const formatDateNullable = (
  date: Date | null,
  formatString: string = defaultFormatString,
): string | null => {
  if (!date) {
    return null;
  }

  try {
    return formatDate(date, formatString);
  } catch {
    return null;
  }
};

export const formatIntlDate =
  (language: Language, options?: Intl.DateTimeFormatOptions) => (date: Date) =>
    new Intl.DateTimeFormat(language, {
      weekday: "short",
      day: "2-digit",
      month: "short",
      ...options,
    }).format(date);

const getDateFromTimeString = (timeString: string) => {
  const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  if (!timeRegex.test(timeString)) {
    console.warn("invalid time format");
    return null;
  }
  const [hours, minutes] = timeString.split(":").map(Number);
  const date = new Date();
  date.setHours(hours ?? 0, minutes ?? 0, 0, 0);
  return date;
};

export const formatIntlTimeToRangeParts =
  (language: Language, options?: Intl.DateTimeFormatOptions) =>
  (timeStart: Date | string, timeEnd: Date | string) => {
    const dateStart =
      typeof timeStart === "string"
        ? getDateFromTimeString(timeStart)
        : timeStart;
    const dateEnd =
      typeof timeEnd === "string" ? getDateFromTimeString(timeEnd) : timeEnd;

    if (!dateStart || !dateEnd) {
      console.warn("invalid time format");
      return [];
    }

    const formattedParts = new Intl.DateTimeFormat(language, {
      hour: "numeric",
      minute: "numeric",

      ...options,
    }).formatRangeToParts(dateStart, dateEnd);

    return formattedParts;
  };
