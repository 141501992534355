import { addMonths, endOfMonth, startOfMonth } from "date-fns";
import useSWR from "swr";
import { AutoOccupancy, Occupancy, Price } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { useRequestFailedAlert } from "../hooks/useRequestFailedAlert";
import { apiRequestDedupingIntervals } from "../utils/constants";
import { formatDateNullable, parseDate } from "../utils/date";
import { setOccupancySearchParams } from "../utils/occupancy";
import { site } from "../utils/site";
import {
  buildURL,
  fetcher,
  setArrivalAndDepartureSearchParams,
  setArrivalSearchParams,
} from "./utils";

const currentDate = parseDate(site.current_date);
const minDate = startOfMonth(currentDate);
const maxDate = endOfMonth(addMonths(currentDate, 24));

export interface ArrivalAvailabilityDay {
  date: string;
  price: Price;
}

interface ArrivalAvailability {
  arrival_days: ArrivalAvailabilityDay[];
}

interface DepartureAvailability {
  min_stay: number | null;
  max_stay: number | null;
  departure_days: string[];
}

interface AvailabilityDiscounts {
  stays: AvailabilityDiscount[];
}

interface AvailabilityDiscount {
  arrival: string;
  departure: string;
  discount: number;
}

// TODO: replace with live url: `/properties/${propertyId}/availabilities/arrivals`
const arrivalAvailabilitiesURL = "/availabilities-arrival.json";

// TODO: replace with live url: `/properties/${propertyId}/availabilities/departure`
const departureAvailabilitiesURL = "/availabilities-departures.json";

const fetchArrivalAvailabilities = async (searchParamString: string) =>
  fetcher<ArrivalAvailability>({
    url: `${arrivalAvailabilitiesURL}?${searchParamString}`,
  });

interface UseArrivalAvailabilitiesArguments {
  start: Date;
  end: Date;
}

export const useArrivalAvailabilities = ({
  start,
  end,
}: UseArrivalAvailabilitiesArguments) => {
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const addRequestFailedAlert = useRequestFailedAlert(
    "getArrivalAvailabilities",
  );

  const { data, ...rest } = useSWR(
    () => {
      if (start < minDate || end > maxDate) {
        return null;
      }

      const formattedStartDate = formatDateNullable(start);
      const formattedEndDate = formatDateNullable(end);

      if (!formattedStartDate || !formattedEndDate) {
        return null;
      }

      const searchParams = new URLSearchParams();
      searchParams.set("start", formattedStartDate);
      searchParams.set("end", formattedEndDate);

      setOccupancySearchParams(searchParams, {
        autoOccupancy,
        occupancies,
        roomTypeId,
        ratePlanId,
      });

      return searchParams.toString();
    },
    fetchArrivalAvailabilities,
    { dedupingInterval: apiRequestDedupingIntervals.short },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  const dataMap = new Map<string, ArrivalAvailabilityDay>();
  data?.arrival_days.forEach((element: ArrivalAvailabilityDay) =>
    dataMap.set(element.date, element),
  );

  return { ...rest, data, dataMap };
};

const fetchDepartureAvailabilitiesInternal = async (
  searchParamString: string,
) =>
  fetcher<DepartureAvailability>({
    url: `${departureAvailabilitiesURL}?${searchParamString}`,
  });

export const fetchDepartureAvailabilities = async (
  arrival: Date | null,
  {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  }: {
    autoOccupancy?: AutoOccupancy | null | undefined;
    occupancies?: Occupancy[] | undefined;
    roomTypeId: string | null;
    ratePlanId: string | null;
  },
) => {
  const key = getDepartureAvailabilitiesKey(arrival, {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  });

  if (!key) {
    return null;
  }

  return fetchDepartureAvailabilitiesInternal(key);
};

const getDepartureAvailabilitiesKey = (
  arrival: Date | null,
  {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  }: {
    autoOccupancy?: AutoOccupancy | null | undefined;
    occupancies?: Occupancy[] | undefined;
    roomTypeId: string | null;
    ratePlanId: string | null;
  },
) => {
  const searchParams = new URLSearchParams();
  if (!setArrivalSearchParams(searchParams, arrival)) {
    return null;
  }

  setOccupancySearchParams(searchParams, {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  });

  return searchParams.toString();
};

export const useDepartureAvailabilities = (arrival: Date | null) => {
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const addRequestFailedAlert = useRequestFailedAlert(
    "getDepartureAvailabilities",
  );

  const key = getDepartureAvailabilitiesKey(arrival, {
    autoOccupancy,
    occupancies,
    roomTypeId,
    ratePlanId,
  });

  const result = useSWR(
    key,
    (searchParamString) =>
      fetchDepartureAvailabilitiesInternal(searchParamString),
    { dedupingInterval: apiRequestDedupingIntervals.short },
  );

  if (result.error) {
    addRequestFailedAlert();
  }

  return result;
};

// TODO: replace with live url: `/properties/${property_id}/availabilities/discounts`
const discountsURL = "/availabilities-discounts.json";

export const useDiscounts = (arrival: Date | null, departure: Date | null) => {
  const occupancies = useBookingStore((state) => state.occupancies);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const addRequestFailedAlert = useRequestFailedAlert("getDiscounts");
  const { data, ...rest } = useSWR(
    () => {
      const searchParams = new URLSearchParams();
      if (
        !setArrivalAndDepartureSearchParams(searchParams, arrival, departure)
      ) {
        return null;
      }

      setOccupancySearchParams(searchParams, {
        autoOccupancy,
        occupancies,
        roomTypeId,
        ratePlanId,
      });

      return { url: buildURL(discountsURL, searchParams) };
    },
    async (opts) => await fetcher<AvailabilityDiscounts>(opts),
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, ...data };
};
