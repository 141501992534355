import useSWR from "swr";
import { ExtraType, Photo, Price } from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { useRequestFailedAlert } from "../hooks/useRequestFailedAlert";
import { apiRequestDedupingIntervals } from "../utils/constants";
import { formatDateNullable } from "../utils/date";
import { buildURL, fetcher } from "./utils";

export interface Extra {
  id: string;
  category_id: string;
  title: string;
  type: ExtraType;
  price_per_unit: Price;
  photos: Photo[];
  description?: string;
}

interface Extras {
  extras: Extra[];
  extra_categories: { id: string; title: string }[];
}

// TODO: replace with live url: `/properties/{property_id}/extras`
const extrasURL = "/extras-de.json";

export const useExtras = () => {
  const language = useBookingStore((state) => state.language);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const addRequestFailedAlert = useRequestFailedAlert("getExtras");

  const formattedArrival = formatDateNullable(arrival);
  const formattedDeparture = formatDateNullable(departure);

  const searchParams = new URLSearchParams();
  searchParams.set("arrival", formattedArrival ?? "");
  searchParams.set("departure", formattedDeparture ?? "");

  const { data, ...rest } = useSWR(
    formattedArrival && formattedArrival
      ? { url: buildURL(extrasURL, searchParams), language }
      : null,
    (opts) => fetcher<Extras>(opts),
    { dedupingInterval: apiRequestDedupingIntervals.long },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, data };
};
