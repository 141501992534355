import { PropsWithChildren } from "react";
import { HeaderPortal } from "../layouts/Header";
import HeaderLogos from "../layouts/HeaderLogos";
import CenterWrapper from "../ui/CenterWrapper";
import styles from "./StaticPage.module.css";

const StaticPage = ({ children }: PropsWithChildren) => (
  <div className={styles.content}>
    <HeaderPortal>
      <header className={styles.header}>
        <CenterWrapper className={styles.inner}>
          <HeaderLogos showMobileLogo />
        </CenterWrapper>
      </header>
    </HeaderPortal>
    {children}
  </div>
);

export default StaticPage;
