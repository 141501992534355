import { Label, LabelProps } from "@headlessui/react";
import { PropsWithChildren } from "react";

type FormLabelProps = LabelProps &
  PropsWithChildren<{ required?: boolean | undefined }>;

export const FormLabel = ({
  className,
  children,
  required,
  ...rest
}: FormLabelProps) => (
  <Label
    className={className}
    {...rest}
    // TODO: HeadlessUI currently has a bug that generates an invalid for attribute. We hope this issue will be resolved soon
  >
    {children}
    {required && <>*</>}
  </Label>
);
