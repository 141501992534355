import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import TrashIcon from "../../ui/icon/trash.svg?react";

import styles from "./CalendarFilter.module.css";

interface CalendarFilterProps {
  label: string;
  text: string;
  onRemove?: () => void;
}

const CalendarFilter = ({ label, text, onRemove }: CalendarFilterProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.filter}>
      <div>
        {label && <span className={styles.bold}>{label}</span>}
        {text && <span>: {text}</span>}
      </div>
      {onRemove && (
        <Button
          layout="link"
          buttonProps={{
            onClick: onRemove,
            "aria-label": i18n.general.remove,
            className: styles.removeButton,
          }}
        >
          <TrashIcon className={styles.removeIcon} />
        </Button>
      )}
    </div>
  );
};

export default CalendarFilter;
