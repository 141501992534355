import { useBookingStore } from "../../hooks/useBookingStore";
import Date from "../../ui/Date";
import NumberInput from "../../ui/NumberInput";
import { maxExtraQuantity } from "../../utils/constants";
import { formatIntlDate, parseDate } from "../../utils/date";
import ExtraControlledQuantityField from "./ExtraControlledQuantityField";
import styles from "./ExtraPerDay.module.css";
import { useQuantitiesFormFields } from "./utils";

const ExtraPerDay = () => {
  const language = useBookingStore((state) => state.language);

  const { control, setValue, fields } = useQuantitiesFormFields();

  return (
    <div className={styles.inputs}>
      {fields.map((quantity, index) => (
        <ExtraControlledQuantityField
          key={quantity.id}
          control={control}
          index={index}
          render={({ field: { value } }) => (
            <NumberInput
              title={
                <Date
                  date={parseDate(quantity.key)}
                  formatter={formatIntlDate(language)}
                />
              }
              value={value ?? 0}
              min={0}
              max={maxExtraQuantity}
              onChange={(changedValue) =>
                setValue(`quantities.${index}.quantity`, changedValue, {
                  shouldDirty: true,
                  shouldTouch: true,
                  shouldValidate: true,
                })
              }
            />
          )}
        />
      ))}
    </div>
  );
};

export default ExtraPerDay;
