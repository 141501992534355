interface TimeRangeProps {
  start: string;
  end: string;
  rangePartsFormatter: (
    start: string,
    end: string,
  ) => Intl.DateTimeRangeFormatPart[];
}

const TimeRange = ({ start, end, rangePartsFormatter }: TimeRangeProps) => {
  const parts = rangePartsFormatter(start, end);

  if (!parts.length) {
    return null;
  }

  const startSharedParts: string[] = [];
  const startRangeParts: string[] = [];
  const middleSharedParts: string[] = [];
  const endRangeParts: string[] = [];
  const endSharedParts: string[] = [];

  parts.forEach((rangePart) => {
    if (rangePart.source === "startRange") {
      startRangeParts.push(rangePart.value);
    } else if (rangePart.source === "endRange") {
      endRangeParts.push(rangePart.value);
    } else {
      if (endRangeParts.length) {
        endSharedParts.push(rangePart.value);
      } else if (startRangeParts.length) {
        middleSharedParts.push(rangePart.value);
      } else {
        startSharedParts.push(rangePart.value);
      }
    }
  });

  return (
    <>
      {startSharedParts.join("")}
      <time dateTime={start}>{startRangeParts.join("")}</time>
      {middleSharedParts.join("")}
      <time dateTime={end}>{endRangeParts.join("")}</time>
      {endSharedParts.join("")}
    </>
  );
};

export default TimeRange;
