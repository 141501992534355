import { useEffect } from "react";
import { WretchError } from "wretch/resolver";
import { BookingStep, HeaderButtonCategory } from "./@types";
import AutoOccupancy from "./domain/autoOccupancy/AutoOccupancy";
import Boards from "./domain/board/Boards";
import Checkout from "./domain/checkout/Checkout";
import Extras from "./domain/extra/Extras";
import Init from "./domain/init/Init";
import RatePlanDetail from "./domain/ratePlan/RatePlanDetail";
import RatePlansAutoOffer from "./domain/ratePlan/RatePlansAutoOffer";
import RoomConfigurations from "./domain/room/RoomConfigurations";
import RoomDetailRatePlans from "./domain/room/RoomDetailRatePlans";
import Rooms from "./domain/room/Rooms";
import Start from "./domain/start/Start";
import {
  HistoryStateWithBookingStore,
  useBookingStore,
  useBookingStoreUpdateStateFromHistory,
} from "./hooks/useBookingStore";
import {
  HistoryStateWithModalHistoryIdentifiers,
  useModalHistoryStore,
} from "./hooks/useModalHistory";
import { useRequestFailedAlert } from "./hooks/useRequestFailedAlert";
import Error, { ErrorType } from "./pages/Error";
import PageWithHeader from "./pages/PageWithHeader";

const Router = () => {
  const currentStep = useBookingStore((state) => state.step);
  const activeHeaderButton = useBookingStore(
    (state) => state.activeHeaderButton,
  );
  const checkoutDataError = useBookingStore((state) => state.checkoutDataError);
  const isUpdatingCheckoutData = useBookingStore(
    (state) => state.isUpdatingCheckoutData,
  );
  const updateStateFromHistory = useBookingStoreUpdateStateFromHistory();
  const updateModalHistoryStateFromHistory = useModalHistoryStore(
    (state) => state.updateStateFromHistory,
  );
  const addPostSelectedOffersAlert =
    useRequestFailedAlert("postSelectedOffers");

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      updateStateFromHistory(
        (e.state as HistoryStateWithBookingStore | undefined)?.bookingStore,
      );
      updateModalHistoryStateFromHistory(
        (e.state as HistoryStateWithModalHistoryIdentifiers | undefined)
          ?.modalHistoryIdentifiers,
      );
    };
    globalThis.addEventListener("popstate", handlePopstate);
    return () => {
      globalThis.removeEventListener("popstate", handlePopstate);
    };
  }, [updateModalHistoryStateFromHistory, updateStateFromHistory]);

  if (!isUpdatingCheckoutData && checkoutDataError) {
    if (checkoutDataError instanceof WretchError) {
      if (checkoutDataError.status === 409) {
        return <Error type={ErrorType.Warning} />;
      }
      addPostSelectedOffersAlert();
    } else {
      throw checkoutDataError;
    }
  }

  switch (currentStep) {
    case BookingStep.Start:
    case BookingStep.Occupancy:
    case BookingStep.Calendar: {
      switch (activeHeaderButton) {
        case HeaderButtonCategory.None: {
          return <Start />;
        }
        default: {
          return <PageWithHeader backgroundImage />;
        }
      }
    }
    case BookingStep.AutoOccupancy: {
      return <AutoOccupancy />;
    }
    case BookingStep.RoomConfigurations: {
      return <RoomConfigurations />;
    }
    case BookingStep.Rooms: {
      return <Rooms />;
    }
    case BookingStep.RatePlans: {
      return <RoomDetailRatePlans />;
    }
    case BookingStep.RatePlansAutoOffer: {
      return <RatePlansAutoOffer />;
    }
    case BookingStep.RatePlan: {
      return <RatePlanDetail />;
    }
    case BookingStep.Boards: {
      return <Boards />;
    }
    case BookingStep.Extras: {
      return <Extras />;
    }
    case BookingStep.Checkout: {
      return <Checkout />;
    }
    default: {
      return <Init />;
    }
  }
};

export default Router;
