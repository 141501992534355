import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";
import { Alert, AlertType, useAlertStore } from "../hooks/useAlertStore";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import styles from "./AlertMessage.module.css";
import Button from "./Button";
import Close from "./icon/close.svg?react";
import Error from "./icon/error.svg?react";
import InfoAlert from "./icon/info-alert.svg?react";
import Success from "./icon/success.svg?react";
import Warning from "./icon/warning.svg?react";

const getIcon = (alert: Alert) => {
  switch (alert.type) {
    case AlertType.Info: {
      return InfoAlert;
    }
    case AlertType.Success: {
      return Success;
    }
    case AlertType.Warning: {
      return Warning;
    }
    case AlertType.Error: {
      return Error;
    }
    default: {
      // exhaustive check
      return alert.type satisfies never;
    }
  }
};

interface AlertMessageProps {
  alert: Alert;
}

const AlertMessage = ({ alert }: AlertMessageProps) => {
  const [show, setShow] = useState(true);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  // const alerts = useAlertStore((state) => state.alerts);
  const removeAlert = useAlertStore((state) => state.removeAlert);

  // TODO: define if the first or last alert should be shown
  // first is more stable, last probably more relevant for the user
  // const alert = alerts[alerts.length - 1];

  const Icon = getIcon(alert);

  return (
    <Transition show={show} appear afterLeave={() => removeAlert(alert.id)}>
      <div className={styles.container}>
        <div
          className={clsx(styles.alert, {
            [styles.info]: alert.type === AlertType.Info,
            [styles.success]: alert.type === AlertType.Success,
            [styles.warning]: alert.type === AlertType.Warning,
            [styles.error]: alert.type === AlertType.Error,
          })}
        >
          <Icon className={styles.icon} />
          <div className={styles.text}>
            <div className={styles.title}>{alert.title}</div>
            {alert.description && <div>{alert.description}</div>}
          </div>
          <Button
            layout="link"
            buttonProps={{
              onClick: () => setShow(false),
              "aria-label": i18n.general.close,
              className: styles.close,
            }}
          >
            <Close />
          </Button>
        </div>
      </div>
    </Transition>
  );
};

export default AlertMessage;
