import { useRoomTypes } from "../../http/room";
import Error from "../../pages/Error";
import StaticPage from "../../pages/StaticPage";
import RoomDetail from "./RoomDetail";

interface StaticRoomDetailProps {
  roomId: string;
}

const StaticRoomDetail = ({ roomId }: StaticRoomDetailProps) => {
  const { roomTypesMap, error, isLoading } = useRoomTypes();
  const room = roomTypesMap?.[roomId];

  if (isLoading) {
    return null;
  }

  if (!room || error) {
    return <Error />;
  }

  return (
    <StaticPage>
      <RoomDetail room={room} shareView />
    </StaticPage>
  );
};

export default StaticRoomDetail;
