import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";
import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { OfferBoard } from "../../http/offer";
import { Inclusive as InclusiveType, RatePlan } from "../../http/ratePlan";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import DetailModal from "../../ui/DetailModal";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import Board from "../../ui/icon/board.svg?react";
import InclusiveIcon from "../../ui/icon/inclusive.svg?react";
import LightGallery from "../../ui/LightGallery";
import PhotoSlider from "../../ui/PhotoSlider";
import { ReadMore } from "../../ui/ReadMore";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Slider from "../../ui/Slider";
import styles from "./RatePlanDetailModal.module.css";

interface RatePlanDetailModalProps {
  ratePlan: RatePlan;
  boardTypes: OfferBoard[];
  open: boolean;
  onClose: () => void;
}

const RatePlanDetailModal = ({
  ratePlan,
  boardTypes,
  open,
  onClose,
}: RatePlanDetailModalProps) => {
  const language = useBookingStore((state) => state.language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const i18n = translate(language);
  const setStep = useBookingStoreSetStep();

  const modalIdentifier = `RatePlan_${ratePlan.id}_ReservationPolicies`;
  const setShowAllReservationPolicies = useModalHistoryToggle(modalIdentifier);
  const showAllReservationPolicies = useModalHistoryStore(
    isModalActive(modalIdentifier),
  );

  return (
    <DetailModal open={open} onClose={onClose}>
      <div className={styles.details}>
        <div className={styles.titleWrapper}>
          <Headline
            size={2}
            as="h1"
            title={ratePlan.title}
            className={styles.title}
          />
        </div>
        <CenterWrapper>
          <Headline
            size={5}
            as="h2"
            title={i18n.ratePlans.general}
            className={styles.descriptionTitle}
          />
          <ReadMore text={ratePlan.description} />
        </CenterWrapper>
      </div>
      <PhotoSlider
        photos={ratePlan.photos}
        imageSizes="(min-width: 1125px) 718px, 100vw"
        showAllButton
        className={styles.sliderContainer}
      />
      <CenterWrapper className={styles.additionalDetails}>
        <div className={styles.block}>
          <Headline size={5} as="h2" title={i18n.ratePlans.inclusives} />
          <div
            className={clsx(styles.additionalDetailsContent, styles.inclusives)}
          >
            {ratePlan.inclusives.map((inclusive) => (
              <Inclusive key={inclusive.id} inclusive={inclusive} />
            ))}
          </div>
        </div>
        <div className={styles.block}>
          <h2>
            <Headline size={5} as="span" title={i18n.ratePlans.boardsTitle} />
            <Headline
              size={4}
              as="span"
              title={` ${i18n.ratePlans.boardsInfo}`}
            />
          </h2>
          <div
            className={clsx(styles.additionalDetailsContent, styles.boardTypes)}
          >
            {boardTypes.map((boardType, index) => (
              <div key={index} className={styles.boardType}>
                <Board className={styles.boardTypeIcon} aria-hidden />
                <div>
                  {i18n.boards[boardType.board_type]}
                  {boardType.surcharge.amount <= 0 &&
                    ` ${i18n.ratePlans.includedInRate}`}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.block}>
          <Headline
            size={5}
            as="h2"
            title={i18n.ratePlans.reservationPolicies}
          />
          <div className={styles.additionalDetailsContent}>
            <ul className={styles.reservationPolicies}>
              {ratePlan.reservation_policies.summary.map((condition, index) => (
                <li key={index}>{condition}</li>
              ))}
            </ul>
          </div>
          <Button
            layout="link"
            buttonProps={{
              className: styles.showMoreReservationPoliciesButton,
              onClick: () => setShowAllReservationPolicies(true),
            }}
          >
            {i18n.ratePlans.moreDetails}
          </Button>
          <FullscreenModal
            isOpen={showAllReservationPolicies}
            onClose={() => setShowAllReservationPolicies(false)}
            title={i18n.ratePlans.reservationPolicies}
            content={
              <ul className={styles.reservationPolicies}>
                {ratePlan.reservation_policies.full.map((condition, index) => (
                  <li key={index}>{condition}</li>
                ))}
              </ul>
            }
          />
        </div>
      </CenterWrapper>
      <div className={styles.buttonWrapper}>
        <CenterWrapper className={styles.buttonCenterWrapper}>
          <Button
            layout="primary"
            buttonProps={{
              className: styles.continueButton,
              onClick: () => {
                updateRoomConfiguration({ ratePlanId: ratePlan.id });
                updateCheckoutData();
                setStep(BookingStep.Boards);
              },
            }}
          >
            {i18n.ratePlans.selectAndContinue}
          </Button>
        </CenterWrapper>
      </div>
    </DetailModal>
  );
};

interface InclusiveProps {
  inclusive: InclusiveType;
}

const Inclusive = ({ inclusive }: InclusiveProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [showInclusivePhotos, setShowInclusivePhotos] = useState(false);

  return (
    <Disclosure>
      {({ open }) => (
        <div className={styles.inclusive}>
          <DisclosureButton className={styles.inclusiveButton}>
            <span className={styles.inclusiveTitleWrapper}>
              {inclusive.thumbnail ? (
                <img
                  src={inclusive.thumbnail.url}
                  width={inclusive.thumbnail.width}
                  height={inclusive.thumbnail.height}
                  alt={inclusive.title}
                  className={styles.inclusivePhoto}
                />
              ) : (
                <InclusiveIcon className={styles.inclusivePhoto} aria-hidden />
              )}
              {inclusive.title}
            </span>
            <ArrowDown
              className={clsx(styles.inclusiveArrow, {
                [styles.inclusiveArrowOpen]: open,
              })}
            />
          </DisclosureButton>
          <DisclosurePanel className={styles.inclusiveContent}>
            {inclusive.photos.length > 0 && (
              <>
                <LightGallery
                  photos={inclusive.photos}
                  isOpen={showInclusivePhotos}
                  onClose={() => setShowInclusivePhotos(false)}
                />
                <Slider
                  className={styles.inclusiveSliderContainer}
                  dots={inclusive.photos.length > 1}
                >
                  {inclusive.photos.map((photo, index) => (
                    <a
                      className={styles.inclusiveSlide}
                      aria-label={i18n.general.openLightbox}
                      key={index}
                      href={photo.derivatives[0]?.url}
                      tabIndex={-1}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowInclusivePhotos(true);
                      }}
                    >
                      <ResponsiveImage
                        srcSet={photo.derivatives}
                        alt={photo.description}
                        sizes="(min-width: 1125px) 331px, (min-width: 919px) 33vw, (min-width: 619px) 50vw, 100vw"
                        className={styles.inclusiveSliderImage}
                        lazyLoad
                      />
                    </a>
                  ))}
                </Slider>
              </>
            )}
            {inclusive.description}
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
};

export default RatePlanDetailModal;
