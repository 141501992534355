import clsx from "clsx";
import { PropsWithChildren, useState } from "react";
import { createPortal } from "react-dom";
import { useShallow } from "zustand/react/shallow";
import { BookingStep, HeaderButtonCategory } from "../@types";
import {
  getOccupancySummary,
  selectBookingAmount,
  useBookingStore,
} from "../hooks/useBookingStore";
import { translate } from "../i18n";
import Button from "../ui/Button";
import ButtonGroup from "../ui/ButtonGroup";
import CenterWrapper from "../ui/CenterWrapper";
import Date from "../ui/Date";
import Calendar from "../ui/icon/calendar.svg?react";
import Close from "../ui/icon/close.svg?react";
import Edit from "../ui/icon/edit.svg?react";
import Guest from "../ui/icon/guests.svg?react";
import Luggage from "../ui/icon/luggage.svg?react";
import { formatIntlDate } from "../utils/date";
import { formatMoney } from "../utils/number";
import { site } from "../utils/site";
import styles from "./Header.module.css";
import HeaderLogos from "./HeaderLogos";

interface HeaderProps {
  hideOnDesktop?: boolean;
}

export const HeaderPortal = ({ children }: PropsWithChildren) =>
  createPortal(children, document.body);

const Header = ({ hideOnDesktop }: HeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const step = useBookingStore((state) => state.step);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );
  const bookingAmount = useBookingStore(selectBookingAmount);
  const i18n = translate(language);
  const [headerSummaryOpen, setHeaderSummaryOpen] = useState(false);

  const occupancySummary = getOccupancySummary(
    useBookingStore(
      useShallow((state) => ({
        autoOccupancy: state.autoOccupancy,
        occupancies: state.occupancies,
      })),
    ),
  );

  return (
    <HeaderPortal>
      <header
        className={clsx(styles.header, {
          [styles.hideOnDesktop]: hideOnDesktop,
          [styles.headerSummaryOpen]: headerSummaryOpen,
          [styles.headerStart]: [
            BookingStep.Start,
            BookingStep.Occupancy,
            BookingStep.Calendar,
          ].includes(step),
        })}
      >
        <CenterWrapper className={styles.inner}>
          <HeaderLogos />
          {!hideOnDesktop && <ButtonGroup className={styles.buttonGroup} />}
          <Button
            glyph={Luggage}
            layout="checkout"
            buttonProps={{
              className: styles.checkout,
              "aria-label": i18n.start.yourBooking[site.guest_interaction],
              onClick: () =>
                setActiveHeaderButton(HeaderButtonCategory.Checkout),
            }}
          >
            {formatMoney(bookingAmount, language)}
          </Button>
        </CenterWrapper>
        {![
          BookingStep.Start,
          BookingStep.Occupancy,
          BookingStep.Calendar,
        ].includes(step) && (
          <div className={styles.mobileSummary}>
            <div className={styles.summaryHeader}>
              <div className={styles.dateWrapper}>
                <Calendar className={styles.dateIcon} aria-hidden />
                <div>
                  {arrival ? (
                    <Date date={arrival} formatter={formatIntlDate(language)} />
                  ) : (
                    i18n.start.arrival
                  )}
                  &nbsp;--&gt;&nbsp;
                  {departure ? (
                    <Date
                      date={departure}
                      formatter={formatIntlDate(language)}
                    />
                  ) : (
                    i18n.start.departure
                  )}
                </div>
              </div>
              <div className={styles.occupancyWrapper}>
                <Guest
                  className={styles.occupancyIcon}
                  title={i18n.start.guests}
                  role="img"
                />
                {occupancySummary.adults + occupancySummary.children}
              </div>
              <Button
                layout="link"
                buttonProps={{
                  onClick: () => setHeaderSummaryOpen(!headerSummaryOpen),
                  className: clsx({ [styles.closeButton]: headerSummaryOpen }),
                  "aria-label": headerSummaryOpen
                    ? i18n.general.close
                    : i18n.general.edit,
                }}
              >
                {headerSummaryOpen ? (
                  <Close className={styles.closeButtonIcon} />
                ) : (
                  <Edit className={styles.editButtonIcon} />
                )}
              </Button>
            </div>
          </div>
        )}
        {headerSummaryOpen && (
          <div className={styles.mobileSummaryContent}>
            <ButtonGroup />
          </div>
        )}
      </header>
    </HeaderPortal>
  );
};

export default Header;
