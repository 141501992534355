import useSWR from "swr";
import { useBookingStore } from "../hooks/useBookingStore";
import { useRequestFailedAlert } from "../hooks/useRequestFailedAlert";
import { apiRequestDedupingIntervals } from "../utils/constants";
import { fetcher } from "./utils";

export enum LegalTextType {
  Imprint = "imprint",
  Privacy = "privacy",
  Terms = "terms",
}

interface LegalTexts {
  [LegalTextType.Imprint]: string;
  [LegalTextType.Privacy]: string;
  [LegalTextType.Terms]: string;
  images: Image[];
}

interface Image {
  url: string;
  description: string;
  copyright: string;
  width: number;
  height: number;
}

// TODO: replace with live url: `/sites/${siteId}/legal_texts`
const url = "/legal-texts-de.json";

export const useLegalTexts = (shouldFetch: boolean) => {
  const language = useBookingStore((state) => state.language);
  const addRequestFailedAlert = useRequestFailedAlert("getLegalTexts");

  const { data: legalTexts, ...rest } = useSWR(
    shouldFetch ? { url, language } : null,
    (opts) => fetcher<LegalTexts>(opts),
    { dedupingInterval: apiRequestDedupingIntervals.long },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, legalTexts };
};
