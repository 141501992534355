import clsx from "clsx";
import { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { PropsWithChildren, ReactNode, useCallback, useEffect } from "react";
import styles from "./Slider.module.css";
import { NextButton, PrevButton, usePrevNextButtons } from "./SliderArrows";
import { DotButton, useDotButton } from "./SliderDots";

type SliderProps = PropsWithChildren<{
  arrows?: boolean;
  dots?: boolean;
  loop?: boolean;
  className?: string;
  customControls?: ReactNode;
}>;

const Slider = ({
  arrows = false,
  dots = true,
  loop = false,
  className,
  children,
  customControls,
}: SliderProps) => {
  const [emblaRef, emblaAPI] = useEmblaCarousel({ loop });
  const [dotsEmblaRef, dotsEmblaAPI] = useEmblaCarousel({
    containScroll: "keepSnaps",
    watchDrag: false,
  });
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaAPI);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaAPI);

  const onSelect = useCallback(
    (emblaAPI: EmblaCarouselType) => {
      if (!dotsEmblaAPI) return;
      dotsEmblaAPI.scrollTo(emblaAPI.selectedScrollSnap());
    },
    [dotsEmblaAPI],
  );

  useEffect(() => {
    if (!emblaAPI) return;

    onSelect(emblaAPI);
    emblaAPI.on("reInit", onSelect).on("select", onSelect);
  }, [emblaAPI, onSelect]);

  return (
    <div className={clsx(styles.sliderWrapper, className)}>
      <div className={styles.sliderContainer} ref={emblaRef}>
        <div className={styles.slider}>{children}</div>
      </div>
      {arrows && (
        <>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </>
      )}
      {dots && (
        <div className={styles.dotsSliderWrapper}>
          <div className={styles.dotsSlider} ref={dotsEmblaRef}>
            <div className={styles.dots}>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  active={index === selectedIndex}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {customControls && customControls}
    </div>
  );
};

export default Slider;
