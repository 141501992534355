import { Photo } from "../@types";
import styles from "./LightGallery.module.css";
import Modal from "./Modal";
import ResponsiveImage from "./ResponsiveImage";

interface LightGalleryProps {
  photos: Photo[];
  isOpen: boolean;
  underPageHeader?: boolean;
  onClose: () => void;
}

const LightGallery = ({
  photos,
  isOpen,
  underPageHeader = true,
  onClose,
}: LightGalleryProps) => (
  <Modal
    isOpen={isOpen}
    underPageHeader={underPageHeader}
    roundedCorners={false}
    onClose={onClose}
  >
    <div className={styles.photosWrapper}>
      <div className={styles.photos}>
        {photos.map((photo, index) => (
          <ResponsiveImage
            key={index}
            className={styles.photo}
            alt={photo.description}
            title={photo.description}
            srcSet={photo.derivatives}
            sizes={
              index % 3 === 0
                ? "(min-width: 1125px) 1093px, 100vw"
                : "(min-width: 1125px) 531px, 50vw"
            }
            lazyLoad
          />
        ))}
      </div>
    </div>
  </Modal>
);

export default LightGallery;
