import { Language } from "../../@types";
import HGVLogo from "../../assets/hgv.svg?react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { LegalTextType } from "../../http/legalTexts";
import { translate } from "../../i18n";
import Header from "../../layouts/Header";
import ButtonGroup from "../../ui/ButtonGroup";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Select from "../../ui/Select";
import { getImagesByOrientation } from "../../utils/image";
import { getLanguageByCode } from "../../utils/language";
import { site } from "../../utils/site";
import { LegalTextMenuItem } from "../legalText/LegalTextMenuItem";
import styles from "./Start.module.css";

const Start = () => {
  const language = useBookingStore((state) => state.language);
  const setLanguage = useBookingStore((state) => state.setLanguage);
  const i18n = translate(language);
  const bgImage = getImagesByOrientation(site.background_image.derivatives);
  const bgDescription = site.background_image.description[language];

  return (
    <div className={styles.start}>
      <Header hideOnDesktop />
      <ResponsiveImage
        className={styles.backgroundImage}
        alt={bgDescription}
        sizes="100vw"
        title={bgDescription}
        srcSet={bgImage.landscape}
        srcSetPortrait={bgImage.portrait}
      />
      <img
        className={styles.logo}
        alt={site.property_name}
        title={site.property_name}
        src={site.logo_light.url}
        width={site.logo_light.width}
        height={site.logo_light.height}
      />
      <ButtonGroup className={styles.buttonGroup} />
      <div className={styles.linksWrapper}>
        <div className={styles.links}>
          <LegalTextMenuItem
            type={LegalTextType.Privacy}
            label={i18n.start.legalMenu.privacy}
          />
          <LegalTextMenuItem
            type={LegalTextType.Imprint}
            label={i18n.start.legalMenu.imprint}
          />
          <LegalTextMenuItem
            type={LegalTextType.Terms}
            label={i18n.start.legalMenu.terms}
          />
          <Select
            name="language"
            value={language}
            options={Object.values(Language).map((value) => ({
              key: value,
              value,
            }))}
            onChange={(e) => setLanguage(getLanguageByCode(e.target.value))}
          />
        </div>
        <HGVLogo className={styles.hgvLogo} title={i18n.start.hgv} role="img" />
      </div>
    </div>
  );
};

export default Start;
