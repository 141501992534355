import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  isAutoOfferRoomConfiguration,
  selectCurrentRoomConfiguration,
  selectNights,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import {
  isModalActive,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { OfferBoard, useAutoOffers, useOffers } from "../../http/offer";
import { useRatePlans } from "../../http/ratePlan";
import { translate } from "../../i18n";
import PageHeader from "../../layouts/PageHeader";
import Error from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import { CardImage } from "../../ui/CardImage";
import CenterWrapper from "../../ui/CenterWrapper";
import FullscreenModal from "../../ui/FullscreenModal";
import Headline from "../../ui/Headline";
import Check from "../../ui/icon/check.svg?react";
import LightGallery from "../../ui/LightGallery";
import Skeleton from "../../ui/Skeleton";
import { formatMoney } from "../../utils/number";
import BoardIcons from "./BoardIcons";
import styles from "./Boards.module.css";
import { Board as BoardType, mergeBoards } from "./utils";

const Boards = () => {
  const isAutoOffer = useBookingStore(isAutoOfferRoomConfiguration);
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );

  if (!currentRoomConfiguration) {
    return <Error />;
  }

  return isAutoOffer ? (
    <BoardsAutoOffers currentRoomConfiguration={currentRoomConfiguration} />
  ) : (
    <BoardsOffers currentRoomConfiguration={currentRoomConfiguration} />
  );
};

interface BoardsProps {
  currentRoomConfiguration: RoomConfiguration;
}

const BoardsOffers = ({ currentRoomConfiguration }: BoardsProps) => {
  const { offersMap, isLoading } = useOffers();
  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const offerBoards =
    offer?.rates.find(
      (rate) => rate.rate_plan_id === currentRoomConfiguration.ratePlanId,
    )?.board_types ?? [];

  return (
    <BoardList
      boards={offerBoards}
      currentRoomConfiguration={currentRoomConfiguration}
      offersLoading={isLoading}
    />
  );
};

const BoardsAutoOffers = ({ currentRoomConfiguration }: BoardsProps) => {
  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  const { autoOffers, isLoading } = useAutoOffers();

  if (autoOfferIndex === null) {
    return <Error />;
  }

  const offerRoom =
    autoOffers?.[autoOfferIndex]?.roomsMap[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const offerBoards =
    offerRoom?.rates.find(
      (rate) => rate.rate_plan_id === currentRoomConfiguration.ratePlanId,
    )?.board_types ?? [];

  return (
    <BoardList
      boards={offerBoards}
      currentRoomConfiguration={currentRoomConfiguration}
      isAutoOffer
      offersLoading={isLoading}
    />
  );
};

interface BoardListProps {
  boards: OfferBoard[];
  currentRoomConfiguration: RoomConfiguration;
  isAutoOffer?: boolean;
  offersLoading?: boolean;
}

const BoardList = ({
  boards,
  currentRoomConfiguration,
  isAutoOffer = false,
  offersLoading = false,
}: BoardListProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const setStep = useBookingStoreSetStep();
  const { ratePlansMap, isLoading: ratePlansLoading } = useRatePlans();
  const ratePlanBoards =
    ratePlansMap?.[currentRoomConfiguration.ratePlanId ?? ""]?.boards ?? [];

  return (
    <PageWithHeader
      header={
        <PageHeader
          headline={i18n.boards.selectBoard}
          backButton={{
            label: i18n.boards.back,
            onClick: () =>
              setStep(
                isAutoOffer
                  ? BookingStep.RatePlansAutoOffer
                  : BookingStep.RatePlans,
              ),
          }}
        />
      }
    >
      <CenterWrapper>
        <div className={styles.boards}>
          {(offersLoading || ratePlansLoading) && <Skeleton />}
          {mergeBoards(boards, ratePlanBoards).map((board, index) => (
            <Board
              key={index}
              board={board}
              currentRoomConfiguration={currentRoomConfiguration}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

interface BoardProps {
  board: BoardType;
  currentRoomConfiguration: RoomConfiguration;
}

const Board = ({ board, currentRoomConfiguration }: BoardProps) => {
  const [showBoardPhotos, setShowBoardPhotos] = useState(false);
  const language = useBookingStore((state) => state.language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );
  const setStep = useBookingStoreSetStep();
  const nights = useBookingStore(selectNights);
  const i18n = translate(language);
  const { adults = 0, children = [] } = currentRoomConfiguration;

  const modalIdentifier = `Board_${board.type}`;
  const toggleDetailModal = useModalHistoryToggle(modalIdentifier);
  const showDetailModal = useModalHistoryStore(isModalActive(modalIdentifier));

  const selectBoard = () => {
    updateRoomConfiguration({ boardType: board.type });
    updateCheckoutData();
    setStep(BookingStep.RoomConfigurations);
  };

  return (
    <>
      <FullscreenModal
        isOpen={showDetailModal}
        title={i18n.boards[board.type]}
        content={<Headline size={4} title={board.description} as="h2" />}
        onClose={() => toggleDetailModal(false)}
      />
      <LightGallery
        photos={board.photo ? [board.photo] : []}
        isOpen={showBoardPhotos}
        onClose={() => setShowBoardPhotos(false)}
      />
      <Card
        header={
          <BoardHeader
            board={board}
            onOpenLightbox={() => setShowBoardPhotos(true)}
          />
        }
        footer={
          <Button buttonProps={{ onClick: selectBoard }}>
            {i18n.boards.continue}
          </Button>
        }
      >
        <Button
          layout="link"
          buttonProps={{ onClick: selectBoard, className: styles.boardName }}
        >
          {i18n.boards[board.type]}
        </Button>
        <div className={styles.surcharge}>
          <Headline
            title={`+${formatMoney(board.surcharge, language)}`}
            size={1}
            as="div"
          />
          {board.description && (
            <Button
              layout="link"
              buttonProps={{
                className: styles.moreInfosButton,
                onClick: () => toggleDetailModal(true),
              }}
            >
              {i18n.boards.moreInfos}
            </Button>
          )}
        </div>
        {nights && adults && (
          <div>
            {children.length
              ? i18n.boards.nightsAndGuests(nights, adults + children.length)
              : i18n.boards.nightsAndAdults(nights, adults)}
          </div>
        )}
      </Card>
    </>
  );
};

interface BoardHeaderProps {
  board: BoardType;
  onOpenLightbox: () => void;
}

const BoardHeader = ({ board, onOpenLightbox }: BoardHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const BoardIcon = BoardIcons[board.type];

  return (
    <CardImage
      photo={board.photo}
      alt={board.description}
      sizes="(min-width: 1125px) 341px, (min-width: 901px) 33vw, (min-width: 610px) 50vw, 100vw"
      placeHolder={
        BoardIcon && (
          <div className={styles.boardIconWrapper}>
            <BoardIcon className={styles.boardIcon} />
          </div>
        )
      }
      button={{ title: i18n.general.openLightbox, onClick: onOpenLightbox }}
      badge={
        board.surcharge.amount <= 0
          ? { icon: Check, text: i18n.boards.included }
          : false
      }
    />
  );
};

export default Boards;
