import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import clsx from "clsx";
import { useBookingStore } from "../../hooks/useBookingStore";
import { SelectedOfferExtraResponse } from "../../http/offer";
import { translate } from "../../i18n";
import Date from "../../ui/Date";
import Headline from "../../ui/Headline";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import { formatIntlDate, parseDate } from "../../utils/date";
import { formatMoney } from "../../utils/number";
import styles from "./ExtraSummary.module.css";

interface ExtraSummaryProps {
  extra: SelectedOfferExtraResponse;
}

const ExtraSummary = ({ extra }: ExtraSummaryProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const extraAmount = extra.amount;

  return "per_stay" in extraAmount ? (
    <div className={styles.extraPerStay}>
      <div>{`${extraAmount.per_stay}\u00A0× ${extra.title}`}</div>
      <ExtraPrice extra={extra} />
    </div>
  ) : (
    <Disclosure>
      {({ open }) => (
        <div className={styles.disclosure}>
          <DisclosureButton className={styles.disclosureOpener}>
            {`${extra.total_amount}\u00A0× ${extra.title}`}
            <div className={styles.disclosureTitle}>
              <ExtraPrice extra={extra} />
              <ArrowDown
                className={clsx(styles.arrow, { [styles.arrowOpen]: open })}
              />
            </div>
          </DisclosureButton>
          <DisclosurePanel className={styles.disclosureContent}>
            {"per_room" in extraAmount &&
              Object.entries(extraAmount.per_room).map(([room, count]) => (
                <div key={room}>{`${count}\u00A0× ${room}`}</div>
              ))}

            {"per_day" in extraAmount &&
              Object.entries(extraAmount.per_day).map(([date, count]) => (
                <div key={date}>
                  {`${count}\u00A0× `}
                  <Date
                    date={parseDate(date)}
                    formatter={formatIntlDate(language)}
                  />
                </div>
              ))}

            {"per_guest_and_day" in extraAmount &&
              Object.entries(extraAmount.per_guest_and_day).map(
                ([date, guests]) => (
                  <div key={date}>
                    <Date
                      date={parseDate(date)}
                      formatter={formatIntlDate(language)}
                    />
                    <div className={styles.guests}>
                      <div>
                        {i18n.checkout.adultsCountSummary(guests.adults)}
                      </div>
                      {guests.children.length > 0 && (
                        <div>{`${i18n.checkout.childrenCountSummary(guests.children.length)} (${guests.children.map((age) => i18n.checkout.childrenAge(age)).join(", ")})`}</div>
                      )}
                    </div>
                  </div>
                ),
              )}
          </DisclosurePanel>
        </div>
      )}
    </Disclosure>
  );
};

interface ExtraPriceProps {
  extra: SelectedOfferExtraResponse;
}

const ExtraPrice = ({ extra }: ExtraPriceProps) => {
  const language = useBookingStore((state) => state.language);
  const extraIncluded = extra.is_included;

  return (
    <Headline
      size={5}
      title={
        extraIncluded ? (
          <>
            <span className={styles.originalPrice}>
              {formatMoney(extra.total_price, language)}
            </span>
            0 &euro;
          </>
        ) : (
          formatMoney(extra.total_price, language)
        )
      }
      className={styles.price}
    />
  );
};

export default ExtraSummary;
