import { Price as PriceType } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import Price from "../../ui/icon/price.svg?react";
import { formatMoney } from "../../utils/number";
import styles from "./ExtraPriceBadge.module.css";

interface ExtraPriceBadgeProps {
  price: PriceType;
}

const ExtraPriceBadge = ({ price }: ExtraPriceBadgeProps) => {
  const language = useBookingStore((state) => state.language);

  return (
    <div className={styles.priceBadge}>
      <Price className={styles.priceIcon} aria-hidden />
      {formatMoney(price, language)}
    </div>
  );
};

export default ExtraPriceBadge;
