import { useRef } from "react";
import { AriaButtonProps, useButton } from "react-aria";

type CalendarButtonProps = AriaButtonProps & { className?: string };

const CalendarButton = ({
  children,
  className,
  ...rest
}: CalendarButtonProps) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const { buttonProps } = useButton(rest, ref);
  return (
    <button type="button" className={className} {...buttonProps} ref={ref}>
      {children}
    </button>
  );
};

export default CalendarButton;
