import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import styles from "./BookingCancellationModal.module.css";

interface BookingCancellationModalProps {
  text: ReactNode;
  isSubmitting: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const BookingCancellationModal = ({
  text,
  isSubmitting,
  onClose,
  onConfirm,
}: BookingCancellationModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Dialog onClose={onClose} open className={styles.modal}>
      <DialogBackdrop className={styles.backdrop} />
      <DialogPanel className={styles.content}>
        {text}
        <div className={styles.buttons}>
          <Button
            layout="secondary"
            buttonProps={{ onClick: onClose, disabled: isSubmitting }}
          >
            {i18n.booking.cancellation.modal.abort}
          </Button>
          <Button
            loading={isSubmitting}
            layout="primary"
            buttonProps={{ onClick: onConfirm }}
          >
            {i18n.booking.cancellation.modal.cancel}
          </Button>
        </div>
      </DialogPanel>
    </Dialog>
  );
};

export default BookingCancellationModal;
