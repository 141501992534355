import { ReactNode } from "react";
import { ExtraType, RoomTerm } from "../@types";
import { CancellationReason } from "../domain/booking/utils";
import { PrepaymentMethod } from "../http/booking";
import { BoardType } from "../http/ratePlan";
import { getRoomTerm } from "./utils";

const de = {
  start: {
    hgv: "Hoteliers- und Gastwirteverband",
    email: "E-Mail senden",
    phone: "Anrufen",
    legalMenu: {
      privacy: "Datenschutz",
      terms: "AGB",
      imprint: "Impressum",
      imageSources: "Bildquellen",
    },
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Gast" : "Gäste"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${count} ${
        count === 1
          ? getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartment",
              suite: "Suite",
            })
          : getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartments",
              suite: "Suiten",
            })
      }`,
    guestsAndRooms: (term: RoomTerm) =>
      `Gäste & ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })}`,
    travelPeriod: "Reisezeitraum",
    nights: (nights: number) =>
      `(${nights} ${nights === 1 ? "Nacht" : "Nächte"})`,
    arrival: "Anreise?",
    departure: "Abreise?",
    yourBooking: { formal: "Ihre Buchung", informal: "Deine Buchung" },
    guests: "Gäste",
  },
  occupancy: {
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Gast" : "Gäste"}`,
    apply: "Anwenden",
    guestsAndRooms: (term: RoomTerm) =>
      `Gäste & ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    autoOccupancy: (term: RoomTerm) =>
      `Gäste automatisch auf ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })} aufteilen.`,
    autoOccupancyInfo: (term: RoomTerm) =>
      `Verschiedene Belegungs- und ${getRoomTerm({
        term,
        room: "Zimmervorschläge",
        apartment: "Apartment-Vorschläge",
        suite: "Suite-Vorschläge",
      })} für die ausgewählte Gästeanzahl erhalten.`,
    adults: "Erwachsene",
    adultsInfo: "Ab 18 Jahren",
    children: "Kinder",
    childrenInfo: (minAge: number) => `Alter: ${minAge}–17 Jahre`,
  },
  calendar: {
    arrival: "Anreise?",
    departure: "Abreise?",
    continueWithNights: (nights: number) =>
      `Weiter mit ${nights} ${nights === 1 ? "Nacht" : "Nächten"}`,
    arrivalNotAvailable: "Anreise an diesem Tag nicht möglich",
    departureNotAvailable: "Abreise an diesem Tag nicht möglich",
    departureBeforeArrival: "Abreise vor Anreise nicht möglich",
    minStay: (nights: number) =>
      `Mindestaufenthalt ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`,
    maxStay: (nights: number) =>
      `Maximalaufenthalt ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`,
    discountTitle: { formal: "Bleiben Sie länger!", informal: "Bleib länger!" },
    discountText: {
      formal: (percentage: number) =>
        `Sie erhalten ${percentage}\u00A0% Rabatt auf Ihren Gesamtpreis, wenn Sie länger bleiben.`,
      informal: (percentage: number) =>
        `Du erhältst ${percentage}\u00A0% Rabatt auf deinen Gesamtpreis, wenn du länger bleibst.`,
    },
    extendStayBefore: (nights: number) =>
      `${nights} ${nights === 1 ? "Nacht" : "Nächte"} früher anreisen`,
    extendStayAfter: (nights: number) =>
      `${nights} ${nights === 1 ? "Nacht" : "Nächte"} später abreisen`,
    successTitle: "Gute Wahl!",
    successText: "Reisezeitraum erfolgreich angepasst.",
  },
  general: {
    close: "Schließen",
    delete: "Löschen",
    remove: "Entfernen",
    expand: "Öffnen",
    less: "Weniger",
    more: "Mehr",
    share: "Teilen",
    edit: "Bearbeiten",
    openLightbox: "Bildergalerie öffnen",
    readMore: "Mehr lesen",
    back: "Zurück",
    form: {
      errors: {
        required: "Pflichtfeld",
        email: {
          formal: "Geben Sie eine gültige E-Mail-Adresse an.",
          informal: "Bitte gib eine gültige E-Mail-Adresse an.",
        },
      },
    },
    errorPage: {
      title: "Es ist ein unerwarteter Fehler aufgetreten.",
      description:
        "Lorem ipsum dolro sit amend orls it amend orlsi tamend orl osit amend orls it amend orl sit amen dorls ti amen.",
      button: "Nochmals versuchen",
    },
    notFoundPage: {
      title: {
        formal: (
          <>
            Oh nein!
            <br />
            Die von Ihnen gesuchte Seite existiert nicht.
          </>
        ),
        informal: (
          <>
            Oh nein!
            <br />
            Die von dir gesuchte Seite existiert nicht.
          </>
        ),
      },
      description: {
        formal:
          "Leider können wir kein Resultat für Ihre Suchanfrage finden – Ihren Urlaub zu einem besonderen Erlebnis machen können wir sehr wohl.",
        informal:
          "Leider können wir kein Resultat für deine Suchanfrage finden – deinen Urlaub zu einem besonderen Erlebnis machen können wir sehr wohl.",
      },
      button: "Jetzt buchen",
    },
    alerts: {
      requestFailed: {
        title: (requestName: string) => `"${requestName}" fehlgeschlagen`,
        description: {
          formal: "Versuchen Sie es später erneut",
          informal: "Versuche es später erneut",
        },
      },
      share: {
        title: "Teilen nicht möglich",
        description: {
          formal: "Versuchen Sie es später erneut",
          informal: "Versuche es später erneut",
        },
      },
    },
  },
  photoSlider: { showAllImages: "Alle Bilder anzeigen" },
  childAgeSelect: {
    childrenAge: (count: number) => `Alter Kind ${count}`,
    selectAge: "Alter wählen",
  },
  autoOccupancy: {
    apply: "Anwenden",
    adults: "Erwachsene",
    adultsInfo: "Ab 18 Jahren",
    children: "Kinder",
    childrenInfo: (minAge: number) => `Alter: ${minAge}–17 Jahre`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Gast" : "Gäste"}`,
    ourSuggestion: {
      formal: "Unser Vorschlag für Sie",
      informal: "Unser Vorschlag für dich",
    },
    noSuitableSuggestion: "Kein passender Vorschlag dabei?",
    splitGuests: {
      formal: (term: RoomTerm) =>
        `Teilen Sie die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm({
          term,
          room: "Zimmer",
          apartment: "Apartments",
          suite: "Suiten",
        })} auf.`,
      informal: (term: RoomTerm) =>
        `Teile die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm({
          term,
          room: "Zimmer",
          apartment: "Apartments",
          suite: "Suiten",
        })} auf.`,
    },
    assignGuestsIndividually: "Gäste individuell zuweisen",
    option: (index: number) => `Option ${index}`,
    bookOption: (index: number) => `Option ${index} buchen`,
    from: "ab",
    totalPriceNights: (nights: number) =>
      `Gesamtpreis für ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`,
    occupancy: (
      adults: number,
      children: number,
      rooms: number,
      term: RoomTerm,
    ) =>
      `${adults + children} ${children > 0 ? "Gäste" : adults > 1 ? "Erwachsene" : "Erwachsener"} aufgeteilt auf ${rooms} ${
        rooms === 1
          ? getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartment",
              suite: "Suite",
            })
          : getRoomTerm({
              term,
              room: "Zimmer",
              apartment: "Apartments",
              suite: "Suiten",
            })
      }`,
    roomOccupancy: (adults: number, children: number) =>
      `Belegung: ${adults + children} ${children > 0 ? "Gäste" : adults > 1 ? "Erwachsene" : "Erwachsener"}`,
    customOffer: {
      formal: (term: RoomTerm) =>
        `Teilen Sie die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm({
          term,
          room: "Zimmer",
          apartment: "Apartments",
          suite: "Suiten",
        })} auf.`,
      informal: (term: RoomTerm) =>
        `Teile die ausgewählten Gäste auf ein oder mehrere ${getRoomTerm({
          term,
          room: "Zimmer",
          apartment: "Apartments",
          suite: "Suiten",
        })} auf.`,
    },
    adultsCount: (count: number) =>
      `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`,
    childrenCount: (count: number) =>
      `${count} ${count === 1 ? "Kind" : "Kinder"}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    applySort: "Anwenden",
    showAllRooms: (term: RoomTerm) =>
      `Alle ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartments",
        suite: "Suiten",
      })} anzeigen`,
    childrenAge: (age: number) => `Kind ${age} ${age === 1 ? "Jahr" : "Jahre"}`,
    maxOccupancyReached: {
      formal: (
        <>
          Maximalbelegung erreicht.
          <br />
          Fügen Sie unten links ein weiteres Zimmer hinzu oder wählen Sie ein
          anderes Zimmer.
        </>
      ),
      informal: (
        <>
          Maximalbelegung erreicht.
          <br />
          Füge unten links ein weiteres Zimmer hinzu oder wähle ein anderes
          Zimmer.
        </>
      ),
    },
    minOccupancyNotReached: {
      formal:
        "Mindestbelegung nicht erreicht. Bitte fügen Sie eine weitere Person hinzu oder wählen Sie ein anderes Zimmer.",
      informal:
        "Mindestbelegung nicht erreicht. Bitte füge eine weitere Person hinzu oder wähle ein anderes Zimmer.",
    },
    addAdditionalRoom: {
      formal: "Fügen Sie unten links ein weiteres Zimmer hinzu",
      informal: "Füge unten links ein weiteres Zimmer hinzu",
    },
    invalidOccupancy: "Ungültige Zimmerbelegung",
    maxAdultsReached: "Maximale Anzahl an Erwachsenen erreicht",
    maxChildrenReached: "Maximale Anzahl an Kindern erreicht",
    adultsRequired: "Ein Zimmer benötigt mindestens eine erwachsene Person",
    notAllGuestsAssigned: "Nicht alle Gäste zugewiesen",
  },
  roomConfigurations: {
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Wählen Sie ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`,
      informal: (term: RoomTerm) =>
        `Wähle ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`,
    },
    continue: "Weiter",
    room: (term: RoomTerm) =>
      getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      }),
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    adults: (count: number) =>
      `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`,
    children: (count: number) => `${count} ${count === 1 ? "Kind" : "Kinder"}`,
    guests: "Gäste",
    ratePlan: "Rate",
    mealType: "Verpflegung",
  },
  rooms: {
    occupancy: "Belegung",
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Wählen Sie ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`,
      informal: (term: RoomTerm) =>
        `Wähle ${getRoomTerm({
          term,
          room: "das Zimmer",
          apartment: "das Apartment",
          suite: "die Suite",
        })}`,
    },
    continue: "Weiter",
    availableRooms: (availableRooms: number) =>
      `Nur noch ${availableRooms} verfügbar`,
    save: (discount: number) => `${discount}\u00A0% sparen`,
    nights: (nights: number) =>
      `${nights} ${nights === 1 ? "Nacht" : "Nächte"}`,
    nightPerRoom: (term: RoomTerm) =>
      `Nacht pro ${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })}`,
    showPortalPrices: "Preisvergleich mit Buchungsportalen einblenden",
    amenities: (term: RoomTerm) =>
      `Das bietet ${getRoomTerm({
        term,
        room: "das Zimmer",
        apartment: "das Apartment",
        suite: "die Suite",
      })}`,
    showMore: "Mehr anzeigen",
    backToOverview: "Zurück zur Übersicht",
    sort: "Sortieren",
    sortHeadline: {
      formal: (term: RoomTerm) =>
        `Sortieren Sie Ihre ${getRoomTerm({
          term,
          room: "Zimmerliste",
          apartment: "Apartmentliste",
          suite: "Suitenliste",
        })} nach Belieben`,
      informal: (term: RoomTerm) =>
        `Sortiere deine ${getRoomTerm({
          term,
          room: "Zimmerliste",
          apartment: "Apartmentliste",
          suite: "Suitenliste",
        })} nach Belieben`,
    },
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    price: "Preis",
    size: "Größe (m²)",
    ascending: "Aufsteigend",
    ascendingText: "Von klein nach groß",
    descending: "Absteigend",
    descendingText: "Von groß nach klein",
    resetSort: "Alle zurücksetzen",
    applySort: "Anwenden",
    roomOption: (option: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Dieses Zimmer",
        apartment: "Dieses Apartment",
        suite: "Diese Suite",
      })} ist Teil von Option ${option}`,
  },
  ratePlans: {
    selected: "Bereits gewählt",
    continue: "Weiter",
    selectRate: "Rate wählen",
    showMoreDetails: "Mehr Details anzeigen",
    back: "Zurück",
    general: "Allgemein",
    inclusives: "Inklusivleistungen",
    boardsTitle: "Verpflegungsoptionen",
    boardsInfo: "(kann im nächsten Schritt gewählt werden)",
    reservationPolicies: "Reservierungsbedingungen",
    includedInRate: "(in der Rate inklusive)",
    selectAndContinue: "Wählen & weiter",
    showMoreOptions: "Weitere Optionen anzeigen",
    moreDetails: "Mehr Details anzeigen",
  },
  boards: {
    continue: "Wählen & abschließen",
    [BoardType.OvernightStayOnly]: "Nur Übernachtung",
    [BoardType.BedAndBreakfast]: "Übernachtung mit Frühstück",
    [BoardType.HalfBoard]: "Halbpension",
    [BoardType.ThreeQuarterBoard]: "¾-Pension",
    [BoardType.FullBoard]: "Vollpension",
    [BoardType.AllInclusive]: "All Inclusive",
    selectBoard: "Verpflegung wählen",
    included: "Inklusive",
    moreInfos: "Mehr Infos",
    nightsAndAdults: (nights: number, adults: number) =>
      `Für ${nights} ${nights === 1 ? "Übernachtung" : "Übernachtungen"} & ${
        adults
      } ${adults === 1 ? "Erwachsener" : "Erwachsene"}`,
    nightsAndGuests: (nights: number, guests: number) =>
      `Für ${nights} ${nights === 1 ? "Übernachtung" : "Übernachtungen"} & ${
        guests
      } ${guests === 1 ? "Gast" : "Gäste"}`,
    back: "Zurück",
  },
  extras: {
    includedExtras:
      "Diese Extras sind in den gewählten Raten bereits inkludiert.",
    selectAdditionalExtras: "Zusätzliche Extras",
    selectExtras: "Extras wählen",
    goToCheckout: "Zur Zusammenfassung der Buchung",
    select: "Wählen",
    edit: "Bearbeiten",
    [ExtraType.PerDay]: "pro Tag",
    [ExtraType.PerGuestAndDay]: "pro Gast und Tag",
    [ExtraType.PerRoom]: "pro Zimmer",
    [ExtraType.PerStay]: "pro Aufenthalt",
    all: (count: number) => `Alle (${count})`,
    close: "Schließen",
    add: (amount: string) => `Hinzufügen zur Buchung (${amount})`,
    quantity: "Stückzahl",
    room: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${count}`,
    allRooms: "Alle Wohneinheiten",
    adults: "Erwachsene",
    children: "Kinder",
    selected: "Bereits gewählt",
    allAdults: "Alle Tage, alle Erwachsene",
    allChildren: "Alle Tage, alle Kinder",
  },
  checkout: {
    summaryHeadline: {
      formal: "Zusammenfassung Ihrer Buchung",
      informal: "Zusammenfassung deiner Buchung",
    },
    formHeadline: "Buchung abschließen",
    travelPeriod: "Reisezeitraum",
    arrival: "Anreise",
    departure: "Abreise",
    dateNotSelected: "noch nicht gewählt",
    guests: "Gäste",
    ratePlan: "Rate",
    boardType: "Verpflegung",
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`,
    childrenCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Kind" : "Kinder"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Zimmer",
        apartment: "Apartment",
        suite: "Suite",
      })} ${count}`,
    extras: "Extras",
    total: "Gesamt",
    continueToForm: {
      formal: "Weiter zu Ihren Daten",
      informal: "Weiter zu deinen Daten",
    },
    priceInfo: "Einschließlich Steuern und Gebühren",
    yourData: { formal: "Ihre Daten", informal: "Deine Daten" },
    showPriceDetails: "Details anzeigen",
    gender: { gender: "Anrede", male: "Herr", female: "Frau" },
    firstName: "Vorname",
    lastName: "Nachname",
    email: "E-Mail-Adresse",
    phone: "Telefonnummer",
    street: "Straße",
    postalCode: "PLZ",
    municipality: "Stadt",
    country: "Land",
    company: {
      isBusinessTrip: {
        formal: "Reisen Sie geschäftlich?",
        informal: "Reist du geschäftlich?",
      },
      yes: "Ja, ich bin geschäftlich unterwegs",
      no: "Nein, ich bin privat unterwegs",
      name: "Name des Unternehmens",
      vatNumber: "Mehrwertsteuernummer",
      recipientCode: "Empfängerkodex",
      street: "Straße",
      postalCode: "PLZ",
      municipality: "Stadt",
      country: "Land",
    },
    voucher: {
      formal: "Haben Sie einen Gutscheincode?",
      informal: "Hast du einen Gutscheincode?",
    },
    insertVoucher: "Gutscheincode eingeben",
    applyVoucher: "Einlösen",
    voucherAlreadyRedeemed: "Gutschein wurde bereits vollständig eingelöst.",
    voucherCancelled: "Gutschein wurde bereits storniert.",
    voucherExpired: "Gutschein ist verfallen.",
    voucherInvalidCode: "Gutschein wurde nicht gefunden.",
    voucherRedeemable: (voucherCode: string) =>
      `Gutschein „${voucherCode}“ erfolgreich eingelöst. Der Gutscheinbetrag ist jetzt im Warenkorb sichtbar.`,
    note: "Besondere Anfragen",
    noteDescription: {
      formal:
        "Besondere Anfragen können nicht garantiert werden. Wir werden uns aber bemühen, Ihren Wünschen bestmöglich zu entsprechen.",
      informal:
        "Besondere Anfragen können nicht garantiert werden. Wir werden uns aber bemühen, deinen Wünschen bestmöglich zu entsprechen.",
    },
    noteLabel: "Bemerkung hinterlassen",
    privacy: "Datenschutzbestimmungen",
    terms: "AGB",
    book: "Ja, zahlungspflichtig buchen",
    paymentInfo: "Zahlungsinformationen",
    paymentAmount: (amount: string, percentage: number) =>
      `${amount} – Angeld (${percentage}\u00A0% des Gesamtbetrages)`,
    paymentMethod: {
      [PrepaymentMethod.Card]: "Kreditkarte",
      [PrepaymentMethod.BankTransfer]: "Banküberweisung",
    },
    paymentType: "Zahlungsart",
    insurance: "Sicher schlafen",
    insuranceLabel: (insuranceName: string, amount: string) => (
      <>
        Ja, ich möchte die {insuranceName} für <strong>{amount}</strong> (inkl.
        Steuern) dazubuchen.
      </>
    ),
    showMoreInsuranceDetails: "Mehr Details anzeigen",
    reservationPolicies: "Reservierungsbedingungen",
    bookingSummary: {
      formal:
        "Aktuell befindet sich in Ihrem Warenkorb eine Reise im Wert von:",
      informal:
        "Aktuell befindet sich in deinem Warenkorb eine Reise im Wert von:",
    },
    acceptPrivacyPolicyAndTermsLabel: (
      privacy: ReactNode,
      terms: ReactNode,
    ) => (
      <>
        Ja, ich akzeptiere die {privacy} und die {terms}.
      </>
    ),
    yourBooking: { formal: "Ihre Buchung", informal: "Deine Buchung" },
    extendStayHeadline: {
      formal: "Haben Sie Lust, länger zu bleiben?",
      informal: "Hast du Lust, länger zu bleiben?",
    },
    extendStayText: {
      formal: (percentage: number) =>
        `Sie erhalten ${percentage}\u00A0% Rabatt auf Ihren Gesamtpreis, wenn Sie länger bleiben.`,
      informal: (percentage: number) =>
        `Du erhältst ${percentage}\u00A0% Rabatt auf deinen Gesamtpreis, wenn du länger bleibst.`,
    },
    extendStayBefore: (nights: number) =>
      `${nights} ${nights === 1 ? "Nacht" : "Nächte"} vorher hinzufügen`,
    extendStayAfter: (nights: number) =>
      `${nights} ${nights === 1 ? "Nacht" : "Nächte"} nachher hinzufügen`,
    forNights: (nights: number) =>
      `Für ${nights} ${nights === 1 ? "Nacht" : "Nächte"}`,
    basePrice: "Buchungsbetrag",
    touristTax: "Ortstaxe (vor Ort zu entrichten)",
    totalPrice: "Gesamtbetrag",
    companyInvoiceData: {
      formal: "Ihre Rechnungsdaten",
      informal: "Deine Rechnungsdaten",
    },
    noExtrasSelected: "Keine Zusatzleistungen gewählt",
    extraSuggestions: {
      formal: "Möchten Sie noch Extras dazubuchen?",
      informal: "Möchtest du noch Extras dazubuchen?",
    },
    addExtra: (amount: string) => `Hinzufügen (${amount})`,
    childrenAge: (age: number) => `${age} ${age === 1 ? "Jahr" : "Jahre"}`,
  },
  booking: {
    success: {
      headline: "Buchung erfolgreich!",
      text: {
        formal: (email: string) =>
          `Danke, Ihre Buchung wurde bestätigt. Eine Bestätigungsmail wurde an ${email} versendet.`,
        informal: (email: string) =>
          `Danke, deine Buchung wurde bestätigt. Eine Bestätigungsmail wurde an ${email} versendet.`,
      },
      insuranceHeadline: {
        formal: "Gehen Sie auf Nummer sicher!",
        informal: "Gehe auf Nummer sicher!",
      },
      insuranceText: {
        formal:
          "Der HGV-Stornoschutz bietet Schutz, wenn Sie Ihre Reise unerwartet nicht antreten können oder vorzeitig abbrechen müssen.",
        informal:
          "Der HGV-Stornoschutz bietet Schutz, wenn du deine Reise unerwartet nicht antreten kannst oder vorzeitig abbrechen musst.",
      },
      insuranceButtonText: "Jetzt Reiseversicherung abschließen",
    },
    cancellation: {
      headline: (bookingCode: string) =>
        `Buchungsübersicht für die Buchungsnummer ${bookingCode}`,
      personalData: "Persönliche Daten",
      propertyData: "Daten der Unterkunft",
      bookingDetails: "Buchungsdetails",
      extras: "Zusatzleistungen",
      priceOverview: "Preisübersicht",
      paymentOptions: "Zahlungsoption",
      cancellationPolicies: "Stornobedingungen",
      cancelBooking: "Buchung stornieren",
      cancel: "Stornieren",
      footerHeadline: {
        formal: "Wir freuen uns auf Sie!",
        informal: "Wir freuen uns auf dich!",
      },
      firstName: "Vorname",
      lastName: "Nachname",
      municipality: "Ort",
      street: "Straße",
      phone: "Telefon",
      email: "E-Mail",
      property: "Unterkunft",
      website: "Webseite",
      arrivalDeparture: "Anreise – Abreise",
      nights: "Nächte",
      checkIn: "Check-In",
      checkOut: "Check-Out",
      companyData: "Unternehmensdaten",
      companyName: "Unternehmen",
      vatNumber: "MwSt.-Nummer",
      recipientCode: "Empfängerkodex",
      board: "Verpflegung",
      guests: "Gäste",
      roomExtras: (term: RoomTerm) =>
        `Zusatzleistungen ${getRoomTerm({
          term,
          room: "fürs Zimmer",
          apartment: "fürs Apartment",
          suite: "für die Suite",
        })}`,
      totalWithoutVat: "Buchungsbetrag",
      touristTax: "Ortstaxe (vor Ort zu entrichten)",
      totalPrice: "Gesamtbetrag",
      adultsCount: (count: number) =>
        `${count} ${count === 1 ? "Erwachsener" : "Erwachsene"}`,
      childrenCount: (count: number) =>
        `${count} ${count === 1 ? "Kind" : "Kinder"}`,
      cancellationPoliciesText: {
        formal: (
          <>
            Es steht kein Rücktrittsrecht im Sinne des Konsumentenschutzkodexes
            zu. Dennoch räumen wir Ihnen folgende Stornobedinungen ein:
          </>
        ),
        informal: (
          <>
            Es steht kein Rücktrittsrecht im Sinne des Konsumentenschutzkodexes
            zu. Dennoch räumen wir dir folgende Stornobedinungen ein:
          </>
        ),
      },
      prepaymentMethod: {
        [PrepaymentMethod.Card]: "Angeld mittels Kredikarte (Visa/Mastercard)",
        [PrepaymentMethod.BankTransfer]: "Angeld mittels Banküberweisung",
      },
      touristTaxHeadline: "* Ortstaxe",
      touristTaxText: (
        <>
          Pro Person (ab 14 Jahren) und Übernachtung ist eine Ortstaxe zu
          entrichten. Diese ist nicht im Buchungsbetrag enthalten und wird
          direkt vor Ort in der Unterkunft bezahlt.
        </>
      ),
      reason: {
        [CancellationReason.GuestUnableToStay]:
          "Ich bin zu dem Zeitpunkt verhindert.",
        [CancellationReason.PropertyAskedToCancel]:
          "Die Unterkunft hat mich gebeten, zu stornieren.",
        [CancellationReason.GuestChoseOtherDestination]:
          "Ich habe mich für ein anderes Reiseziel entschieden.",
        [CancellationReason.GuestChoseOtherProperty]:
          "Ich habe mich für eine andere Unterkunft entschieden.",
        [CancellationReason.Other]: "Andere Gründe:",
      },
      note: "Grund angeben",
      success: "Die Buchung wurde erfolgreich storniert.",
      error: {
        formal:
          "Beim Übermitteln der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
        informal:
          "Beim Übermitteln der Daten ist ein Fehler aufgetreten. Bitte versuche es später erneut.",
      },
      alreadyCancelled: "Die ausgewählte Buchung wurde bereits storniert.",
      modal: {
        abort: "Abbrechen",
        cancel: "Stornieren",
        text: {
          formal: (daysToArrival: number, fee: string) => (
            <>
              <strong>Achtung:</strong> Wenn Sie Ihre Buchung jetzt (
              {daysToArrival} Tage bis Anreise) stornieren, fallen
              Stornogebühren in Höhe von <strong>{fee}</strong> an.
            </>
          ),
          informal: (daysToArrival: number, fee: string) => (
            <>
              <strong>Achtung:</strong> Wenn du deine Buchung jetzt (
              {daysToArrival} Tage bis Anreise) stornierst, fallen
              Stornogebühren in Höhe von <strong>{fee}</strong> an.
            </>
          ),
        },
      },
    },
  },
};

export default de;
