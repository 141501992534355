import { Input as HeadlessInput } from "@headlessui/react";
import { FocusEventHandler, Ref } from "react";
import { FieldError } from "react-hook-form";
import FormField from "./FormField";

interface InputProps {
  label: string;
  required?: boolean;
  isEmpty?: boolean;
  autoComplete?: string;
  className?: string;
  error?: FieldError | undefined;
  type?: "text" | "email" | "tel";
  onBlur?: FocusEventHandler<HTMLInputElement>;
  ref: Ref<HTMLInputElement>;
}

const Input = ({
  label,
  required,
  isEmpty,
  autoComplete,
  className,
  error,
  type = "text",
  onBlur,
  ref,
  ...rest
}: InputProps) => (
  <FormField
    label={label}
    required={required}
    isEmpty={isEmpty}
    className={className}
    error={error}
  >
    {({ inputClassName, errorId, setFocused, onAnimationStart }) => (
      <HeadlessInput
        type={type}
        {...rest}
        ref={ref}
        autoComplete={autoComplete}
        className={inputClassName}
        aria-invalid={!!error}
        aria-required={required}
        aria-errormessage={error ? errorId : undefined}
        onFocus={() => setFocused(true)}
        onBlur={(...args) => {
          setFocused(false);
          onBlur?.(...args);
        }}
        /* required to trigger animation on autofill preview which then can be detected via javascript */
        onAnimationStart={onAnimationStart}
      />
    )}
  </FormField>
);

export default Input;
