import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useCallback, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { HeaderButtonCategory, Occupancy } from "../../@types";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useRoomTypes } from "../../http/room";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import Button from "../../ui/Button";
import { MobileModalContent, MobileModalHeader } from "../../ui/MobileModal";
import NumberInput from "../../ui/NumberInput";
import Switch from "../../ui/Switch";
import Close from "../../ui/icon/close.svg?react";
import Info from "../../ui/icon/info.svg?react";
import {
  maxAdultsAutoOccupancy,
  maxChildrenAutoOccupancy,
} from "../../utils/constants";
import { site } from "../../utils/site";
import OccupancyChildren from "./OccupancyChildren";
import { OccupancyFooter } from "./OccupancyFooter";
import styles from "./OccupancyForm.module.css";
import OccupancyRooms from "./OccupancyRooms";
import { getFormDataOccupancy, useOccupancyFormContext } from "./utils";

const OccupancyForm = () => {
  const language = useBookingStore((state) => state.language);
  const storeOccupancies = useBookingStore((state) => state.occupancies);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const [openedRoom, setOpenedRoom] = useState(storeOccupancies.length - 1);
  const i18n = translate(language);
  const { roomTypesMap, isLoading: isRoomTypesLoading } = useRoomTypes({
    skipFetch: !roomTypeId,
  });

  const roomType = roomTypesMap?.[roomTypeId ?? ""];

  const setActiveHeaderButton = useBookingStore(
    (state) => state.setActiveHeaderButton,
  );

  const { control, watch, setValue, getValues } = useOccupancyFormContext();

  const {
    fields: occupancyFields,
    append,
    remove,
  } = useFieldArray({ name: "occupancies", control });

  const useAutoOccupancy = watch("useAutoOccupancy");

  const toggleUseAutoOccupancy = useCallback(
    (value: boolean) => {
      const useAutoOccupancy = !value;
      if (useAutoOccupancy) {
        setValue("occupancies", [
          getValues("occupancies").reduce(
            (summary, occupancy) => {
              summary.adults += occupancy.adults;
              summary.children = [...summary.children, ...occupancy.children];
              return summary;
            },
            { adults: 0, children: [] },
          ),
        ]);
      } else {
        setValue(
          "occupancies",
          storeOccupancies.map((occupancy: Occupancy) =>
            getFormDataOccupancy(occupancy, site),
          ),
        );
      }
      setOpenedRoom(0);
      setValue("useAutoOccupancy", useAutoOccupancy);
    },
    [getValues, setValue, storeOccupancies],
  );

  const autoOccupancyAvailable = !roomTypeId && !ratePlanId;

  return (
    <>
      <MobileModalHeader
        title={i18n.occupancy.guestsAndRooms(site.room_term)}
        onClose={() => setActiveHeaderButton(HeaderButtonCategory.None)}
      />
      <MobileModalContent className={styles.modalContent}>
        {autoOccupancyAvailable && (
          <Controller
            name="useAutoOccupancy"
            control={control}
            render={({ field: { value } }) => (
              <div className={styles.switchContainer}>
                <Popover className={styles.popover}>
                  <PopoverButton className={styles.popoverButton}>
                    <Info className={styles.icon} />
                  </PopoverButton>
                  <PopoverPanel
                    anchor={{ to: "bottom start", gap: "4px" }}
                    className={styles.popoverPanel}
                  >
                    {({ close }) => (
                      <div className={styles.popoverPanelContent}>
                        {i18n.occupancy.autoOccupancyInfo(site.room_term)}
                        <Button
                          layout="link"
                          buttonProps={{
                            onClick: () => close(),
                            "aria-label": i18n.general.close,
                            className: styles.closeButton,
                          }}
                        >
                          <Close className={styles.closeIcon} />
                        </Button>
                      </div>
                    )}
                  </PopoverPanel>
                </Popover>
                <div className={styles.switchContent}>
                  {i18n.occupancy.autoOccupancy(site.room_term)}
                </div>
                <Switch
                  checked={value}
                  onChange={() => toggleUseAutoOccupancy(value)}
                />
              </div>
            )}
          />
        )}
        {useAutoOccupancy ? (
          <div className={styles.autoOccupancy}>
            <Controller
              name="occupancies.0.adults"
              control={control}
              rules={{ min: 1, max: maxAdultsAutoOccupancy }}
              render={({ field: { value } }) => (
                <NumberInput
                  title={i18n.occupancy.adults}
                  value={value}
                  min={1}
                  max={maxAdultsAutoOccupancy}
                  description={i18n.occupancy.adultsInfo}
                  onChange={(adults) =>
                    setValue(`occupancies.0.adults`, adults)
                  }
                />
              )}
            />
            {site.children_allowed && (
              <OccupancyChildren
                occupancyIndex={0}
                minChildrenCount={0}
                maxChildrenCount={maxChildrenAutoOccupancy}
                childrenMinAge={site.children_min_age}
              />
            )}
          </div>
        ) : (
          <OccupancyRooms
            fields={occupancyFields}
            openedRoom={openedRoom}
            onOpen={(index) => setOpenedRoom(openedRoom === index ? -1 : index)}
            onDelete={(index) => {
              remove(index);
              setOpenedRoom((prev) => (prev >= index ? prev - 1 : prev));
            }}
          />
        )}
      </MobileModalContent>
      <ActionFooter>
        <OccupancyFooter
          roomCount={occupancyFields.length}
          disabled={isRoomTypesLoading}
          onAddRoom={() => {
            append({
              adults: roomType
                ? Math.min(
                    Math.max(roomType.occupancy.min, 2),
                    roomType.occupancy.max,
                  )
                : 2,
              children: [],
            });
            setOpenedRoom(occupancyFields.length);
          }}
        />
      </ActionFooter>
    </>
  );
};

export default OccupancyForm;
