import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import styles from "./BackButton.module.css";
import Button from "./Button";
import { CenteredIcon } from "./CenteredIcon";
import ArrowLeft from "./icon/arrow-left.svg?react";
import Close from "./icon/close.svg?react";

interface BackButtonProps {
  onClick: () => void;
  label?: string;
}

export const BackButton = ({ onClick, label }: BackButtonProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Button layout="link" buttonProps={{ onClick, className: styles.button }}>
      <span className={styles.text}>
        <ArrowLeft aria-hidden className={styles.arrow} />
        {label ?? i18n.general.back}
      </span>
      <span className={styles.iconWrapper}>
        <CenteredIcon icon={Close} />
      </span>
    </Button>
  );
};
