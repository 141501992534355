import { differenceInDays } from "date-fns";
import { useState } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useDiscounts } from "../../http/availability";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import Headline from "../../ui/Headline";
import Calendar from "../../ui/icon/calendar.svg?react";
import Close from "../../ui/icon/close.svg?react";
import Success from "../../ui/icon/success.svg?react";
import { parseDateNullable } from "../../utils/date";
import { site } from "../../utils/site";
import styles from "./CalendarFooter.module.css";
import { DateRange, useCalendarFormContext } from "./utils";

interface CalendarFooterProps {
  arrival: Date;
  departure: Date;
  stayExtended: boolean;
  success: boolean;
  onSelect: (range: DateRange) => void;
}

const CalendarFooter = ({
  arrival,
  departure,
  stayExtended,
  success,
  onSelect,
}: CalendarFooterProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { formState } = useCalendarFormContext();
  const { stays } = useDiscounts(arrival, departure);
  const [hideDiscount, setHideDiscount] = useState(false);

  const title = i18n.calendar.continueWithNights(
    differenceInDays(departure, arrival),
  );

  const {
    stay,
    suggestedArrival,
    suggestedDeparture,
    extendArrivalNights,
    extendDepartureNights,
  } = stays
    ?.map((stay) => {
      const suggestedArrival = parseDateNullable(stay.arrival);
      const suggestedDeparture = parseDateNullable(stay.departure);
      const extendArrivalNights =
        suggestedArrival === null
          ? 0
          : differenceInDays(arrival, suggestedArrival);
      const extendDepartureNights =
        suggestedDeparture === null
          ? 0
          : differenceInDays(suggestedDeparture, departure);

      return {
        stay,
        suggestedArrival,
        suggestedDeparture,
        extendArrivalNights,
        extendDepartureNights,
      };
    })
    .find((x) => x.extendArrivalNights + x.extendDepartureNights > 0) ?? {
    suggestedArrival: null,
    suggestedDeparture: null,
    extendArrivalNights: 0,
    extendDepartureNights: 0,
  };

  const extendArrivalDeparture = (
    arrival: Date | null,
    departure: Date | null,
  ) => {
    onSelect({ start: arrival, end: departure });
  };

  return (
    <>
      {!stayExtended && !hideDiscount && stay && (
        <div className={styles.discount}>
          <Calendar className={styles.icon} aria-hidden />
          <Button
            layout="link"
            buttonProps={{
              onClick: () => setHideDiscount(true),
              "aria-label": i18n.general.close,
              className: styles.close,
            }}
          >
            <Close />
          </Button>
          <div className={styles.content}>
            <Headline
              size={2}
              title={i18n.calendar.discountTitle[site.guest_interaction]}
            />
            <Headline
              size={4}
              title={i18n.calendar.discountText[site.guest_interaction](
                stay.discount,
              )}
            />
            <div className={styles.buttons}>
              {extendArrivalNights > 0 && (
                <Button
                  layout="link"
                  buttonProps={{
                    onClick: () =>
                      extendArrivalDeparture(suggestedArrival, departure),
                    className: styles.button,
                  }}
                >
                  {i18n.calendar.extendStayBefore(extendArrivalNights)}
                </Button>
              )}
              {extendDepartureNights > 0 && (
                <Button
                  layout="link"
                  buttonProps={{
                    onClick: () =>
                      extendArrivalDeparture(arrival, suggestedDeparture),
                    className: styles.button,
                  }}
                >
                  {i18n.calendar.extendStayAfter(extendDepartureNights)}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
      {success && !hideDiscount && (
        <div className={styles.success}>
          <Success className={styles.successIcon} />
          <Button
            layout="link"
            buttonProps={{
              onClick: () => setHideDiscount(true),
              "aria-label": i18n.general.close,
              className: styles.close,
            }}
          >
            <Close />
          </Button>
          <div>
            <Headline size={3} title={i18n.calendar.successTitle} />
            <Headline
              size={4}
              title={i18n.calendar.successText}
              className={styles.successText}
            />
          </div>
        </div>
      )}
      <div className={styles.submitButton}>
        <Button
          buttonProps={{ type: "submit", title, disabled: !formState.isValid }}
          layout="primary"
        >
          {title}
        </Button>
      </div>
    </>
  );
};

export default CalendarFooter;
