import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Room } from "../../http/room";
import { translate } from "../../i18n";
import { BackButton } from "../../ui/BackButton";
import DetailModal from "../../ui/DetailModal";
import RoomDetail from "./RoomDetail";
import styles from "./RoomDetailModal.module.css";

interface RoomDetailModalProps {
  room: Room | null | undefined;
  open: boolean;
  onClose: () => void;
  prices?: ReactNode;
  footer?: ReactNode | (() => ReactNode);
  availableRooms?: number | null;
}

const RoomDetailModal = ({
  room,
  open,
  onClose,
  prices,
  footer,
  availableRooms,
}: RoomDetailModalProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  if (!room) {
    return null;
  }

  return (
    <DetailModal
      open={open}
      onClose={onClose}
      title={
        <>
          {!!availableRooms && (
            <div className={styles.availability}>
              {i18n.rooms.availableRooms(availableRooms)}
            </div>
          )}
          <BackButton onClick={onClose} label={i18n.rooms.backToOverview} />
        </>
      }
    >
      <RoomDetail
        room={room}
        prices={prices}
        footer={typeof footer === "function" ? footer() : footer}
      />
      <div className={styles.spaceBottom} />
    </DetailModal>
  );
};

export default RoomDetailModal;
