import { useFormContext } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import { Extra } from "../../http/extra";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Form from "../../ui/Form";
import Headline from "../../ui/Headline";
import { formatMoney } from "../../utils/number";
import ExtraConfiguration from "./ExtraConfiguration";
import styles from "./ExtraConfigurationForm.module.css";
import { ExtraFormType, getDefaultValues, getQuantityCount } from "./utils";

interface ExtraConfigurationFormProps {
  extra: Extra;
  onSubmit: () => void;
}

const ExtraConfigurationForm = ({
  extra,
  onSubmit,
}: ExtraConfigurationFormProps) => {
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const extraConfigurations = useBookingStore(
    (state) => state.extraConfigurations,
  );
  const insertOrUpdateExtra = useBookingStore(
    (state) => state.insertOrUpdateExtra,
  );
  const removeExtra = useBookingStore((state) => state.removeExtra);
  const updateCheckoutData = useBookingStore(
    (state) => state.updateCheckoutData,
  );

  if (!arrival || !departure) {
    return null;
  }

  return (
    <Form<ExtraFormType>
      className={styles.form}
      defaultValues={getDefaultValues(
        extra,
        extraConfigurations,
        arrival,
        departure,
        roomConfigurations,
      )}
      onSubmit={(data) => {
        const count = getQuantityCount(data.quantities);
        if (count > 0) {
          insertOrUpdateExtra(extra.id, {
            extraId: extra.id,
            type: extra.type,
            quantities: Object.fromEntries(
              data.quantities.map(({ key, ...values }) => {
                const { children, ...rest } = values;
                return [
                  key,
                  { ...rest, children: children?.map((x) => x.age) },
                ];
              }),
            ),
          });
        } else {
          removeExtra(extra.id);
        }
        updateCheckoutData();
        onSubmit();
      }}
    >
      <div className={styles.content}>
        <Headline size={4} title={extra.description} as="h2" />
        <ExtraConfiguration extra={extra} />
      </div>
      <ExtraConfigurationFooter extra={extra} />
    </Form>
  );
};

interface ExtraConfigurationFooterProps {
  extra: Extra;
}

const ExtraConfigurationFooter = ({ extra }: ExtraConfigurationFooterProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { formState, watch } = useFormContext<ExtraFormType>();

  const quantities = watch("quantities");

  const count = getQuantityCount(quantities);
  const amount = count * extra.price_per_unit.amount;
  const title =
    count > 0
      ? i18n.extras.add(
          formatMoney(
            { amount, currency: extra.price_per_unit.currency },
            language,
          ),
        )
      : i18n.extras.close;

  return (
    <div className={styles.footer}>
      <CenterWrapper>
        <Button
          buttonProps={{
            type: "submit",
            title,
            disabled: !formState.isValid,
            className: styles.button,
          }}
        >
          {title}
        </Button>
      </CenterWrapper>
    </div>
  );
};

export default ExtraConfigurationForm;
