import { ElementType } from "react";

import styles from "./CenteredIcon.module.css";

interface BackButtonProps {
  icon: ElementType;
}

export const CenteredIcon = ({ icon: Icon, ...rest }: BackButtonProps) => (
  <Icon {...rest} className={styles.icon} />
);
