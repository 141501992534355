import { create } from "zustand";
import { DeepPartial } from "../@types";
import { CheckoutFormType, GuestType } from "../domain/checkout/utils";
import { Gender } from "../http/booking";

interface CheckoutFormStoreActions {
  updateData: (data: DeepPartial<CheckoutFormType>) => void;
  reset: () => void;
}

interface CheckoutFormStore {
  data: CheckoutFormType;
  actions: CheckoutFormStoreActions;
}

const defaultState: CheckoutFormType = {
  guest: {
    gender: Gender.None,
    first_name: "",
    last_name: "",
    email_address: "",
    phone_number: "",
    note: "",
    street: "",
    postal_code: "",
    municipality: "",
    country: "",
  },
  company: {
    name: "",
    vat_number: "",
    recipient_code: "",
    street: "",
    postal_code: "",
    municipality: "",
    country: "",
  },
  general: {
    guestType: GuestType.Private,
    insurance: false,
    prepayment_method: "",
    privacyPolicy: false,
  },
};

export const useCheckoutFormStore = create<CheckoutFormStore>()((set) => ({
  data: defaultState,
  actions: {
    updateData: (data: DeepPartial<CheckoutFormType>) =>
      set((state) => ({
        data: {
          guest: { ...state.data.guest, ...data.guest },
          company: { ...state.data.company, ...data.company },
          general: { ...state.data.general, ...data.general },
        },
      })),
    reset: () => set(() => ({ data: defaultState })),
  },
}));
