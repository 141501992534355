import { ReactNode } from "react";
import { ExtraType, I18n, RoomTerm } from "../@types";
import { CancellationReason } from "../domain/booking/utils";
import { PrepaymentMethod } from "../http/booking";
import { BoardType } from "../http/ratePlan";
import { getRoomTerm } from "./utils";

const it: I18n = {
  start: {
    hgv: "Unione Albergatori e Pubblici Esercenti",
    email: "Inviare email",
    phone: "Chiamare",
    legalMenu: {
      privacy: "Privacy",
      terms: "Termini e condizioni",
      imprint: "Impressum",
      imageSources: "Fonti immagini",
    },
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "adulto" : "adulti"}`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "ospite" : "ospiti"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${count} ${
        count === 1
          ? getRoomTerm({
              term,
              room: "camera",
              apartment: "appartamento",
              suite: "suite",
            })
          : getRoomTerm({
              term,
              room: "camere",
              apartment: "appartamenti",
              suite: "suite",
            })
      }`,
    guestsAndRooms: (term: RoomTerm) =>
      `Ospiti & ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })}`,
    travelPeriod: "Periodo di viaggio",
    nights: (nights: number) =>
      `(${nights} ${nights === 1 ? "notte" : "notti"})`,
    arrival: "Arrivo?",
    departure: "Partenza?",
    yourBooking: {
      formal: "La Sua prenotazione",
      informal: "La tua prenotazione",
    },
    guests: "Ospiti",
  },
  occupancy: {
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "ospite" : "ospiti"}`,
    apply: "Applica",
    guestsAndRooms: (term: RoomTerm) =>
      `Ospiti & ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      })} ${index}`,
    autoOccupancy: (term: RoomTerm) =>
      `Suddividere gli ospiti automaticamente ${getRoomTerm({
        term,
        room: "sulle camere",
        apartment: "sugli appartamenti",
        suite: "sulle suite",
      })}`,
    autoOccupancyInfo: (term: RoomTerm) =>
      `Ottenere diverse proposte di occupazione e ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })} per il numero selezionato di ospiti.`,
    adults: "Adulti",
    adultsInfo: "A partire da 18 anni",
    children: "Bambini",
    childrenInfo: (minAge: number) => `Età: ${minAge}–17 anni`,
  },
  calendar: {
    arrival: "Arrivo?",
    departure: "Partenza?",
    continueWithNights: (nights: number) =>
      `Continua con ${nights} ${nights === 1 ? "notte" : "notti"}`,
    arrivalNotAvailable: "Arrivo in questo giorno non possibile",
    departureNotAvailable: "Partenza in questo giorno non possibile",
    departureBeforeArrival: "Partenza prima dell’arrivo non possibile",
    minStay: (nights: number) =>
      `Soggiorno minimo: ${nights} ${nights === 1 ? "notte" : "notti"}`,
    maxStay: (nights: number) =>
      `Soggiorno massimo: ${nights} ${nights === 1 ? "notte" : "notti"}`,
    discountTitle: {
      formal: "Rimanga più a lungo!",
      informal: "Rimani più a lungo!",
    },
    discountText: {
      formal: (percentage: number) =>
        `Riceverà uno sconto del ${percentage}% sul prezzo totale se rimarrà più a lungo.`,
      informal: (percentage: number) =>
        `Riceverai uno sconto del ${percentage}% sul prezzo totale se rimani più a lungo.`,
    },
    extendStayBefore: (nights: number) =>
      `Arrivare ${nights} ${nights === 1 ? "notte" : "notti"} prima`,
    extendStayAfter: (nights: number) =>
      `Partire ${nights} ${nights === 1 ? "notte" : "notti"} più tardi`,
    successTitle: "Ottima scelta!",
    successText: "Periodo di viaggio modificato con successo.",
  },
  general: {
    close: "Chiudere",
    delete: "Cancellare",
    remove: "Rimuovere",
    expand: "Aprire",
    less: "Meno",
    more: "Di più",
    share: "Condividere",
    edit: "Modificare",
    openLightbox: "Aprire galleria immagini",
    readMore: "Leggere di più",
    back: "Indietro",
    form: {
      errors: {
        required: "Campo obbligatorio",
        email: {
          formal: "Inserisca un indirizzo email valido.",
          informal: "Inserisci un indirizzo email valido.",
        },
      },
    },
    errorPage: {
      title: "Si è verificato un errore inaspettato.",
      description:
        "Lorem ipsum dolro sit amend orls it amend orlsi tamend orl osit amend orls it amend orl sit amen dorls ti amen.",
      button: "Riprovare",
    },
    notFoundPage: {
      title: {
        formal: (
          <>
            Oh no!
            <br />
            Il sito che cerca non esiste.
          </>
        ),
        informal: (
          <>
            Oh no!
            <br />
            Il sito che cerchi non esiste.
          </>
        ),
      },
      description: {
        formal:
          "Purtroppo non riusciamo a trovare un risultato per la Sua ricerca – ma possiamo sicuramente rendere la Sua vacanza un’esperienza speciale.",
        informal:
          "Purtroppo non riusciamo a trovare un risultato per la tua ricerca – ma possiamo sicuramente rendere la tua vacanza un’esperienza speciale.",
      },
      button: "Prenotare adesso",
    },
    alerts: {
      requestFailed: {
        title: (requestName: string) => `"${requestName}" non riuscito`,
        description: {
          formal: "Riprovi più tardi",
          informal: "Riprova più tardi",
        },
      },
      share: {
        title: "Impossibile condividere",
        description: {
          formal: "Riprovi più tardi",
          informal: "Riprova più tardi",
        },
      },
    },
  },
  photoSlider: { showAllImages: "Mostrare tutte le immagini" },
  childAgeSelect: {
    childrenAge: (count: number) => `Età bambino ${count}`,
    selectAge: "Scegliere età",
  },
  autoOccupancy: {
    apply: "Applicare",
    adults: "Adulti",
    adultsInfo: "A partire da 18 anni",
    children: "Bambini",
    childrenInfo: (minAge: number) => `Età: ${minAge}–17 anni`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "ospite" : "ospiti"}`,
    ourSuggestion: {
      formal: "La nostra proposta per Lei",
      informal: "La nostra proposta per te",
    },
    noSuitableSuggestion: "Nessuna proposta ideale disponibile?",
    splitGuests: {
      formal: (term: RoomTerm) =>
        `Suddivida gli ospiti selezionati su una o più ${getRoomTerm({
          term,
          room: "camere",
          apartment: "appartamenti",
          suite: "suite",
        })}.`,
      informal: (term: RoomTerm) =>
        `Suddividi gli ospiti selezionati su una o più ${getRoomTerm({
          term,
          room: "camere",
          apartment: "appartamenti",
          suite: "suite",
        })}.`,
    },
    assignGuestsIndividually: "Assegnare gli ospiti individualmente",
    option: (index: number) => `Opzione ${index}`,
    bookOption: (index: number) => `Prenotare opzione ${index}`,
    from: "a partire da",
    totalPriceNights: (nights: number) =>
      `Prezzo completo per ${nights} ${nights === 1 ? "notte" : "notti"}`,
    occupancy: (
      adults: number,
      children: number,
      rooms: number,
      term: RoomTerm,
    ) =>
      `Suddividere ${adults + children} ${children > 0 ? "ospiti" : adults > 1 ? "adulti" : "adulto"} su ${rooms} ${
        rooms === 1
          ? getRoomTerm({
              term,
              room: "camera",
              apartment: "appartamento",
              suite: "suite",
            })
          : getRoomTerm({
              term,
              room: "camere",
              apartment: "appartamenti",
              suite: "suite",
            })
      }`,
    roomOccupancy: (adults: number, children: number) =>
      `Occupazione: ${adults + children} ${children > 0 ? "ospiti" : adults > 1 ? "adulti" : "adulto"}`,
    customOffer: {
      formal: (term: RoomTerm) =>
        `Suddivida gli ospiti selezionati su una o più ${getRoomTerm({
          term,
          room: "camere",
          apartment: "appartamenti",
          suite: "suite",
        })}.`,
      informal: (term: RoomTerm) =>
        `Suddividi gli ospiti selezionati su uno o più ${getRoomTerm({
          term,
          room: "camere",
          apartment: "appartamenti",
          suite: "suite",
        })}.`,
    },
    adultsCount: (count: number) =>
      `${count} ${count === 1 ? "adulto" : "adulti"}`,
    childrenCount: (count: number) =>
      `${count} ${count === 1 ? "bambino" : "bambini"}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      })} ${index}`,
    applySort: "Applicare",
    showAllRooms: (term: RoomTerm) =>
      `Mostrare tutte le ${getRoomTerm({
        term,
        room: "camere",
        apartment: "appartamenti",
        suite: "suite",
      })}`,
    childrenAge: (age: number) =>
      `Bambino ${age} ${age === 1 ? "anno" : "anni"}`,
    maxOccupancyReached: {
      formal: (
        <>
          Occupazione massima raggiunta.
          <br />
          Aggiunga un’ulteriore camera in basso a sinistra o scelga un’altra
          camera.
        </>
      ),
      informal: (
        <>
          Occupazione massima raggiunta.
          <br />
          Aggiungi un’ulteriore camera in basso a sinistra o scegli un’altra
          camera.
        </>
      ),
    },
    minOccupancyNotReached: {
      formal:
        "Occupazione minima non raggiunta. Le chiediamo di aggiungere un’ulteriore persona o scegliere un’altra camera.",
      informal:
        "Occupazione minima non raggiunta. Ti chiediamo di aggiungere un’ulteriore persona o scegliere un’altra camera.",
    },
    addAdditionalRoom: {
      formal: "Aggiunga un’ulteriore camera in basso a sinistra",
      informal: "Aggiungi un’ulteriore camera in basso a sinistra",
    },
    invalidOccupancy: "Occupazione della camera non valida",
    maxAdultsReached: "Numero massimo di adulti raggiunto",
    maxChildrenReached: "Numero massimo di bambini raggiunto",
    adultsRequired: "Per ogni camera serve almeno una persona adulta",
    notAllGuestsAssigned: "Non tutti gli ospiti sono stati assegnati",
  },
  roomConfigurations: {
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Scelga ${getRoomTerm({
          term,
          room: "la camera",
          apartment: "l’appartamento",
          suite: "la suite",
        })}`,
      informal: (term: RoomTerm) =>
        `Scegli ${getRoomTerm({
          term,
          room: "la camera",
          apartment: "l’appartamento",
          suite: "la suite",
        })}`,
    },
    continue: "Avanti",
    room: (term: RoomTerm) =>
      getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      }),
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      })} ${index}`,
    adults: (count: number) => `${count} ${count === 1 ? "adulto" : "adulti"}`,
    children: (count: number) =>
      `${count} ${count === 1 ? "bambino" : "bambini"}`,
    guests: "Ospiti",
    ratePlan: "Rata",
    mealType: "Ristorazione",
  },
  rooms: {
    occupancy: "Occupazione",
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Scelga ${getRoomTerm({
          term,
          room: "la camera",
          apartment: "l’appartamento",
          suite: "la suite",
        })}`,
      informal: (term: RoomTerm) =>
        `Scegli ${getRoomTerm({
          term,
          room: "la camera",
          apartment: "l’appartamento",
          suite: "la suite",
        })}`,
    },
    continue: "Avanti",
    availableRooms: (availableRooms: number) =>
      `Solo ${availableRooms} ${availableRooms === 1 ? "disponibile" : "disponibili"}`,
    save: (discount: number) => `Risparmiare il ${discount}%`,
    nights: (nights: number) => `${nights} ${nights === 1 ? "notte" : "notti"}`,
    nightPerRoom: (term: RoomTerm) =>
      `notte per ${getRoomTerm({
        term,
        room: "camera",
        apartment: "appartamento",
        suite: "suite",
      })}`,
    showPortalPrices:
      "Mostrare confronto prezzi con altre piattaforme di prenotazione",
    amenities: (term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "La camera",
        apartment: "L’appartamento",
        suite: "La suite",
      })} offre`,
    showMore: "Mostrare di più",
    backToOverview: "Tornare alla panoramica",
    sort: "Sorteggiare",
    sortHeadline: {
      formal: (term: RoomTerm) =>
        `Sorteggi la Sua lista ${getRoomTerm({
          term,
          room: "delle camere",
          apartment: "degli appartamenti",
          suite: "delle suite",
        })} a piacere`,
      informal: (term: RoomTerm) =>
        `Sorteggi la tua lista ${getRoomTerm({
          term,
          room: "delle camere",
          apartment: "degli appartamenti",
          suite: "delle suite",
        })} a piacere`,
    },
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      })} ${index}`,
    price: "Prezzo",
    size: "Grandezza (m²)",
    ascending: "Crescente",
    ascendingText: "Da minore a maggiore",
    descending: "Descrescente",
    descendingText: "Da maggiore a minore",
    resetSort: "Ripristinare tutto",
    applySort: "Applicare",
    roomOption: (option: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Questa camera",
        apartment: "Questo appartamento",
        suite: "Questa suite",
      })} fa parte di opzione ${option}`,
  },
  ratePlans: {
    selected: "Già selezionato",
    continue: "Avanti",
    selectRate: "Scegliere rata",
    showMoreDetails: "Mostrare più dettagli",
    back: "Indietro",
    general: "Generale",
    inclusives: "Servizi inclusi",
    boardsTitle: "Opzioni di ristorazione",
    boardsInfo: "(può essere scelta nel prossimo passo)",
    reservationPolicies: "Condizioni di prenotazione",
    includedInRate: "(incluso nella rata)",
    selectAndContinue: "Scegliere & procedere",
    showMoreOptions: "Mostrare più opzioni",
    moreDetails: "Mostrare più dettagli",
  },
  boards: {
    continue: "Selezionare & concludere",
    [BoardType.OvernightStayOnly]: "Solo pernottamento",
    [BoardType.BedAndBreakfast]: "Pernottamento con colazione",
    [BoardType.HalfBoard]: "Mezza pensione",
    [BoardType.ThreeQuarterBoard]: "Pensione a ¾",
    [BoardType.FullBoard]: "Pensione completa",
    [BoardType.AllInclusive]: "All inclusive",
    selectBoard: "Scegliere tipologia di ristorazione",
    included: "incluso",
    moreInfos: "Ulteriori informazioni",
    nightsAndAdults: (nights: number, adults: number) =>
      `Per ${nights} ${nights === 1 ? "pernottamento" : "pernottamenti"} & ${
        adults
      } ${adults === 1 ? "adulto" : "adulti"}`,
    nightsAndGuests: (nights: number, guests: number) =>
      `Per ${nights} ${nights === 1 ? "pernottamento" : "pernottamenti"} & ${
        guests
      } ${guests === 1 ? "ospite" : "ospiti"}`,
    back: "Indietro",
  },
  extras: {
    includedExtras: "Questi extra sono già inclusi nelle rate scelte.",
    selectAdditionalExtras: "Extra aggiuntivi",
    selectExtras: "Scegliere extra",
    goToCheckout: "Al riassunto della prenotazione",
    select: "Scegliere",
    edit: "Modificare",
    [ExtraType.PerDay]: "al giorno",
    [ExtraType.PerGuestAndDay]: "per ospite al giorno",
    [ExtraType.PerRoom]: "per camera",
    [ExtraType.PerStay]: "per soggiorno",
    all: (count: number) => `Tutti (${count})`,
    close: "Chiudere",
    add: (amount: string) => `Aggiungere alla prenotazione (${amount})`,
    quantity: "Quantità",
    room: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "camera",
        apartment: "appartamento",
        suite: "suite",
      })} ${count}`,
    allRooms: "Tutte le unità abitative",
    adults: "Adulti",
    children: "Bambini",
    selected: "Già selezionato",
    allAdults: "Tutti i giorni, tutti gli adulti",
    allChildren: "Tutti i giorni, tutti i bambini",
  },
  checkout: {
    summaryHeadline: {
      formal: "Riassunto della Sua prenotazione",
      informal: "Riassunto della tua prenotazione",
    },
    formHeadline: "Concludere prenotazione",
    travelPeriod: "Periodo di viaggio",
    arrival: "Arrivo",
    departure: "Partenza",
    dateNotSelected: "ancora da selezionare",
    guests: "Ospiti",
    ratePlan: "Rata",
    boardType: "Ristorazione",
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "adulto" : "adulti"}`,
    childrenCountSummary: (count: number) =>
      `${count} ${count === 1 ? "bambino" : "bambini"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Camera",
        apartment: "Appartamento",
        suite: "Suite",
      })} ${count}`,
    extras: "Extra",
    total: "Totale",
    continueToForm: {
      formal: "Avanti ai Suoi dati",
      informal: "Avanti ai tuoi dati",
    },
    priceInfo: "Tasse e commissioni comprese",
    yourData: { formal: "I Suoi dati", informal: "I tuoi dati" },
    showPriceDetails: "Mostrare dettagli",
    gender: { gender: "Appellativo", male: "Sig.", female: "Sig.ra" },
    firstName: "Nome",
    lastName: "Cognome",
    email: "Indirizzo email",
    phone: "Numero di telefono",
    street: "Via",
    postalCode: "CAP",
    municipality: "Città",
    country: "Paese",
    company: {
      isBusinessTrip: {
        formal: "È in viaggio per lavoro?",
        informal: "Sei in viaggio per lavoro?",
      },
      yes: "Sì, viaggio per lavoro",
      no: "No, questo è un viaggio privato",
      name: "Nome dell’azienda",
      vatNumber: "Partita IVA",
      recipientCode: "Codice destinatario",
      street: "Via",
      postalCode: "CAP",
      municipality: "Città",
      country: "Paese",
    },
    voucher: {
      formal: "Ha un codice voucher?",
      informal: "Hai un codice voucher?",
    },
    insertVoucher: "Inserire codice voucher",
    applyVoucher: "Riscattare",
    voucherAlreadyRedeemed: "Il voucher è già stato riscattato completamente.",
    voucherCancelled: "Il voucher è stato già annullato.",
    voucherExpired: "Il voucher non è più valido.",
    voucherInvalidCode: "Il voucher non è stato trovato.",
    voucherRedeemable: (voucherCode: string) =>
      `Voucher “${voucherCode}” riscattato con successo. Il valore del voucher può essere visualizzato nel carrello.`,
    note: "Richieste speciali",
    noteDescription: {
      formal:
        "Le richieste speciali non possono essere garantite. Tuttavia, faremo del nostro meglio per soddisfare i Suoi desideri.",
      informal:
        "Le richieste speciali non possono essere garantite. Tuttavia, faremo del nostro meglio per soddisfare i tuoi desideri.",
    },
    noteLabel: "Lasciare una nota",
    privacy: "informativa sulla privacy",
    terms: "termini e condizioni",
    book: "Sì, prenotare con obbligo di pagamento",
    paymentInfo: "Informazioni sul pagamento",
    paymentAmount: (amount: string, percentage: number) =>
      `${amount} – Caparra (${percentage}% dell’importo completo)`,
    paymentMethod: {
      [PrepaymentMethod.Card]: "Carta di credito",
      [PrepaymentMethod.BankTransfer]: "Bonifico bancario",
    },
    paymentType: "Modalità di pagamento",
    insurance: "Dormire senza preoccupazioni",
    insuranceLabel: (insuranceName: string, amount: string) => (
      <>
        Sì, voglio aggiungere {insuranceName} di <strong>{amount}</strong>{" "}
        (tasse incluse) alla mia prenotazione.
      </>
    ),
    showMoreInsuranceDetails: "Mostrare più dettagli",
    reservationPolicies: "Condizioni di prenotazione",
    bookingSummary: {
      formal: "Attualmente nel Suo carello si trova un viaggio dal valore di:",
      informal:
        "Attualmente nel tuo carello si trova un viaggio dal valore di:",
    },
    acceptPrivacyPolicyAndTermsLabel: (
      privacy: ReactNode,
      terms: ReactNode,
    ) => (
      <>
        Sì, accetto l’{privacy} e i {terms}.
      </>
    ),
    yourBooking: {
      formal: "La Sua prenotazione",
      informal: "La tua prenotazione",
    },
    extendStayHeadline: {
      formal: "Ha voglia di rimanere più a lungo?",
      informal: "Hai voglia di rimanere più a lungo?",
    },
    extendStayText: {
      formal: (percentage: number) =>
        `Riceverà uno sconto del ${percentage}% sul prezzo totale se rimarrà più a lungo.`,
      informal: (percentage: number) =>
        `Riceverai uno sconto del ${percentage}% sul prezzo totale se rimani più a lungo.`,
    },
    extendStayBefore: (nights: number) =>
      `Aggiungere ${nights} ${nights === 1 ? "notte" : "notti"} prima`,
    extendStayAfter: (nights: number) =>
      `Aggiungere ${nights} ${nights === 1 ? "notte" : "notti"} dopo`,
    forNights: (nights: number) =>
      `Per ${nights} ${nights === 1 ? "notte" : "notti"}`,
    basePrice: "Importo di prenotazione",
    touristTax: "Tassa di soggiorno (da pagare in loco)",
    totalPrice: "Importo totale",
    companyInvoiceData: {
      formal: "I Suoi dati di fatturazione",
      informal: "I tuoi dati di fatturazione",
    },
    noExtrasSelected: "Nessuni servizi aggiuntivi selezionati",
    extraSuggestions: {
      formal: "Vuole aggiungere qualche extra alla Sua prenotazione?",
      informal: "Vuoi aggiungere qualche extra alla tua prenotazione?",
    },
    addExtra: (amount: string) => `Aggiungere (${amount})`,
    childrenAge: (age: number) => `${age} ${age === 1 ? "anno" : "anni"}`,
  },
  booking: {
    success: {
      headline: "Prenotazione avvennuta con successo",
      text: {
        formal: (email: string) =>
          `Grazie, la Sua prenotazione è stata accettata. Un’email di conferma è stata inviata a ${email}.`,
        informal: (email: string) =>
          `Grazie, la tua prenotazione è stata accettata. Un’email di conferma è stata inviata a ${email}.`,
      },
      insuranceHeadline: {
        formal: "Vada sul sicuro!",
        informal: "Vai sul sicuro!",
      },
      insuranceText: {
        formal:
          "L’assicurazione spese di annullamento HGV offre protezione se inaspettatamente non può più partire o deve interrompere in anticipo il Suo viaggio.",
        informal:
          "L’assicurazione spese di annullamento HGV offre protezione se inaspettatamente non puoi più partire o devi interrompere in anticipo il tuo viaggio.",
      },
      insuranceButtonText: "Stipulare ora assicurazione di viaggio.",
    },
    cancellation: {
      headline: (bookingCode: string) =>
        `Riepilogo della prenotazione per il numero di prenotazione ${bookingCode}`,
      personalData: "Dati personali",
      propertyData: "Dati dell’alloggio",
      bookingDetails: "Dettagli della prenotazione",
      extras: "Servizi aggiuntivi",
      priceOverview: "Panoramica dei prezzi",
      paymentOptions: "Opzioni di pagamento",
      cancellationPolicies: "Condizioni di cancellazione",
      cancelBooking: "Cancella la prenotazione",
      cancel: "Cancellare",
      footerHeadline: { formal: "La aspettiamo!", informal: "Ti aspettiamo!" },
      firstName: "Nome",
      lastName: "Cognome",
      municipality: "Città",
      street: "Via",
      phone: "Numero di telefono",
      email: "Indirizzo email",
      property: "Alloggio",
      website: "Sito web",
      arrivalDeparture: "Arrivo – Partenza",
      nights: "notti",
      checkIn: "Check-in",
      checkOut: "Check-out",
      companyData: "Dati aziendali",
      companyName: "Azienda",
      vatNumber: "Partita IVA",
      recipientCode: "Codice destinatario",
      board: "Ristorazione",
      guests: "Ospiti",
      roomExtras: (term: RoomTerm) =>
        `Servizi aggiuntivi per ${getRoomTerm({
          term,
          room: "la camera",
          apartment: "l’appartamento",
          suite: "la suite",
        })}`,
      totalWithoutVat: "Importo di prenotazione",
      touristTax: "Tassa di soggiorno (da pagare in loco)",
      totalPrice: "Importo totale",
      adultsCount: (count: number) =>
        `${count} ${count === 1 ? "adulti" : "adulti"}`,
      childrenCount: (count: number) =>
        `${count} ${count === 1 ? "bambino" : "bambini"}`,
      cancellationPoliciesText: {
        formal: (
          <>
            Non è previsto un diritto di recesso ai sensi del Codice del Consumo
            – nonostante ciò Le accordiamo le seguenti condizioni di recesso:
          </>
        ),
        informal: (
          <>
            Non è previsto un diritto di recesso ai sensi del Codice del Consumo
            – nonostante ciò ti accordiamo le seguenti condizioni di recesso:
          </>
        ),
      },
      prepaymentMethod: {
        [PrepaymentMethod.Card]:
          "Caparra tramite carta di credito (Visa/Mastercard)",
        [PrepaymentMethod.BankTransfer]:
          "Caparra tramite trasferimento bancario",
      },
      touristTaxHeadline: "* Tassa di soggiorno",
      touristTaxText: (
        <>
          Per persona (a partire dai 14 anni) e notte è dovuta una tassa di
          soggiorno. Non è inclusa nel prezzo e viene pagata direttamente presso
          la struttura.
        </>
      ),
      reason: {
        [CancellationReason.GuestUnableToStay]: "Sono impedito in quel periodo",
        [CancellationReason.PropertyAskedToCancel]:
          "L’alloggio mi ha chiesto di cancellare",
        [CancellationReason.GuestChoseOtherDestination]:
          "Ho scelto un’altra destinazione",
        [CancellationReason.GuestChoseOtherProperty]:
          "Ho scelto un’altro alloggio",
        [CancellationReason.Other]: "Altri motivi",
      },
      note: "Indicare motivo",
      success: "La prenotazione è stata cancellata con successo.",
      error: {
        formal:
          "Nella trasmissione dei dati si è presentato un errore. La chiediamo di riprovare più tardi.",
        informal:
          "Nella trasmissione dei dati si è presentato un errore. Ti chiediamo di riprovare più tardi.",
      },
      alreadyCancelled: "La prenotazione selezionata è già stata cancellata.",
      modal: {
        abort: "Annulla",
        cancel: "Cancella prenotazione",
        text: {
          formal: (daysToArrival: number, fee: string) => (
            <>
              <strong>Attenzione:</strong> Se annulla la prenotazione ora (a{" "}
              {daysToArrival} giorni dall’arrivo) verranno addebitate spese di
              cancellazione pari a <strong>{fee}</strong>.
            </>
          ),
          informal: (daysToArrival: number, fee: string) => (
            <>
              <strong>Attenzione:</strong> Se annulli la prenotazione ora (a{" "}
              {daysToArrival} giorni dall’arrivo) ti verranno addebitate spese
              di cancellazione pari a <strong>{fee}</strong>.
            </>
          ),
        },
      },
    },
  },
};

export default it;
