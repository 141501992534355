import { ReactElement } from "react";
import {
  Control,
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  UseFormStateReturn,
} from "react-hook-form";
import { maxExtraQuantity } from "../../utils/constants";
import { ExtraFormType } from "./utils";

interface ExtraControlledQuantityFieldProps {
  index: number;
  control: Control<ExtraFormType>;
  render: ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerRenderProps<
      ExtraFormType,
      `quantities.${number}.quantity`
    >;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<ExtraFormType>;
  }) => ReactElement;
}

const ExtraControlledQuantityField = ({
  index,
  control,
  render,
}: ExtraControlledQuantityFieldProps) => (
  <Controller
    name={`quantities.${index}.quantity`}
    control={control}
    rules={{ min: 0, max: maxExtraQuantity }}
    render={render}
  />
);

export default ExtraControlledQuantityField;
