
      // TODO: This language override has only been implemented for testing.
      // Remove it in production.
      const languagePathParam = location.pathname.slice(1).split("/")[0];
      const overrideLanguage = ["de", "it", "en"].find(
        (v) => v === languagePathParam,
      );
      if (overrideLanguage) {
        document.documentElement.lang = overrideLanguage;
      }
    