import { FunctionComponent } from "react";
import { ExtraType } from "../../@types";
import { Extra } from "../../http/extra";
import styles from "./ExtraConfiguration.module.css";
import ExtraPerDay from "./ExtraPerDay";
import ExtraPerGuestAndDay from "./ExtraPerGuestAndDay";
import ExtraPerRoom from "./ExtraPerRoom";
import ExtraPerStay from "./ExtraPerStay";
import { ExtraProps } from "./utils";

interface ExtraConfigurationProps {
  extra: Extra;
}

const components: Record<ExtraType, FunctionComponent<ExtraProps>> = {
  [ExtraType.PerDay]: ExtraPerDay,
  [ExtraType.PerStay]: ExtraPerStay,
  [ExtraType.PerRoom]: ExtraPerRoom,
  [ExtraType.PerGuestAndDay]: ExtraPerGuestAndDay,
};

const ExtraConfiguration = ({ extra }: ExtraConfigurationProps) => {
  const ExtraComponent = components[extra.type];

  return (
    <div className={styles.configuration}>
      <ExtraComponent extra={extra} />
    </div>
  );
};

export default ExtraConfiguration;
