import useSWR from "swr";
import {
  FetcherOptions,
  Photo,
  Price,
  SelectedExtra,
  TotalPrices,
} from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { OfferSelection, SelectedOfferExtraResponse } from "./offer";
import { BoardType } from "./ratePlan";
import { api, fetcher } from "./utils";

export enum PrepaymentType {
  Prepayment = "prepayment",
  CardAsSecurity = "card_as_security",
  NoSecurity = "no_security",
}

export enum PrepaymentMethod {
  BankTransfer = "bank_transfer",
  Card = "card",
}

export enum Gender {
  None = "",
  Male = "male",
  Female = "female",
}

enum CancellationReason {
  GuestUnableToStay = "guest_unable_to_stay",
  PropertyAskedToCancel = "property_asked_to_cancel",
  GuestChoseOtherDestination = "guest_chose_other_destination",
  GuestChoseOtherProperty = "guest_chose_other_property",
  Other = "other",
}

interface Payment {
  type: PrepaymentType;
  prepayment_method: PrepaymentMethod;
  price: Price;
  percentage: number;
}

interface BookingResponse {
  payment_url: string;
}

export interface Company {
  name: string;
  vat_number: string;
  recipient_code?: string | undefined;
  street?: string | undefined;
  postal_code?: string | undefined;
  municipality?: string | undefined;
  country?: string | undefined;
}

export interface Guest {
  gender?: Gender | undefined;
  first_name: string;
  last_name: string;
  email_address: string;
  phone_number?: string | undefined;
  note?: string | undefined;
  street?: string | undefined;
  postal_code?: string | undefined;
  municipality?: string | undefined;
  country?: string | undefined;
  company?: Company | undefined;
}

interface BookingBody {
  offer_selections: OfferSelection[];
  extras?: SelectedExtra[] | undefined;
  insurance: boolean;
  voucher_code?: string | undefined;
  prepayment_method: PrepaymentMethod;
  guest: Guest;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings`
const postBookingURL = "/bookings.json";

export const postBooking = (_body: BookingBody) =>
  // TODO: replace with `api.url(postBookingURL).post(body).json<BookingResponse>();`
  api.url(postBookingURL).get().json<BookingResponse>();

export interface BookingRoom {
  room_type_id: string;
  room_type_name: string;
  occupancy: { adults: number; children?: number[] };
  board_type: BoardType;
  photo: Photo;
  extras: { id: string; title: string }[];
}

interface Booking {
  booking_code: string;
  guest: Guest;
  arrival: string;
  departure: string;
  nights_count: number;
  rooms: BookingRoom[];
  extras: SelectedOfferExtraResponse[];
  total_prices: TotalPrices;
  payment: Payment;
  cancellation_policies: string;
  days_to_arrival: number;
  cancellation_fee: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings/${bookingId}`
const bookingURL = "/get-booking-de.json";

export const useBooking = (bookingId: string) => {
  const language = useBookingStore((state) => state.language);

  return useSWR<Booking, Error>(
    { url: `${bookingURL}?bookingId=${bookingId}`, language },
    (opts: FetcherOptions) => fetcher<Booking>(opts),
  );
};

interface CancelBookingBody {
  cancellation_reason: CancellationReason;
  other_reason_text?: string | undefined;
}

// TODO: replace with live url: `/properties/${propertyId}/bookings/${bookingId}/cancel`
const cancelBookingURL = "/get-booking-de.json";

export const cancelBooking = (_body: CancelBookingBody) =>
  // TODO: replace with `api.url(cancelBookingURL).post(body).json();`
  api.url(cancelBookingURL).get().json();
