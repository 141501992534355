import { useState } from "react";
import { WretchError } from "wretch/resolver";
import { useBookingStore } from "../../hooks/useBookingStore";
import { useCheckoutFormStore } from "../../hooks/useCheckoutFormStore";
import useMediaQuery from "../../hooks/useMediaQuery";
import { postBooking } from "../../http/booking";
import Error, { ErrorType } from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import Form from "../../ui/Form";
import styles from "./Checkout.module.css";
import CheckoutForm from "./CheckoutForm";
import CheckoutSummary from "./CheckoutSummary";
import {
  CheckoutFormType,
  CheckoutStep,
  GuestType,
  toUndefinedIfEmpty,
} from "./utils";

const Checkout = () => {
  const isDesktop = useMediaQuery("(min-width: 1125px)");
  const checkoutFormValues = useCheckoutFormStore((state) => state.data);
  const language = useBookingStore((state) => state.language);
  const resetCheckoutFormStore = useCheckoutFormStore(
    (state) => state.actions.reset,
  );
  const [checkoutStep, setCheckoutStep] = useState(CheckoutStep.Summary);
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const voucherCode = useBookingStore((state) => state.voucherCode);
  const [isBookable, setIsBookable] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (!isBookable) {
    return <Error type={ErrorType.Warning} />;
  }

  const submitBooking = async ({
    guest,
    company,
    general,
  }: CheckoutFormType) => {
    if (!general.prepayment_method || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      await postBooking({
        offer_selections:
          checkoutData?.rooms.map((room) => ({
            adults: room.occupancy.adults,
            children: room.occupancy.children,
            room_type_id: room.room_type_id,
            rate_plan_id: room.rate_plan.id,
            board_type: room.board_type,
          })) ?? [],
        extras: checkoutData?.extras.map((extra) => ({
          id: extra.id,
          ...extra.amount,
        })),
        guest: {
          gender: toUndefinedIfEmpty(guest.gender),
          first_name: guest.first_name,
          last_name: guest.last_name,
          email_address: guest.email_address,
          phone_number: toUndefinedIfEmpty(guest.phone_number),
          note: toUndefinedIfEmpty(guest.note),
          street: toUndefinedIfEmpty(guest.street),
          postal_code: toUndefinedIfEmpty(guest.postal_code),
          municipality: toUndefinedIfEmpty(guest.municipality),
          country: toUndefinedIfEmpty(guest.country),
          company:
            general.guestType === GuestType.Company
              ? {
                  name: company.name,
                  vat_number: company.vat_number,
                  recipient_code: toUndefinedIfEmpty(company.recipient_code),
                  street: toUndefinedIfEmpty(company.street),
                  postal_code: toUndefinedIfEmpty(company.postal_code),
                  municipality: toUndefinedIfEmpty(company.municipality),
                  country: toUndefinedIfEmpty(company.country),
                }
              : undefined,
        },
        insurance: general.insurance,
        voucher_code: toUndefinedIfEmpty(voucherCode),
        prepayment_method: general.prepayment_method,
      });

      // TODO: redirect to payment page
      // globalThis.location.href = bookingResponse.payment_url;
      globalThis.location.href = `/${language}/booking-success?booking_id=00a16eb2-87b0-454e-9755-34286c955106`;
    } catch (error) {
      if (error instanceof WretchError && error.status === 409) {
        setIsBookable(false);
      }
      setIsSubmitting(false);
      throw error;
    } finally {
      resetCheckoutFormStore();
    }
  };

  return (
    <PageWithHeader contentClassName={styles.pageContent}>
      <Form<CheckoutFormType>
        mode="onSubmit"
        defaultValues={checkoutFormValues}
        onSubmit={submitBooking}
      >
        <CenterWrapper className={styles.centerWrapper}>
          {(checkoutStep === CheckoutStep.Summary || isDesktop) && (
            <div className={styles.checkoutSummaryWrapper}>
              <CheckoutSummary onChangeStep={(step) => setCheckoutStep(step)} />
            </div>
          )}
          {(checkoutStep === CheckoutStep.Form || isDesktop) && (
            <CheckoutForm
              checkoutData={checkoutData}
              isSubmitting={isSubmitting}
            />
          )}
        </CenterWrapper>
      </Form>
    </PageWithHeader>
  );
};

export default Checkout;
