import {
  ReferenceType,
  arrow,
  autoPlacement,
  offset,
  useFloating,
} from "@floating-ui/react-dom";
import clsx from "clsx";
import { ReactNode, useEffect, useRef } from "react";
import { useOnInteractionOutside } from "../hooks/useOnInteractionOutside";
import styles from "./Tooltip.module.css";

interface TooltipProps {
  title: ReactNode;
  isVisible: boolean;
  anchor: ReferenceType | null;
  onClose: () => void;
  onIsPositionChange?: (floatingEl: Element) => void;
  duration?: number;
  className?: string;
}

const Tooltip = ({
  title,
  isVisible,
  anchor,
  onClose,
  onIsPositionChange,
  duration = 1500,
  className,
}: TooltipProps) => {
  const arrowRef = useRef<HTMLDivElement>(null);
  const { refs, floatingStyles, middlewareData, placement, isPositioned } =
    useFloating({
      elements: { reference: anchor },
      open: isVisible,
      middleware: [
        offset(17),
        arrow({ element: arrowRef }),
        autoPlacement({
          crossAxis: true,
          allowedPlacements: ["top", "top-start", "top-end"],
        }),
      ],
    });

  const side = placement.split("-")[0] ?? "bottom";

  useOnInteractionOutside(refs.floating, () => onClose());

  useEffect(() => {
    const durationTimer = setTimeout(() => {
      onClose();
    }, duration);
    return () => clearTimeout(durationTimer);
  }, [duration, onClose]);

  useEffect(() => {
    if (
      !isPositioned ||
      !isVisible ||
      !refs.floating.current ||
      !onIsPositionChange
    ) {
      return;
    }
    onIsPositionChange(refs.floating.current);
  }, [isPositioned, refs, isVisible, onIsPositionChange]);

  return (
    isVisible &&
    title && (
      <div
        ref={refs.setFloating}
        className={clsx(styles.tooltip, className)}
        style={floatingStyles}
      >
        {title}
        <div
          className={styles.arrow}
          ref={arrowRef}
          style={{
            top: middlewareData.arrow?.y,
            left: middlewareData.arrow?.x,
            [side]: "calc(100% - 5px)",
          }}
        />
      </div>
    )
  );
};

export default Tooltip;
