import { ReactNode } from "react";
import { ExtraType, I18n, RoomTerm } from "../@types";
import { CancellationReason } from "../domain/booking/utils";
import { PrepaymentMethod } from "../http/booking";
import { BoardType } from "../http/ratePlan";
import { getRoomTerm } from "./utils";

const en: I18n = {
  start: {
    hgv: "Hotel and Restaurant Keeper’s Association",
    email: "Send email",
    phone: "Call",
    legalMenu: {
      privacy: "Privacy",
      terms: "Terms and conditions",
      imprint: "Impressum",
      imageSources: "Picture source",
    },
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "adult" : "adults"}`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "guest" : "guests"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${count} ${
        count === 1
          ? getRoomTerm({
              term,
              room: "room",
              apartment: "apartment",
              suite: "suite",
            })
          : getRoomTerm({
              term,
              room: "rooms",
              apartment: "apartments",
              suite: "suites",
            })
      }`,
    guestsAndRooms: (term: RoomTerm) =>
      `Guests & ${getRoomTerm({
        term,
        room: "rooms",
        apartment: "apartments",
        suite: "suites",
      })}`,
    travelPeriod: "Travel dates",
    nights: (nights: number) =>
      `(${nights} ${nights === 1 ? "night" : "nights"})`,
    arrival: "Arrival?",
    departure: "Departure?",
    yourBooking: {
      formal: "Your booking",
      get informal() {
        return this.formal;
      },
    },
    guests: "Guests",
  },
  occupancy: {
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "Guest" : "Guests"}`,
    apply: "Apply",
    guestsAndRooms: (term: RoomTerm) =>
      `Guests & ${getRoomTerm({
        term,
        room: "rooms",
        apartment: "apartments",
        suite: "suites",
      })}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    autoOccupancy: (term: RoomTerm) =>
      `Automatically assign guests to ${getRoomTerm({
        term,
        room: "rooms",
        apartment: "apartments",
        suite: "suites",
      })}.`,
    autoOccupancyInfo: (term: RoomTerm) =>
      `Receive various occupancy and ${getRoomTerm({
        term,
        room: "room",
        apartment: "apartment",
        suite: "suite",
      })} suggestions for the selected number of guests.`,
    adults: "Adults",
    adultsInfo: "18 and older",
    children: "Children",
    childrenInfo: (minAge: number) => `Age: ${minAge}–17 years`,
  },
  calendar: {
    arrival: "Arrival?",
    departure: "Departure?",
    continueWithNights: (nights: number) =>
      `Go ahead with ${nights} ${nights === 1 ? "night" : "nights"}`,
    arrivalNotAvailable: "Arrival on this day not possible",
    departureNotAvailable: "Departure on this day not possible",
    departureBeforeArrival: "Departure before arrival not possible",
    minStay: (nights: number) =>
      `Minimum stay: ${nights} ${nights === 1 ? "night" : "nights"}`,
    maxStay: (nights: number) =>
      `Maximum stay: ${nights} ${nights === 1 ? "night" : "nights"}`,
    discountTitle: {
      formal: "Stay longer!",
      get informal() {
        return this.formal;
      },
    },
    discountText: {
      formal: (percentage: number) =>
        `You will get a ${percentage}% discount on the total amount if you stay longer.`,
      get informal() {
        return this.formal;
      },
    },
    extendStayBefore: (nights: number) =>
      `Arrive ${nights} ${nights === 1 ? "night" : "nights"} earlier`,
    extendStayAfter: (nights: number) =>
      `Leave ${nights} ${nights === 1 ? "night" : "nights"} later`,
    successTitle: "Great choice!",
    successText: "Travel dates changed successfully.",
  },
  general: {
    close: "Close",
    delete: "Delete",
    remove: "Remove",
    expand: "Open",
    less: "Less",
    more: "More",
    share: "Share",
    edit: "Edit",
    openLightbox: "Open picture gallery",
    readMore: "Read more",
    back: "Back",
    form: {
      errors: {
        required: "Mandatory field",
        email: {
          formal: "Insert a valid email address.",
          informal: "Please insert a valid email address.",
        },
      },
    },
    errorPage: {
      title: "An unexpected error has occurred.",
      description:
        "Lorem ipsum dolro sit amend orls it amend orlsi tamend orl osit amend orls it amend orl sit amen dorls ti amen.",
      button: "Try again",
    },
    notFoundPage: {
      title: {
        formal: (
          <>
            Oh no!
            <br />
            The site you are looking for doesn’t exist.
          </>
        ),
        get informal() {
          return this.formal;
        },
      },
      description: {
        formal:
          "Unfortunately, we cannot find any results for your search – but we can certainly make your holiday a special experience.",
        get informal() {
          return this.formal;
        },
      },
      button: "Book now",
    },
    alerts: {
      requestFailed: {
        title: (requestName: string) => `"${requestName}" failed`,
        description: {
          formal: "Try again later",
          get informal() {
            return this.formal;
          },
        },
      },
      share: {
        title: "Sharing not possible",
        description: {
          formal: "Try again later",
          get informal() {
            return this.formal;
          },
        },
      },
    },
  },
  photoSlider: { showAllImages: "Show all pictures" },
  childAgeSelect: {
    childrenAge: (count: number) => `Age child ${count}`,
    selectAge: "Select age",
  },
  autoOccupancy: {
    apply: "Apply",
    adults: "Adults",
    adultsInfo: "18 and older",
    children: "Children",
    childrenInfo: (minAge: number) => `Age: ${minAge}–17 years`,
    guestsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "guests" : "guests"}`,
    ourSuggestion: {
      formal: "Our suggestion for you",
      get informal() {
        return this.formal;
      },
    },
    noSuitableSuggestion: "Didn’t find a suitable suggestion?",
    splitGuests: {
      formal: (term: RoomTerm) =>
        `Assign the selected guests to one or more ${getRoomTerm({
          term,
          room: "rooms",
          apartment: "apartments",
          suite: "suites",
        })}.`,
      get informal() {
        return this.formal;
      },
    },
    assignGuestsIndividually: "Assign guests individually",
    option: (index: number) => `Option ${index}`,
    bookOption: (index: number) => `Book option ${index}`,
    from: "from",
    totalPriceNights: (nights: number) =>
      `Total price for ${nights} ${nights === 1 ? "night" : "nights"}`,
    occupancy: (
      adults: number,
      children: number,
      rooms: number,
      term: RoomTerm,
    ) =>
      `Assign ${adults + children} ${children > 0 ? "guests" : adults > 1 ? "adults" : "adult"} to ${rooms} ${
        rooms === 1
          ? getRoomTerm({
              term,
              room: "room",
              apartment: "apartment",
              suite: "suite",
            })
          : getRoomTerm({
              term,
              room: "rooms",
              apartment: "apartments",
              suite: "suites",
            })
      }`,
    roomOccupancy: (adults: number, children: number) =>
      `Occupancy: ${adults + children} ${children > 0 ? "guests" : adults > 1 ? "adults" : "adult"}`,
    customOffer: {
      formal: (term: RoomTerm) =>
        `Assign the selected guests to one or more ${getRoomTerm({
          term,
          room: "rooms",
          apartment: "apartments",
          suite: "suites",
        })}.`,
      get informal() {
        return this.formal;
      },
    },
    adultsCount: (count: number) =>
      `${count} ${count === 1 ? "adult" : "adults"}`,
    childrenCount: (count: number) =>
      `${count} ${count === 1 ? "child" : "children"}`,
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    applySort: "Apply",
    showAllRooms: (term: RoomTerm) =>
      `Show all ${getRoomTerm({
        term,
        room: "rooms",
        apartment: "apartments",
        suite: "suites",
      })}`,
    childrenAge: (age: number) =>
      `Child ${age} ${age === 1 ? "year" : "years"}`,
    maxOccupancyReached: {
      formal: (
        <>
          Maximum occupancy reached.
          <br />
          Add another room at the bottom left or choose a different room.
        </>
      ),
      get informal() {
        return this.formal;
      },
    },
    minOccupancyNotReached: {
      formal:
        "Minimum occupancy not reached. Please add another person or choose another room.",
      get informal() {
        return this.formal;
      },
    },
    addAdditionalRoom: {
      formal: "Add another room at the bottom left",
      get informal() {
        return this.formal;
      },
    },
    invalidOccupancy: "Invalid room occupancy",
    maxAdultsReached: "Maximum number of adults reached",
    maxChildrenReached: "Maximum number of children reached",
    adultsRequired: "At least one adult per room is required",
    notAllGuestsAssigned: "Not all guests assigned",
  },
  roomConfigurations: {
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Select the ${getRoomTerm({
          term,
          room: "room",
          apartment: "apartment",
          suite: "suite",
        })}`,
      get informal() {
        return this.formal;
      },
    },
    continue: "Go ahead",
    room: (term: RoomTerm) =>
      getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      }),
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    adults: (count: number) => `${count} ${count === 1 ? "adult" : "adults"}`,
    children: (count: number) =>
      `${count} ${count === 1 ? "child" : "children"}`,
    guests: "Guests",
    ratePlan: "Rate",
    mealType: "Meals",
  },
  rooms: {
    occupancy: "Occupancy",
    selectRoom: {
      formal: (term: RoomTerm) =>
        `Select the ${getRoomTerm({
          term,
          room: "room",
          apartment: "apartment",
          suite: "suite",
        })}`,
      get informal() {
        return this.formal;
      },
    },
    continue: "Go ahead",
    availableRooms: (availableRooms: number) =>
      `Only ${availableRooms} remaining`,
    save: (discount: number) => `Save ${discount}%`,
    nights: (nights: number) =>
      `${nights} ${nights === 1 ? "night" : "nights"}`,
    nightPerRoom: (term: RoomTerm) =>
      `night per ${getRoomTerm({
        term,
        room: "room",
        apartment: "apartment",
        suite: "suite",
      })}`,
    showPortalPrices: "Display price comparison with booking platforms",
    amenities: (term: RoomTerm) =>
      `The ${getRoomTerm({
        term,
        room: "room",
        apartment: "apartment",
        suite: "suite",
      })} offers`,
    showMore: "Show more",
    backToOverview: "Back to the overview",
    sort: "Sort",
    sortHeadline: {
      formal: (term: RoomTerm) =>
        `Sort your ${getRoomTerm({
          term,
          room: "room",
          apartment: "apartment",
          suite: "suite",
        })} list as you wish`,
      get informal() {
        return this.formal;
      },
    },
    roomIndex: (term: RoomTerm, index: number) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${index}`,
    price: "Price",
    size: "Size (m²)",
    ascending: "Ascending",
    ascendingText: "From small to big",
    descending: "Descending",
    descendingText: "From big to small",
    resetSort: "Reset all",
    applySort: "Apply",
    roomOption: (option: number, term: RoomTerm) =>
      `This ${getRoomTerm({
        term,
        room: "room",
        apartment: "apartment",
        suite: "suite",
      })} is part of option ${option}`,
  },
  ratePlans: {
    selected: "Already selected",
    continue: "Go ahead",
    selectRate: "Choose rate",
    showMoreDetails: "Show more details",
    back: "Back",
    general: "General",
    inclusives: "Included services",
    boardsTitle: "Meal options",
    boardsInfo: "(can be selected in the next step)",
    reservationPolicies: "Booking conditions",
    includedInRate: "(included in the rate)",
    selectAndContinue: "Select and go ahead",
    showMoreOptions: "Show more options",
    moreDetails: "Show more details",
  },
  boards: {
    continue: "Select & complete",
    [BoardType.OvernightStayOnly]: "Accommodation only",
    [BoardType.BedAndBreakfast]: "Accommodation with breakfast",
    [BoardType.HalfBoard]: "Half board",
    [BoardType.ThreeQuarterBoard]: "¾ board",
    [BoardType.FullBoard]: "Full board",
    [BoardType.AllInclusive]: "All inclusive",
    selectBoard: "Choose meal plan",
    included: "Included",
    moreInfos: "More information",
    nightsAndAdults: (nights: number, adults: number) =>
      `For ${nights} ${nights === 1 ? "night" : "nights"} & ${
        adults
      } ${adults === 1 ? "adullt" : "adults"}`,
    nightsAndGuests: (nights: number, guests: number) =>
      `For ${nights} ${nights === 1 ? "night" : "nights"} & ${
        guests
      } ${guests === 1 ? "guest" : "guests"}`,
    back: "Back",
  },
  extras: {
    includedExtras: "These extras are already included in the selected rate.",
    selectAdditionalExtras: "Additional extras",
    selectExtras: "Select extras",
    goToCheckout: "To the booking summary",
    select: "Select",
    edit: "Modify",
    [ExtraType.PerDay]: "per day",
    [ExtraType.PerGuestAndDay]: "per guest and day",
    [ExtraType.PerRoom]: "per room",
    [ExtraType.PerStay]: "per stay",
    all: (count: number) => `All (${count})`,
    close: "Close",
    add: (amount: string) => `Add to the booking (${amount})`,
    quantity: "Quantity",
    room: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${count}`,
    allRooms: "All accommodation units",
    adults: "Adults",
    children: "Children",
    selected: "Already selected",
    allAdults: "All days, all adults",
    allChildren: "All days, all children",
  },
  checkout: {
    summaryHeadline: {
      formal: "Summary of your booking",
      get informal() {
        return this.formal;
      },
    },
    formHeadline: "Finalise booking",
    travelPeriod: "Travel dates",
    arrival: "Arrival",
    departure: "Departure",
    dateNotSelected: "not selected yet",
    guests: "Guests",
    ratePlan: "Rate",
    boardType: "Meal plan",
    adultsCountSummary: (count: number) =>
      `${count} ${count === 1 ? "adult" : "adults"}`,
    childrenCountSummary: (count: number) =>
      `${count} ${count === 1 ? "child" : "children"}`,
    roomsCountSummary: (count: number, term: RoomTerm) =>
      `${getRoomTerm({
        term,
        room: "Room",
        apartment: "Apartment",
        suite: "Suite",
      })} ${count}`,
    extras: "Extras",
    total: "Total",
    continueToForm: {
      formal: "Go ahead to your data",
      get informal() {
        return this.formal;
      },
    },
    priceInfo: "Including taxes and fees",
    yourData: {
      formal: "Your data",
      get informal() {
        return this.formal;
      },
    },
    showPriceDetails: "Show details",
    gender: { gender: "Title", male: "Mr", female: "Mrs" },
    firstName: "First name",
    lastName: "Last name",
    email: "Email address",
    phone: "Telephone number",
    street: "Street",
    postalCode: "Postcode",
    municipality: "City",
    country: "Country",
    company: {
      isBusinessTrip: {
        formal: "Is this a business trip?",
        get informal() {
          return this.formal;
        },
      },
      yes: "Yes, it is a business trip",
      no: "No, this is a private trip",
      name: "Company name",
      vatNumber: "VAT number",
      recipientCode: "Recipient code",
      street: "Street",
      postalCode: "Postcode",
      municipality: "City",
      country: "Country",
    },
    voucher: {
      formal: "Do you have a gift voucher?",
      get informal() {
        return this.formal;
      },
    },
    insertVoucher: "Insert voucher code",
    applyVoucher: "Redeem",
    voucherAlreadyRedeemed: "Voucher has already been fully redeemed.",
    voucherCancelled: "Voucher has already been canceled.",
    voucherExpired: "The voucher has expired.",
    voucherInvalidCode: "Voucher was not found.",
    voucherRedeemable: (voucherCode: string) =>
      `Voucher “${voucherCode}” successfully redeemed. The voucher amount is now visible in your basket.`,
    note: "Special requests",
    noteDescription: {
      formal:
        "Special requests cannot be guaranteed. However, we will do our best to accommodate your wishes.",
      get informal() {
        return this.formal;
      },
    },
    noteLabel: "Leave a comment",
    privacy: "privacy policy",
    terms: "terms and conditions",
    book: "Yes, book with payment obligation",
    paymentInfo: "Payment details",
    paymentAmount: (amount: string, percentage: number) =>
      `${amount} – down payment (${percentage}% of the total amount)`,
    paymentMethod: {
      [PrepaymentMethod.Card]: "Credit card",
      [PrepaymentMethod.BankTransfer]: "Bank transfer",
    },
    paymentType: "Payment method",
    insurance: "A save sleep",
    insuranceLabel: (insuranceName: string, amount: string) => (
      <>
        Yes, I would like to add the {insuranceName} to my booking for{" "}
        <strong>{amount}</strong> (taxes included).
      </>
    ),
    showMoreInsuranceDetails: "Show more details",
    reservationPolicies: "Booking conditions",
    bookingSummary: {
      formal: "At the moment, your basket contains a trip worth:",
      get informal() {
        return this.formal;
      },
    },
    acceptPrivacyPolicyAndTermsLabel: (
      privacy: ReactNode,
      terms: ReactNode,
    ) => (
      <>
        Yes, I accept the {privacy} and {terms}.
      </>
    ),
    yourBooking: {
      formal: "Your booking",
      get informal() {
        return this.formal;
      },
    },
    extendStayHeadline: {
      formal: "Do you want to stay longer?",
      get informal() {
        return this.formal;
      },
    },
    extendStayText: {
      formal: (percentage: number) =>
        `You will get a ${percentage}% discount on the total amount if you stay longer.`,
      get informal() {
        return this.formal;
      },
    },
    extendStayBefore: (nights: number) =>
      `Arrive ${nights} ${nights === 1 ? "night" : "nights"} earlier`,
    extendStayAfter: (nights: number) =>
      `Leave ${nights} ${nights === 1 ? "night" : "nights"} later`,
    forNights: (nights: number) =>
      `For ${nights} ${nights === 1 ? "night" : "nights"}`,
    basePrice: "Booking amount",
    touristTax: "Tourist tax (to be paid on-site)",
    totalPrice: "Total amount",
    companyInvoiceData: {
      formal: "Your billing details",
      get informal() {
        return this.formal;
      },
    },
    noExtrasSelected: "No additional services selected",
    extraSuggestions: {
      formal: "Would you like to add extras to your booking?",
      get informal() {
        return this.formal;
      },
    },
    addExtra: (amount: string) => `Add (${amount})`,
    childrenAge: (age: number) => `${age} ${age === 1 ? "year" : "years"}`,
  },
  booking: {
    success: {
      headline: "Booking successful",
      text: {
        formal: (email: string) =>
          `Thank you, your booking has been confirmed. A booking confirmation has been sent to ${email}.`,
        get informal() {
          return this.formal;
        },
      },
      insuranceHeadline: {
        formal: "Play it safe!",
        get informal() {
          return this.formal;
        },
      },
      insuranceText: {
        formal:
          "The HGV cancellation protection provides coverage if you are unable to start your trip unexpectetly or have to cut it short.",
        get informal() {
          return this.formal;
        },
      },
      insuranceButtonText: "Get travel insurance now",
    },

    cancellation: {
      headline: (bookingCode: string) =>
        `Booking overview for booking number ${bookingCode}`,
      personalData: "Personal details",
      propertyData: "Accomodation details",
      bookingDetails: "Booking details",
      extras: "Additional services",
      priceOverview: "Price overview",
      paymentOptions: "Payment options",
      cancellationPolicies: "Terms of cancellation",
      cancelBooking: "Cancel booking",
      cancel: "Cancel",
      footerHeadline: {
        formal: "We are looking forward to welcoming you!",
        get informal() {
          return this.formal;
        },
      },
      firstName: "First name",
      lastName: "Last name",
      municipality: "City",
      street: "Street",
      phone: "Telephone number",
      email: "Email address",
      property: "Accomodation",
      website: "Website",
      arrivalDeparture: "Arrival – Departure",
      nights: "Nights",
      checkIn: "Check-in",
      checkOut: "Check-out",
      companyData: "Company details",
      companyName: "Company",
      vatNumber: "VAT number",
      recipientCode: "Recipient code",
      board: "Meals",
      guests: "Guests",
      roomExtras: (term: RoomTerm) =>
        `Additional services for the ${getRoomTerm({
          term,
          room: "room",
          apartment: "apartment",
          suite: "suite",
        })}`,
      totalWithoutVat: "Booking amount",
      touristTax: "Tourist tax (to be paid on-site)",
      totalPrice: "Total amount",
      adultsCount: (count: number) =>
        `${count} ${count === 1 ? "adult" : "adults"}`,
      childrenCount: (count: number) =>
        `${count} ${count === 1 ? "child" : "children"}`,
      cancellationPoliciesText: {
        formal: (
          <>
            There is no right to withdrawal in the sense of the consumer
            protection code – nevertheless, we grant you the following
            cancellation conditions:
          </>
        ),
        get informal() {
          return this.formal;
        },
      },
      prepaymentMethod: {
        [PrepaymentMethod.Card]:
          "Down payment via credit card (Visa/Mastercard)",
        [PrepaymentMethod.BankTransfer]: "Down payment via bank transfer",
      },
      touristTaxHeadline: "* Tourist tax",
      touristTaxText: (
        <>
          Per person (14 and older) and night, a tourist tax is to be paid. It
          is not included in the booking amount and is to be paid on site in the
          accommodation.
        </>
      ),
      reason: {
        [CancellationReason.GuestUnableToStay]:
          "I am unavailable at that period",
        [CancellationReason.PropertyAskedToCancel]:
          "The accommodation has asked me to cancel",
        [CancellationReason.GuestChoseOtherDestination]:
          "I have chosen another vacation destination",
        [CancellationReason.GuestChoseOtherProperty]:
          "I have chosen another accommodation",
        [CancellationReason.Other]: "Other reasons",
      },
      note: "Indicate reason",
      success: "The booking was canceled successfully.",
      error: {
        formal:
          "An error occurred while submitting the data. Please try again later.",
        get informal() {
          return this.formal;
        },
      },
      alreadyCancelled: "The selected booking has already been canceled.",
      modal: {
        abort: "Exit",
        cancel: "Cancel booking",
        text: {
          formal: (daysToArrival: number, fee: string) => (
            <>
              <strong>Attention:</strong> If you cancel your booking now (
              {daysToArrival} days before arrival), a cancellation fee of{" "}
              <strong>{fee}</strong> will apply.
            </>
          ),
          get informal() {
            return this.formal;
          },
        },
      },
    },
  },
};

export default en;
