import { Checkbox, Field, Label } from "@headlessui/react";
import clsx from "clsx";
import styles from "./CheckboxButton.module.css";
import Check from "./icon/check.svg?react";

interface CheckboxButtonProps {
  name: string;
  label: string;
  checked: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  onChange: (checked: boolean) => void;
}

const CheckboxButton = ({
  name,
  label,
  checked,
  className,
  onChange,
  ...rest
}: CheckboxButtonProps) => (
  <Field>
    <Checkbox
      name={name}
      checked={checked}
      onChange={onChange}
      className={clsx(styles.checkbox, className)}
      {...rest}
    >
      <span className={styles.content}>
        <Check className={styles.icon} aria-hidden />
        {label && <Label className={styles.label}>{label}</Label>}
      </span>
    </Checkbox>
  </Field>
);

export default CheckboxButton;
