import { Switch as HeadlessSwitch } from "@headlessui/react";
import styles from "./Switch.module.css";

interface SwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Switch = ({ checked, onChange }: SwitchProps) => (
  <HeadlessSwitch
    checked={checked}
    onChange={onChange}
    className={styles.switch}
  />
);

export default Switch;
