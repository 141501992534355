import useSWR from "swr";
import {
  AutoOccupancy,
  ExtraType,
  Image,
  Language,
  Occupancy,
  Price,
  Prices,
  ReservationPolicies,
  RoomConfiguration,
  SelectedExtra,
  SelectedExtraAmount,
  TotalPrices,
} from "../@types";
import { useBookingStore } from "../hooks/useBookingStore";
import { useRequestFailedAlert } from "../hooks/useRequestFailedAlert";
import { apiRequestDedupingIntervals } from "../utils/constants";
import {
  setOccupancySearchParams,
  setOccupancySearchParamsByRoomConfigurations,
} from "../utils/occupancy";
import { PrepaymentMethod, PrepaymentType } from "./booking";
import { BoardType } from "./ratePlan";
import {
  api,
  buildURL,
  fetcher,
  setArrivalSearchParams,
  setDepartureSearchParams,
} from "./utils";

interface Offers {
  offers: Offer[];
}

export interface Offer {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  rates: Rates[];
}

export interface OfferBoard {
  board_type: BoardType;
  surcharge: Price;
}

export interface Rates {
  rate_plan_id: string;
  prices: Prices;
  board_types: OfferBoard[];
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const offersURL = "/offers.json";

export const useOffers = () => {
  const language = useBookingStore((state) => state.language);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const addRequestFailedAlert = useRequestFailedAlert("getOffers");
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const searchParams = getSearchParams({
        arrival,
        departure,
        roomConfigurations,
        occupancies,
        roomTypeId,
        ratePlanId,
      });
      if (!searchParams) {
        return null;
      }

      return { url: buildURL(offersURL, searchParams), language };
    },
    async (opts) => {
      const { offers } = await fetcher<Offers>(opts);

      return {
        offers,
        offersMap: Object.fromEntries(
          offers.map((x) => [`${x.room_index}_${x.room_type_id}`, x]),
        ),
      };
    },
    { dedupingInterval: apiRequestDedupingIntervals.short },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, ...data };
};

interface AutoOffers {
  auto_offers: AutoOffer[];
}

export interface AutoOffer {
  total_prices: Prices;
  rooms: AutoOfferRoom[];
  roomsMap: Record<string, AutoOfferRoom>;
}

export interface AutoOfferRoom {
  room_type_id: string;
  name: string;
  room_index: number;
  available_rooms: number | null;
  cheapest_prices: Prices;
  occupancy: { adults: number; children?: number[] };
  rates: Rates[];
}

// TODO: replace with live url: `/properties/${propertyId}/auto_offers`
const autoOffersURL = "/auto-offers-de.json";

export const useAutoOffers = () => {
  const language = useBookingStore((state) => state.language);
  const addRequestFailedAlert = useRequestFailedAlert("getAutoOffers");
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const searchParams = getSearchParams({
        arrival,
        departure,
        autoOccupancy,
      });
      if (!searchParams) {
        return null;
      }

      return { url: buildURL(autoOffersURL, searchParams), language };
    },
    async (opts) => {
      const { auto_offers: autoOffers } = await fetcher<AutoOffers>(opts);

      autoOffers.forEach((ao) => {
        ao.roomsMap = Object.fromEntries(
          ao.rooms.map((x) => [`${x.room_index}_${x.room_type_id}`, x]),
        );
      });

      return { autoOffers };
    },
    { dedupingInterval: apiRequestDedupingIntervals.short },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, ...data };
};

interface PortalPrices {
  portal_prices: PortalPrice[];
}

interface PortalPrice {
  name: string;
  room_index?: number;
  icon: Image;
  price: Price;
}

// TODO: replace with live url: `/properties/${propertyId}/portal_prices`
const portalPricesURL = "/portal-prices.json";

export const usePortalPrices = () => {
  const addRequestFailedAlert = useRequestFailedAlert("getPortalPrices");
  const occupancies = useBookingStore((state) => state.occupancies);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const arrival = useBookingStore((state) => state.arrival);
  const departure = useBookingStore((state) => state.departure);

  const { data, ...rest } = useSWR(
    () => {
      const searchParams = getSearchParams({
        arrival,
        departure,
        roomConfigurations,
        occupancies,
      });
      if (!searchParams) {
        return null;
      }

      return { url: buildURL(portalPricesURL, searchParams) };
    },
    async (opts) => (await fetcher<PortalPrices>(opts)).portal_prices,
    { dedupingInterval: apiRequestDedupingIntervals.short },
  );

  if (rest.error) {
    addRequestFailedAlert();
  }

  return { ...rest, portalPrices: data };
};

export interface OfferSelection {
  adults: number;
  children?: number[] | undefined;
  room_type_id: string;
  rate_plan_id: string;
  board_type: BoardType | null;
}

export interface SelectedOfferBody {
  offer_selections: OfferSelection[];
  insurance: boolean;
  voucher_code?: string | undefined;
  extras?: SelectedExtra[] | undefined;
}

interface SelectedOfferRoom {
  room_type_id: string;
  name: string;
  occupancy: { adults: number; children?: number[] };
  rate_plan: { id: string; title: string; reservation_policies: string };
  board_type: BoardType;
  price: Price;
}

export interface SelectedOfferExtraResponse {
  id: string;
  title: string;
  is_included: boolean;
  amount: SelectedExtraAmount;
  total_amount: number;
  total_price: Price;
}

interface ExtendStaySuggestion {
  arrival: string;
  departure: string;
  discount: number;
}

export interface ExtraSuggestion {
  id: string;
  title: string;
  type: ExtraType;
  description: string;
  price_per_unit: Price;
}

export interface SelectedOfferResponse {
  check_in: { from: Date; to: Date };
  check_out: { from: Date; to: Date };
  rooms: SelectedOfferRoom[];
  extras: SelectedOfferExtraResponse[];
  prices: TotalPrices;
  payment: {
    type: PrepaymentType;
    prepayment_methods: PrepaymentMethod[];
    price: Price;
    percentage: number;
  };
  insurance?: { name: string; price: Price; description: string };
  extend_stay_suggestions: ExtendStaySuggestion[];
  extra_suggestions: ExtraSuggestion[];
  reservation_policies: ReservationPolicies;
}

// TODO: replace with live url: `/properties/${propertyId}/offers`
const postOffersURL = "/post-offers-de.json";
interface PostSelectedOffersOptions {
  arrival: Date | null;
  departure: Date | null;
  language: Language;
  body: SelectedOfferBody;
}

export const postSelectedOffers = (_options: PostSelectedOffersOptions) =>
  // const { arrival, departure, language, body } = options;
  // const searchParams = new URLSearchParams();
  // if (!setArrivalAndDepartureSearchParams(searchParams, arrival, departure)) {
  //   return null;
  // }

  // return api
  //   .url(buildURL(postOffersURL, searchParams))
  //   .headers({ "Accept-Language": language })
  //   .post(body)
  //   .json<SelectedOfferResponse>();
  // TODO: replace with POST request
  api.url(postOffersURL).get().json<SelectedOfferResponse>();

const getSearchParams = ({
  arrival,
  departure,
  occupancies,
  autoOccupancy,
  roomConfigurations,
  roomTypeId,
  ratePlanId,
}: {
  arrival?: Date | null;
  departure?: Date | null;
  occupancies?: Occupancy[];
  autoOccupancy?: AutoOccupancy | null;
  roomConfigurations?: RoomConfiguration[];
  roomTypeId?: string | null;
  ratePlanId?: string | null;
}) => {
  const searchParams = new URLSearchParams();
  if (arrival !== undefined) {
    const isSet = setArrivalSearchParams(searchParams, arrival);
    if (!isSet) {
      return null;
    }
  }

  if (departure !== undefined) {
    const isSet = setDepartureSearchParams(searchParams, departure);
    if (!isSet) {
      return null;
    }
  }

  if (roomConfigurations?.length) {
    setOccupancySearchParamsByRoomConfigurations(searchParams, {
      roomConfigurations,
      roomTypeId,
      ratePlanId,
    });
  } else if (occupancies || autoOccupancy) {
    setOccupancySearchParams(searchParams, {
      occupancies,
      autoOccupancy,
      roomTypeId,
      ratePlanId,
    });
  }

  return searchParams;
};
