import {
  DateDuration,
  DayOfWeek,
  endOfMonth,
  getWeeksInMonth,
} from "@internationalized/date";
import { RangeCalendarState } from "@react-stately/calendar";
import {
  AriaCalendarGridProps,
  useCalendarGrid,
  useDateFormatter,
} from "react-aria";
import CalendarCell from "./CalendarCell";

import { useCallback, useMemo } from "react";
import { useArrivalAvailabilities } from "../../http/availability";
import { parseDate } from "../../utils/date";
import styles from "./CalendarGrid.module.css";
import { InvalidDayReason, timeZone } from "./utils";

interface CalendarGridProps {
  state: RangeCalendarState;
  locale: string;
  firstDayOfWeek?: DayOfWeek | undefined;
  hideWeekdays?: boolean | undefined;
  offset?: DateDuration;
  minStay: number;
  isDayInvalid: (day: Date, arrivalDays: Date[]) => false | InvalidDayReason;
  isLoading?: boolean;
}

const CalendarGrid = ({
  state,
  locale,
  firstDayOfWeek,
  hideWeekdays = false,
  offset = {},
  minStay,
  isDayInvalid,
  isLoading = false,
}: CalendarGridProps) => {
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const calendarGridProps: AriaCalendarGridProps = {
    startDate,
    endDate,
    weekdayStyle: "short",
  };
  if (firstDayOfWeek) {
    calendarGridProps.firstDayOfWeek = firstDayOfWeek;
  }
  const { gridProps, headerProps, weekDays } = useCalendarGrid(
    calendarGridProps,
    state,
  );

  // arrival availabilities
  const {
    data: arrivalAvailabilities,
    dataMap: arrivalAvailabilitiesMap,
    isLoading: isArrivalLoading,
    isValidating: isArrivalValidating,
  } = useArrivalAvailabilities({
    start: startDate.toDate(timeZone),
    end: endDate.toDate(timeZone),
  });

  const arrivalDays = useMemo(
    () =>
      arrivalAvailabilities?.arrival_days.map((arrivalAvailabilityDay) =>
        parseDate(arrivalAvailabilityDay.date),
      ) ?? [],
    [arrivalAvailabilities],
  );

  const isDayInvalidMemoized = useCallback(
    (day: Date) => isDayInvalid(day, arrivalDays),
    [arrivalDays, isDayInvalid],
  );

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(startDate, locale, firstDayOfWeek);

  const monthDateFormatter = useDateFormatter({
    month: "long",
    year: "numeric",
  });

  const monthTitle = monthDateFormatter.format(
    startDate.toDate(state.timeZone),
  );

  return (
    <div className={styles.month} aria-label={monthTitle}>
      <div className={styles.captionWrapper}>
        <div className={styles.caption}>
          <span className={styles.captionLabel}>{monthTitle}</span>
        </div>
      </div>
      <table {...gridProps}>
        {!hideWeekdays && (
          <thead {...headerProps}>
            <tr>
              {weekDays.map((day, index) => (
                <th key={index}>{day}</th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {[...Array.from({ length: weeksInMonth }).keys()].map((weekIndex) => (
            <tr key={weekIndex}>
              {state
                .getDatesInWeek(weekIndex, startDate)
                .map((date, i) =>
                  date ? (
                    <CalendarCell
                      key={i}
                      isLoading={
                        isLoading || isArrivalLoading || isArrivalValidating
                      }
                      state={state}
                      date={date}
                      currentMonth={startDate}
                      arrivalAvailabilitiesMap={arrivalAvailabilitiesMap}
                      minStay={minStay}
                      isDayInvalid={isDayInvalidMemoized}
                    />
                  ) : (
                    <td key={i} />
                  ),
                )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CalendarGrid;
