import { CalendarDate, isSameDay, isSameMonth } from "@internationalized/date";
import { RangeCalendarState } from "@react-stately/calendar";
import clsx from "clsx";
import {
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useCalendarCell } from "react-aria";
import { useBookingStore } from "../../hooks/useBookingStore";
import { ArrivalAvailabilityDay } from "../../http/availability";
import { translate } from "../../i18n";
import Date from "../../ui/Date";
import Tooltip from "../../ui/Tooltip";
import { formatMoney } from "../../utils/number";
import styles from "./CalendarCell.module.css";
import { InvalidDayReason, InvalidDayReasonType, timeZone } from "./utils";

interface CalendarCellProps {
  state: RangeCalendarState;
  isLoading?: boolean;
  date: CalendarDate;
  currentMonth: CalendarDate;
  arrivalAvailabilitiesMap: Map<string, ArrivalAvailabilityDay>;
  minStay: number;
  isDayInvalid: (day: Date) => false | InvalidDayReason;
}

const CalendarCell = ({
  state,
  date,
  currentMonth,
  arrivalAvailabilitiesMap,
  isLoading = false,
  minStay,
  isDayInvalid,
}: CalendarCellProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const ref = useRef<HTMLButtonElement | null>(null);
  const {
    cellProps,
    buttonProps,
    isSelected,
    isOutsideVisibleRange,
    isDisabled,
    isUnavailable,
    formattedDate,
    isPressed,
  } = useCalendarCell({ date }, state, ref);

  const eventTracker = useRef({ valid: false, pressed: false });

  const isOutsideMonth = !isSameMonth(currentMonth, date);
  const isOutside = isOutsideVisibleRange || isOutsideMonth;

  const isSelectionStart = state.anchorDate
    ? isSameDay(date, state.anchorDate)
    : state.highlightedRange
      ? isSameDay(date, state.highlightedRange.start)
      : false;
  const isSelectionEnd = state.anchorDate
    ? false
    : state.highlightedRange
      ? isSameDay(date, state.highlightedRange.end)
      : false;

  const isSelectionStartOrEnd = isSelectionStart || isSelectionEnd;

  const arrivalAvailabilityDay = arrivalAvailabilitiesMap.get(date.toString());
  const price = arrivalAvailabilityDay?.price;

  const invalidReason = isDayInvalid(date.toDate(timeZone));

  const tooltipTitle = useMemo(() => {
    if (invalidReason) {
      switch (invalidReason.type) {
        case InvalidDayReasonType.ArrivalNotAvailable: {
          return i18n.calendar.arrivalNotAvailable;
        }
        case InvalidDayReasonType.MinStay: {
          return i18n.calendar.minStay(invalidReason.value);
        }
        case InvalidDayReasonType.MaxStay: {
          return i18n.calendar.maxStay(invalidReason.value);
        }
        case InvalidDayReasonType.DepartureNotAvailable: {
          return i18n.calendar.departureNotAvailable;
        }
        case InvalidDayReasonType.DepartureBeforeArrival: {
          return i18n.calendar.departureBeforeArrival;
        }
        default: {
          return "";
        }
      }
    }

    if (minStay > 0) {
      return i18n.calendar.minStay(minStay);
    }

    return "";
  }, [i18n.calendar, invalidReason, minStay]);

  const shouldHandleEvent = useCallback(
    (e: SyntheticEvent) => {
      const pressed = isPressed || eventTracker.current.pressed;
      const valid = pressed ? eventTracker.current.valid : !invalidReason;

      if (pressed && (!valid || !state.anchorDate)) {
        setTooltipVisible(true);
      }

      if (!valid) {
        e.preventDefault();
        e.stopPropagation();
      }

      eventTracker.current.valid = valid;
      return valid;
    },
    [isPressed, invalidReason, state.anchorDate],
  );

  // remove some events to handle the selection of unavailable/invalid dates manually
  const {
    onClick: _onClick,
    onMouseDown: _onMouseDown,
    onDragStart: _onDragStart,
    onPointerEnter: _onPointerEnter,
    // onPointerLeave: _onPointerLeave,
    onPointerDown,
    onPointerUp,
    onKeyDown,
    ...restButtonProps
  } = buttonProps;

  return (
    <td
      {...cellProps}
      className={clsx(styles.cell, {
        [styles.invalid]:
          !isLoading &&
          !!invalidReason &&
          (!isSelectionStartOrEnd || !!state.anchorDate),
        [styles.selected]:
          (isSelected && !state.anchorDate) || isSelectionStartOrEnd,
        [styles.disabled]: isDisabled,
        [styles.unavailable]: isUnavailable,
        [styles.range]:
          !state.anchorDate && isSelected && !isSelectionStartOrEnd,
        [styles.outside]: isOutside,
      })}
      aria-disabled={
        cellProps["aria-disabled"] ??
        (!isLoading &&
          !!invalidReason &&
          (!isSelectionStartOrEnd || !!state.anchorDate))
      }
    >
      <button
        type="button"
        {...restButtonProps}
        aria-disabled={
          restButtonProps["aria-disabled"] ??
          (!isLoading &&
            !!invalidReason &&
            (!isSelectionStartOrEnd || !!state.anchorDate))
        }
        onPointerDown={(e) => {
          if (shouldHandleEvent(e)) {
            onPointerDown?.(e);
          }
          eventTracker.current.pressed = true;
        }}
        onPointerUp={(e) => {
          if (shouldHandleEvent(e)) {
            onPointerUp?.(e);
          }
          eventTracker.current.pressed = false;
        }}
        onPointerCancel={() => {
          eventTracker.current.pressed = false;
        }}
        onKeyDown={(e) => {
          if (!isValidKeyboardEvent(e) || shouldHandleEvent(e)) {
            onKeyDown?.(e);
          }
        }}
        ref={ref}
        hidden={isOutside}
        className={styles.day}
      >
        <span className={styles.innerDay}>
          <Date
            className={styles.date}
            date={date.toDate(timeZone)}
            formatter={() => formattedDate}
          />
          {isLoading && !isSelectionStartOrEnd ? (
            <span className={styles.loading} />
          ) : (
            <span className={styles.price}>
              {price ? formatMoney(price, language) : "-"}
            </span>
          )}
        </span>
      </button>
      <Tooltip
        isVisible={tooltipVisible}
        anchor={ref.current}
        title={tooltipTitle}
        onClose={() => setTooltipVisible(false)}
        onIsPositionChange={(floating) => {
          floating.scrollIntoView({ block: "nearest", behavior: "smooth" });
        }}
      />
    </td>
  );
};

const isValidKeyboardEvent = (event: KeyboardEvent): boolean => {
  const { key, code } = event;
  // Accessibility for keyboards. Space and Enter only.
  // "Spacebar" is for IE 11
  return (
    key === "Enter" || key === " " || key === "Spacebar" || code === "Space"
  );
};

export default CalendarCell;
