import { Image } from "../@types";

interface ResponsiveImageProps {
  alt: string;
  className?: string;
  lazyLoad?: boolean;
  sizes: string;
  srcSet: Image[];
  srcSetPortrait?: Image[];
  title?: string;
}

const ResponsiveImage = ({
  alt,
  className,
  lazyLoad,
  sizes,
  srcSet,
  srcSetPortrait,
  title,
}: ResponsiveImageProps) => {
  const lastSrcset = srcSet[srcSet.length - 1];
  const img = (
    <img
      src={lastSrcset?.url}
      srcSet={srcSet.map((img) => `${img.url} ${img.width}w`).join(",")}
      sizes={sizes}
      alt={alt}
      title={title}
      className={className}
      width={lastSrcset?.width}
      height={lastSrcset?.height}
      loading={lazyLoad ? "lazy" : undefined}
    />
  );

  return srcSetPortrait ? (
    <picture>
      <source
        srcSet={srcSetPortrait
          .map((img) => `${img.url} ${img.width}w`)
          .join(", ")}
        media="(orientation: portrait)"
        sizes={sizes}
      />
      {img}
    </picture>
  ) : (
    img
  );
};

export default ResponsiveImage;
