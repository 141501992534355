import { Price } from "../@types";

export const maxRooms = 5;
export const maxAdultsAutoOccupancy = 99;
export const maxChildrenAutoOccupancy = 99;
export const maxChildrenAge = 17;

export const maxMonths = 24;
export const desktopNumberOfMonths = 2;
export const calendarMonthLoadingThreshold = 80;

export const maxExtraQuantity = 99;

export const defaultURLPattern = /^\/(?:[a-zA-Z]{2}\/?)?(?:\?.*)?$/;

export const staticRoomDetailPattern =
  /^\/[a-zA-Z]{2}\/room-types\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})\/?.*/;

export const bookingSuccessPattern = /^\/[a-z]{2}\/booking-success$/;

export const bookingCancellationPattern =
  /^\/[a-z]{2}\/bookings\/([0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12})\/?.*/;

export const zeroPrice: Price = { amount: 0, currency: "EUR" };

export const apiRequestDedupingIntervals = {
  // 20 seconds
  short: 20_000,
  // 5 minutes
  long: 5 * 60_000,
} as const;
