export const municipality = (
  municipality: string,
  postalCode: string,
  country: string,
) => {
  const municipalityParts = [];
  if (municipality) {
    municipalityParts.push(municipality);
  }
  if (postalCode) {
    municipalityParts.push(postalCode);
  }
  if (country) {
    municipalityParts.push(`(${country})`);
  }

  return municipalityParts.join(" ");
};

export enum CancellationReason {
  GuestUnableToStay = "guest_unable_to_stay",
  PropertyAskedToCancel = "property_asked_to_cancel",
  GuestChoseOtherDestination = "guest_chose_other_destination",
  GuestChoseOtherProperty = "guest_chose_other_property",
  Other = "other",
}

export interface BookingCancellationForm {
  cancellation_reason: CancellationReason | "";
  other_reason_text?: string;
}
