import { useCallback, useEffect, useState } from "react";
import { translate } from "../i18n";
import { site } from "../utils/site";
import { AlertType, useAlertStore } from "./useAlertStore";
import { useBookingStore } from "./useBookingStore";

export const useRequestFailedAlert = (requestName: string) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const addAlert = useAlertStore((state) => state.addAlert);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) {
      addAlert({
        type: AlertType.Warning,
        key: requestName,
        title: i18n.general.alerts.requestFailed.title(requestName),
        description:
          i18n.general.alerts.requestFailed.description[site.guest_interaction],
      });
    }

    return () => setShow(false);
  }, [addAlert, i18n.general.alerts.requestFailed, requestName, show]);

  return useCallback(() => {
    if (show) {
      return;
    }
    setShow(true);
  }, [show]);
};
