import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { ReactNode } from "react";
import { BackButton } from "./BackButton";
import styles from "./DetailModal.module.css";

interface DetailModalProps {
  open: boolean;
  onClose: () => void;
  title?: ReactNode;
  children?: ReactNode;
}

const DetailModal = ({ open, onClose, title, children }: DetailModalProps) => (
  <Dialog open={open} onClose={onClose} className={styles.detailModal}>
    <DialogPanel className={styles.dialog}>
      <div className={styles.innerDialog}>
        <DialogTitle as="div" className={styles.header}>
          {title ?? <BackButton onClick={onClose} />}
        </DialogTitle>
        {children}
      </div>
    </DialogPanel>
  </Dialog>
);

export default DetailModal;
