import "@fontsource-variable/inter";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import App from "./App";
import "./global.css";
import { genericErrorMessage, showGenericError } from "./utils/error";

const el = document.querySelector("#root");
if (!el) {
  showGenericError();
  throw new Error("#root element not found");
}

createRoot(el).render(
  <StrictMode>
    <ErrorBoundary fallback={genericErrorMessage}>
      <App />
    </ErrorBoundary>
  </StrictMode>,
);
