import { Fieldset, Legend } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";
import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Button from "../../ui/Button";
import ArrowDown from "../../ui/icon/arrow-down.svg?react";
import Trash from "../../ui/icon/trash.svg?react";
import styles from "./CollapsibleRoomOccupancy.module.css";

interface CollapsibleRoomOccupancyProps {
  open: boolean;
  index: number;
  onOpen: (index: number) => void;
  onDelete?: (() => void) | false;
  openText: string;
  toggleDisabled?: boolean;
  boldOpener?: boolean;
  summary?: string | undefined;
  hideActions?: boolean;
  children?: ReactNode;
}

const CollapsibleRoomOccupancy = ({
  open,
  index,
  onOpen,
  onDelete,
  openText,
  boldOpener = false,
  toggleDisabled = false,
  summary,
  hideActions = false,
  children,
}: CollapsibleRoomOccupancyProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <Fieldset className={styles.room}>
      <div className={styles.header}>
        <Button
          layout="link"
          buttonProps={{
            onClick: () => onOpen(index),
            className: clsx(styles.opener, {
              [styles.cursorDefault]: hideActions,
            }),
            disabled: toggleDisabled,
          }}
        >
          <Legend as="span">
            <span className={clsx({ [styles.bold]: open || boldOpener })}>
              {openText}
            </span>
            {summary && <span>{summary}</span>}
          </Legend>
        </Button>
        {!hideActions && (
          <div className={styles.actions}>
            {onDelete && (
              <Button
                layout="link"
                buttonProps={{
                  onClick: () => {
                    onDelete();
                  },
                  "aria-label": i18n.general.delete,
                  className: styles.deleteButton,
                }}
              >
                <Trash className={styles.deleteIcon} />
              </Button>
            )}
            <Button
              layout="icon"
              buttonProps={{
                onClick: () => onOpen(index),
                "aria-label": i18n.general.expand,
                className: clsx(styles.expandButton, {
                  [styles.expandButtonActive]: open,
                }),
                disabled: toggleDisabled,
              }}
              glyph={ArrowDown}
            />
          </div>
        )}
      </div>
      {open && children}
    </Fieldset>
  );
};

export default CollapsibleRoomOccupancy;
