import { AlertType, useAlertStore } from "../hooks/useAlertStore";
import { useBookingStore } from "../hooks/useBookingStore";
import { translate } from "../i18n";
import { site } from "../utils/site";
import Button, { ButtonProps } from "./Button";

type ShareButtonProps = ButtonProps & { shareData: ShareData };

const ShareButton = ({ shareData, ...restShareProps }: ShareButtonProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const addAlert = useAlertStore((state) => state.addAlert);

  // This is required, since not all browsers support the web share api, such as Firefox (only available if enabled in preferences)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!navigator.canShare?.(shareData)) {
    return null;
  }

  const { buttonProps, ...restButtonProps } = restShareProps;

  const share = async () => {
    try {
      await navigator.share(shareData);
    } catch {
      addAlert({
        type: AlertType.Warning,
        title: i18n.general.alerts.share.title,
        description:
          i18n.general.alerts.share.description[site.guest_interaction],
      });
    }
  };

  return (
    <Button
      {...restButtonProps}
      buttonProps={{
        ...buttonProps,
        onClick: () => {
          share();
        },
      }}
    />
  );
};

export default ShareButton;
