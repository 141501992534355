import { JSX, ReactNode } from "react";

interface TelLinkProps {
  anchorProps: Omit<JSX.IntrinsicElements["a"], "href" | "children">;
  children: ReactNode;
  phoneNumber: string;
}

const removeAllWhitespace = (value: string): string =>
  value.replaceAll(/\s+/g, "");

const TelLink = ({ anchorProps, children, phoneNumber }: TelLinkProps) => (
  <a href={`tel:${removeAllWhitespace(phoneNumber)}`} {...anchorProps}>
    {children}
  </a>
);

export default TelLink;
