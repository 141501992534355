import { Fragment, ReactNode } from "react";
import { ArrayPath, FieldArrayWithId, FieldValues } from "react-hook-form";
import styles from "./ChildrenAssignment.module.css";
import NumberInput from "./NumberInput";

interface ChildrenAssignmentProps<TFieldValues extends FieldValues> {
  title: ReactNode;
  description?: string;
  fields: FieldArrayWithId<TFieldValues, ArrayPath<TFieldValues>>[];
  min?: number;
  max: number;
  disabled?: boolean | undefined;
  className?: string;
  onIncrease: (value: number) => void;
  onDecrease: (value: number) => void;
  children: (childAgeProps: { index: number }) => ReactNode;
}

const ChildrenAssignment = <TFieldValues extends FieldValues>({
  title,
  description,
  fields,
  min = 0,
  max,
  disabled = false,
  className = "",
  onIncrease,
  onDecrease,
  children,
}: ChildrenAssignmentProps<TFieldValues>) => (
  <NumberInput
    className={className}
    title={title}
    description={description}
    value={fields.length}
    min={min}
    max={max}
    disabled={disabled}
    onChange={(value) =>
      value > fields.length ? onIncrease(value) : onDecrease(value)
    }
  >
    {fields.length > 0 && (
      <div className={styles.childrenAges}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>{children({ index })}</Fragment>
        ))}
      </div>
    )}
  </NumberInput>
);

export default ChildrenAssignment;
