import clsx from "clsx";
import { ElementType, ReactNode } from "react";
import { Photo } from "../@types";
import Button from "./Button";
import styles from "./CardImage.module.css";
import ResponsiveImage from "./ResponsiveImage";

interface CardImageProps {
  photo: Photo | undefined;
  sizes: string;
  alt: string;
  placeHolder?: ReactNode;
  button?: { title: string; onClick: () => void };
  badge: { icon: ElementType; text: string } | false;
  className?: string;
}

export const CardImage = ({
  photo,
  sizes,
  alt,
  className,
  placeHolder,
  button: buttonProps,
  badge: badgeProps,
}: CardImageProps) => {
  const image = photo ? (
    <ResponsiveImage
      srcSet={photo.derivatives}
      alt={alt}
      sizes={sizes}
      lazyLoad
      className={styles.image}
    />
  ) : null;

  const imageWrapper = buttonProps ? (
    <Button
      layout="link"
      buttonProps={{ ...buttonProps, className: styles.imageWrapper }}
    >
      {image}
    </Button>
  ) : (
    <div className={styles.imageWrapper}>{image}</div>
  );

  return (
    <div className={clsx(className, styles.cardImage)}>
      {photo ? imageWrapper : placeHolder}
      {badgeProps && (
        <div className={styles.badge}>
          <badgeProps.icon className={styles.badgeIcon} aria-hidden />
          {badgeProps.text}
        </div>
      )}
    </div>
  );
};
