import { useEffect, useState } from "react";
import { BookingStep } from "../../@types";
import {
  useBookingStore,
  useBookingStoreSetStep,
  validate,
} from "../../hooks/useBookingStore";
import { useRatePlans } from "../../http/ratePlan";
import { useRoomTypes } from "../../http/room";
import { translate } from "../../i18n";
import ErrorPage from "../../pages/Error";
import {
  bookingCancellationPattern,
  bookingSuccessPattern,
  defaultURLPattern,
  staticRoomDetailPattern,
} from "../../utils/constants";
import { site } from "../../utils/site";
import BookingCancellation from "../booking/BookingCancellation";
import BookingSuccess from "../booking/BookingSuccess";
import StaticRoomDetail from "../room/StaticRoomDetail";

const Init = () => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const ratePlanId = useBookingStore((state) => state.ratePlanId);
  const hydratedValuesValidated = useBookingStore(
    (state) => state.hydratedValuesValidated,
  );
  const setHydratedValuesValidated = useBookingStore(
    (state) => state.setHydratedValuesValidated,
  );
  const setStep = useBookingStoreSetStep();
  const [isValidating, setIsValidating] = useState(false);
  const [validationError, setValidationError] = useState<Error | null>(null);

  if (validationError) {
    throw validationError;
  }

  const { roomTypesMap, isLoading: roomTypesLoading } = useRoomTypes({
    skipFetch: !roomTypeId,
  });
  const { ratePlansMap, isLoading: ratePlansLoading } = useRatePlans({
    skipFetch: !ratePlanId,
  });

  const matchDefaultURLPattern = location.pathname.match(defaultURLPattern);

  const matchStaticRoomDetailPattern = location.pathname.match(
    staticRoomDetailPattern,
  );

  const matchBookingSuccessPattern = location.pathname.match(
    bookingSuccessPattern,
  );

  const matchBookingCancellationPattern = location.pathname.match(
    bookingCancellationPattern,
  );

  const shouldValidateBookingStore =
    !isValidating &&
    !hydratedValuesValidated &&
    !matchStaticRoomDetailPattern &&
    !matchBookingSuccessPattern &&
    !matchBookingCancellationPattern &&
    matchDefaultURLPattern &&
    !roomTypesLoading &&
    !ratePlansLoading;

  useEffect(() => {
    if (!shouldValidateBookingStore) {
      return;
    }

    const triggerValidate = async () => {
      try {
        const validationResult = await validate(roomTypesMap, ratePlansMap);
        const firstInvalidStepResult = validationResult.steps.find(
          (s) => !s.valid,
        );
        setHydratedValuesValidated(true);
        setStep(firstInvalidStepResult?.step ?? BookingStep.Start, true);
      } catch (error) {
        if (error instanceof Error) {
          setValidationError(error);
        }

        throw error;
      }
    };

    setIsValidating(true);
    triggerValidate();
  }, [
    ratePlansMap,
    roomTypesMap,
    setHydratedValuesValidated,
    setStep,
    shouldValidateBookingStore,
  ]);

  if (matchStaticRoomDetailPattern?.[1]) {
    return <StaticRoomDetail roomId={matchStaticRoomDetailPattern[1]} />;
  }

  if (matchBookingSuccessPattern) {
    return <BookingSuccess />;
  }

  if (matchBookingCancellationPattern?.[1]) {
    return (
      <BookingCancellation bookingId={matchBookingCancellationPattern[1]} />
    );
  }

  if (!matchDefaultURLPattern) {
    // 404
    return (
      <ErrorPage
        title={i18n.general.notFoundPage.title[site.guest_interaction]}
        description={
          i18n.general.notFoundPage.description[site.guest_interaction]
        }
        buttonTitle={i18n.general.notFoundPage.button}
        buttonOnClick={() => {
          setStep(BookingStep.Start);
          history.replaceState(history.state, "", `/${language}`);
        }}
      />
    );
  }

  return null;
};

export default Init;
