import { formatISO } from "date-fns";

interface DateProps {
  className?: string;
  date: Date;
  formatter: (date: Date) => string;
}

const Date = ({ className, date, formatter }: DateProps) => (
  <time
    className={className}
    dateTime={formatISO(date, { representation: "date" })}
  >
    {formatter(date)}
  </time>
);

export default Date;
