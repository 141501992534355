import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode, useState } from "react";
import { Controller } from "react-hook-form";
import { WretchError } from "wretch/resolver";
import { useBookingStore } from "../../hooks/useBookingStore";
import { BookingRoom, cancelBooking, useBooking } from "../../http/booking";
import { translate } from "../../i18n";
import Error, { ErrorType } from "../../pages/Error";
import Button from "../../ui/Button";
import Date from "../../ui/Date";
import Form from "../../ui/Form";
import FormError from "../../ui/FormError";
import Headline from "../../ui/Headline";
import Radio from "../../ui/Radio";
import ResponsiveImage from "../../ui/ResponsiveImage";
import Skeleton from "../../ui/Skeleton";
import TelLink from "../../ui/TelLink";
import Textarea from "../../ui/Textarea";
import TimeRange from "../../ui/TimeRange";
import {
  formatIntlDate,
  formatIntlTimeToRangeParts,
  parseDate,
} from "../../utils/date";
import { formatMoney } from "../../utils/number";
import { site } from "../../utils/site";
import { getURLHost, sanitize } from "../../utils/string";
import styles from "./BookingCancellation.module.css";
import BookingCancellationModal from "./BookingCancellationModal";
import {
  BookingCancellationForm,
  CancellationReason,
  municipality,
} from "./utils";

interface BookingCancellationProps {
  bookingId: string;
}

const BookingCancellation = ({ bookingId }: BookingCancellationProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { data: booking, error, isLoading } = useBooking(bookingId);
  const [modalOpen, setModalOpen] = useState(false);
  const [cancellationText, setCancellationText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedFormData, setSubmittedFormData] =
    useState<BookingCancellationForm>();

  if (error || (!booking && !isLoading)) {
    return <Error type={ErrorType.Warning} />;
  }

  const confirmCancelBooking = async () => {
    if (!submittedFormData?.cancellation_reason) {
      return;
    }

    setIsSubmitting(true);

    try {
      await cancelBooking({
        cancellation_reason: submittedFormData.cancellation_reason,
        other_reason_text:
          submittedFormData.cancellation_reason === CancellationReason.Other
            ? submittedFormData.other_reason_text
            : undefined,
      });
      setCancellationText(i18n.booking.cancellation.success);
    } catch (error) {
      if (error instanceof WretchError && error.status === 409) {
        setCancellationText(i18n.booking.cancellation.alreadyCancelled);
        return;
      }
      setCancellationText(
        i18n.booking.cancellation.error[site.guest_interaction],
      );
    } finally {
      setModalOpen(false);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {modalOpen && booking && (
        <BookingCancellationModal
          isSubmitting={isSubmitting}
          text={i18n.booking.cancellation.modal.text[site.guest_interaction](
            booking.days_to_arrival,
            formatMoney(booking.cancellation_fee, language),
          )}
          onClose={() => setModalOpen(false)}
          onConfirm={() => {
            confirmCancelBooking();
          }}
        />
      )}
      <header className={styles.header}>
        <img
          className={styles.logo}
          alt={site.property_name}
          title={site.property_name}
          src={site.logo_dark.url}
          width={site.logo_dark.width}
          height={site.logo_dark.height}
        />
      </header>
      {isLoading && <Skeleton desktopItems={1} mobileItems={1} />}
      {booking && (
        <div className={styles.content}>
          <div className={styles.headline}>
            {i18n.booking.cancellation.headline(booking.booking_code)}
          </div>
          {cancellationText && (
            <Headline
              title={cancellationText}
              size={3}
              className={styles.successHeadline}
            />
          )}
          {!cancellationText && (
            <>
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.personalData}
              </div>
              <div className={clsx(styles.table, styles.center)}>
                <Row
                  label={i18n.booking.cancellation.firstName}
                  value={booking.guest.first_name}
                />
                <Row
                  label={i18n.booking.cancellation.lastName}
                  value={booking.guest.last_name}
                />
                <Row
                  label={i18n.booking.cancellation.municipality}
                  value={municipality(
                    booking.guest.municipality ?? "",
                    booking.guest.postal_code ?? "",
                    booking.guest.country ?? "",
                  )}
                />
                <Row
                  label={i18n.booking.cancellation.phone}
                  value={
                    booking.guest.phone_number && (
                      <TelLink
                        phoneNumber={booking.guest.phone_number}
                        anchorProps={{ className: styles.link }}
                      >
                        {booking.guest.phone_number}
                      </TelLink>
                    )
                  }
                />
                <Row
                  label={i18n.booking.cancellation.street}
                  value={booking.guest.street}
                />
                <Row
                  label={i18n.booking.cancellation.email}
                  value={
                    <a
                      href={`mailto:${booking.guest.email_address}`}
                      className={styles.link}
                    >
                      {booking.guest.email_address}
                    </a>
                  }
                />
              </div>
              {booking.guest.company && (
                <>
                  <div className={styles.blockHeadline}>
                    {i18n.booking.cancellation.companyData}
                  </div>
                  <div className={clsx(styles.table, styles.center)}>
                    <Row
                      label={i18n.booking.cancellation.companyName}
                      value={booking.guest.company.name}
                    />
                    <Row
                      label={i18n.booking.cancellation.vatNumber}
                      value={booking.guest.company.vat_number}
                    />
                    <Row
                      label={i18n.booking.cancellation.recipientCode}
                      value={booking.guest.company.recipient_code}
                    />
                    <Row
                      label={i18n.booking.cancellation.municipality}
                      value={municipality(
                        booking.guest.company.municipality ?? "",
                        booking.guest.company.postal_code ?? "",
                        booking.guest.company.country ?? "",
                      )}
                    />
                  </div>
                </>
              )}
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.propertyData}
              </div>
              <div className={clsx(styles.table, styles.center)}>
                <Row
                  label={i18n.booking.cancellation.property}
                  value={site.property_name}
                />
                <Row
                  label={i18n.booking.cancellation.street}
                  value={site.address.street}
                />
                <Row
                  label={i18n.booking.cancellation.phone}
                  value={
                    <TelLink
                      phoneNumber={site.phone_number}
                      anchorProps={{ className: styles.link }}
                    >
                      {site.phone_number}
                    </TelLink>
                  }
                />
                <Row
                  label={i18n.booking.cancellation.municipality}
                  value={municipality(
                    site.address.municipality,
                    site.address.postal_code,
                    site.address.province,
                  )}
                />
                <Row
                  label={i18n.booking.cancellation.email}
                  value={
                    <a href={`mailto:${site.email}`} className={styles.link}>
                      {site.email}
                    </a>
                  }
                />
                <Row
                  label={i18n.booking.cancellation.website}
                  value={
                    <a href={site.website_url} className={styles.link}>
                      {getURLHost(site.website_url)}
                    </a>
                  }
                />
              </div>
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.bookingDetails}
              </div>
              <div className={clsx(styles.table, styles.center)}>
                <Row
                  label={i18n.booking.cancellation.arrivalDeparture}
                  value={
                    <div>
                      <Date
                        date={parseDate(booking.arrival)}
                        formatter={formatIntlDate(language)}
                      />
                      {" – "}
                      <Date
                        date={parseDate(booking.departure)}
                        formatter={formatIntlDate(language)}
                      />
                    </div>
                  }
                />
                <Row
                  label={i18n.booking.cancellation.nights}
                  value={booking.nights_count}
                />
                <Row
                  label={i18n.booking.cancellation.checkIn}
                  value={
                    <TimeRange
                      start={site.check_in.from}
                      end={site.check_in.to}
                      rangePartsFormatter={formatIntlTimeToRangeParts(language)}
                    />
                  }
                />
                <Row
                  label={i18n.booking.cancellation.checkOut}
                  value={
                    <TimeRange
                      start={site.check_out.from}
                      end={site.check_out.to}
                      rangePartsFormatter={formatIntlTimeToRangeParts(language)}
                    />
                  }
                />
              </div>
              <div className={clsx(styles.rooms, styles.center)}>
                {booking.rooms.map((room, index) => (
                  <Room key={index} room={room} />
                ))}
              </div>
              {booking.extras.length > 0 && (
                <>
                  <div className={styles.blockHeadline}>
                    {i18n.booking.cancellation.extras}
                  </div>
                  <ul className={styles.extras}>
                    {booking.extras.map((extra, index) => (
                      <li key={index}>{extra.title}</li>
                    ))}
                  </ul>
                </>
              )}
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.priceOverview}
              </div>
              <div className={clsx(styles.prices, styles.center)}>
                <div className={styles.price}>
                  <div className={styles.priceLabel}>
                    {i18n.booking.cancellation.totalWithoutVat}
                  </div>
                  {formatMoney(
                    booking.total_prices.total_without_vat,
                    language,
                  )}
                </div>
                <div className={styles.price}>
                  <div className={styles.priceLabel}>
                    {i18n.booking.cancellation.touristTax}
                  </div>
                  {formatMoney(booking.total_prices.tourist_tax, language)}
                </div>
                <div className={clsx(styles.totalPrice, styles.price)}>
                  <div className={styles.priceLabel}>
                    {i18n.booking.cancellation.totalPrice}
                  </div>
                  {formatMoney(booking.total_prices.total, language)}
                </div>
              </div>
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.paymentOptions}
              </div>
              <div className={styles.center}>
                <div>
                  {
                    i18n.booking.cancellation.prepaymentMethod[
                      booking.payment.prepayment_method
                    ]
                  }
                </div>
                <div className={styles.touristTaxInfo}>
                  <div className={styles.touristTaxHeadline}>
                    {i18n.booking.cancellation.touristTaxHeadline}
                  </div>
                  <div>{i18n.booking.cancellation.touristTaxText}</div>
                </div>
              </div>
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.cancellationPolicies}
              </div>
              <div className={styles.center}>
                {
                  i18n.booking.cancellation.cancellationPoliciesText[
                    site.guest_interaction
                  ]
                }
                <div
                  className={styles.cancellationPolicies}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(booking.cancellation_policies),
                  }}
                />
              </div>
              <div className={styles.blockHeadline}>
                {i18n.booking.cancellation.cancelBooking}
              </div>
              <div className={styles.center}>
                <Form<BookingCancellationForm>
                  defaultValues={{
                    cancellation_reason: "",
                    other_reason_text: "",
                  }}
                  onSubmit={(data) => {
                    setSubmittedFormData(data);
                    setModalOpen(true);
                  }}
                >
                  {({ control, register, watch, formState }) => (
                    <>
                      <Controller
                        control={control}
                        name="cancellation_reason"
                        rules={{ required: i18n.general.form.errors.required }}
                        render={({ field, fieldState }) => (
                          <>
                            <RadioGroup
                              className={styles.reasons}
                              {...field}
                              aria-required
                              aria-invalid={!!fieldState.error}
                              aria-errormessage={
                                fieldState.error
                                  ? "cancellation_reason_error"
                                  : undefined
                              }
                            >
                              <Radio
                                label={
                                  i18n.booking.cancellation.reason[
                                    CancellationReason.GuestUnableToStay
                                  ]
                                }
                                value={CancellationReason.GuestUnableToStay}
                              />
                              <Radio
                                label={
                                  i18n.booking.cancellation.reason[
                                    CancellationReason.PropertyAskedToCancel
                                  ]
                                }
                                value={CancellationReason.PropertyAskedToCancel}
                              />
                              <Radio
                                label={
                                  i18n.booking.cancellation.reason[
                                    CancellationReason
                                      .GuestChoseOtherDestination
                                  ]
                                }
                                value={
                                  CancellationReason.GuestChoseOtherDestination
                                }
                              />
                              <Radio
                                label={
                                  i18n.booking.cancellation.reason[
                                    CancellationReason.GuestChoseOtherProperty
                                  ]
                                }
                                value={
                                  CancellationReason.GuestChoseOtherProperty
                                }
                              />
                              <Radio
                                label={
                                  i18n.booking.cancellation.reason[
                                    CancellationReason.Other
                                  ]
                                }
                                value={CancellationReason.Other}
                              />
                            </RadioGroup>
                            <FormError
                              id="cancellation_reason_error"
                              errorMessage={fieldState.error?.message ?? ""}
                            />
                          </>
                        )}
                      />
                      {watch("cancellation_reason") ===
                        CancellationReason.Other && (
                        <Textarea
                          label={i18n.booking.cancellation.note}
                          isEmpty={!watch("other_reason_text")}
                          className={styles.note}
                          {...register("other_reason_text", {
                            required: {
                              value:
                                watch("cancellation_reason") ===
                                CancellationReason.Other,
                              message: i18n.general.form.errors.required,
                            },
                          })}
                        />
                      )}
                      <Button
                        buttonProps={{
                          disabled: !formState.isValid,
                          type: "submit",
                          className: styles.submitButton,
                        }}
                      >
                        {i18n.booking.cancellation.cancel}
                      </Button>
                    </>
                  )}
                </Form>
              </div>
            </>
          )}
        </div>
      )}
      <footer className={styles.footer}>
        <img
          className={styles.logo}
          alt={site.property_name}
          title={site.property_name}
          src={site.logo_light.url}
          width={site.logo_light.width}
          height={site.logo_light.height}
        />
        <div className={styles.headline}>
          {i18n.booking.cancellation.footerHeadline[site.guest_interaction]}
        </div>
        <div className={styles.propertyData}>
          <div>{site.property_name}</div>
          <div className={styles.space} />
          <TelLink
            phoneNumber={site.phone_number}
            anchorProps={{ className: styles.link }}
          >
            {site.phone_number}
          </TelLink>
          <div className={styles.space} />
          <a href={`mailto:${site.email}`} className={styles.link}>
            {site.email}
          </a>
          <div className={styles.space} />
          <a
            className={styles.link}
            href={site.website_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {getURLHost(site.website_url)}
          </a>
        </div>
        <div className={styles.propertyData}>
          <div>{site.address.street}</div>
          <div className={styles.space} />
          <div>{`${site.address.municipality} ${site.address.postal_code} ${site.address.province ? `(${site.address.province})` : ""}`}</div>
        </div>
      </footer>
    </div>
  );
};

interface RowProps {
  label: string;
  value: ReactNode | undefined;
}

const Row = ({ label, value }: RowProps) => {
  if (!value) {
    return null;
  }

  return (
    <div className={styles.row}>
      <div className={styles.label}>{label}</div>
      {value}
    </div>
  );
};

interface RoomProps {
  room: BookingRoom;
}

const Room = ({ room }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const childrenCount = room.occupancy.children?.length ?? 0;
  const childrenAges = room.occupancy.children?.join(", ");

  return (
    <div className={styles.room}>
      <ResponsiveImage
        lazyLoad
        className={styles.roomImage}
        srcSet={room.photo.derivatives}
        alt={room.photo.description}
        sizes="(min-width: 1125px) 350px, (min-width: 875px) 803px, 100vw"
      />
      <div className={styles.roomContent}>
        <Headline title={room.room_type_name} size={2} />
        <div className={styles.roomContentBlock}>
          <Headline title={i18n.booking.cancellation.board} size={3} />
          <div>{i18n.boards[room.board_type]}</div>
        </div>
        <div className={styles.roomContentBlock}>
          <Headline title={i18n.booking.cancellation.guests} size={3} />
          <div>
            {`${i18n.booking.cancellation.adultsCount(room.occupancy.adults)}${childrenCount > 0 ? ` | ${i18n.booking.cancellation.childrenCount(childrenCount)} (${childrenAges})` : ""}`}
          </div>
        </div>
        <div className={styles.roomContentBlock}>
          <Headline title={i18n.booking.cancellation.extras} size={3} />
          <ul className={styles.roomExtras}>
            {room.extras.map((extra, index) => (
              <li key={index}>{extra.title}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BookingCancellation;
