import { useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { Offer, useOffers } from "../../http/offer";
import { Room as RoomType, useRoomTypes } from "../../http/room";
import { translate } from "../../i18n";
import Error from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import Button from "../../ui/Button";
import { Card } from "../../ui/Card";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import PhotoSlider from "../../ui/PhotoSlider";
import Prices from "../../ui/Prices";
import Skeleton from "../../ui/Skeleton";
import { site } from "../../utils/site";
import PortalPrices from "./PortalPrices";
import RoomInfos from "./RoomInfos";
import styles from "./Rooms.module.css";
import RoomsSort from "./RoomsSort";
import { OfferRoomOrderCriterion, sortOffersWithRooms } from "./utils";

const Rooms = () => {
  const language = useBookingStore((state) => state.language);
  const roomTypeId = useBookingStore((state) => state.roomTypeId);
  const roomConfigurations = useBookingStore(
    (state) => state.roomConfigurations,
  );
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );
  const i18n = translate(language);
  const { offers, offersMap, isLoading: isLoadingOffers } = useOffers();
  const { roomTypesMap, isLoading: isLoadingRoomTypes } = useRoomTypes();
  const [orderCriterion, setOrderCriterion] =
    useState<OfferRoomOrderCriterion>();
  const setStep = useBookingStoreSetStep();

  if (!currentRoomConfiguration) {
    return <Error />;
  }

  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];

  if (!isLoadingOffers && offer && currentRoomConfiguration.roomTypeId) {
    setStep(BookingStep.RatePlans, true);
  }

  const offersAndRooms = sortOffersWithRooms(
    offers
      ?.filter(
        (offer) =>
          offer.room_index === currentRoomConfiguration.roomIndex &&
          (!roomTypeId ||
            offer.room_type_id === roomTypeId ||
            roomConfigurations.some(
              (roomConfiguration) =>
                roomConfiguration.roomTypeId === offer.room_type_id,
            )),
      )
      .map((offer) => {
        const room = roomTypesMap?.[offer.room_type_id];

        return room ? { offer, room } : null;
      })
      .filter((x) => !!x) ?? [],
    orderCriterion,
  );

  return (
    <PageWithHeader
      header={
        <>
          <Headline
            as="h1"
            size={2}
            title={i18n.rooms.selectRoom[site.guest_interaction](
              site.room_term,
            )}
          />
          <RoomsSort
            orderCriterion={orderCriterion}
            onSort={setOrderCriterion}
          />
        </>
      }
    >
      <CenterWrapper>
        <CurrentRoomConfiguration
          roomConfiguration={currentRoomConfiguration}
        />
        <PortalPrices />
        <div className={styles.rooms}>
          {(isLoadingOffers || isLoadingRoomTypes) && <Skeleton />}
          {offersAndRooms.map((offerAndRoom, index) => (
            <Room
              key={index}
              room={offerAndRoom.room}
              offer={offerAndRoom.offer}
            />
          ))}
        </div>
      </CenterWrapper>
    </PageWithHeader>
  );
};

interface RoomProps {
  room: RoomType;
  offer: Offer;
}

const Room = ({ room, offer }: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const i18n = translate(language);
  const setStep = useBookingStoreSetStep();

  const toggleDetailView = () => {
    updateRoomConfiguration({ roomTypeId: room.id });
    setStep(BookingStep.RatePlans);
  };

  return (
    <Card
      header={<RoomHeader room={room} availableRooms={offer.available_rooms} />}
      footer={
        <Button buttonProps={{ onClick: () => toggleDetailView() }}>
          {i18n.rooms.continue}
        </Button>
      }
    >
      <Button
        layout="link"
        buttonProps={{
          onClick: () => toggleDetailView(),
          className: styles.roomName,
        }}
      >
        {room.name}
      </Button>
      <RoomInfos
        room={room}
        prices={
          <Prices
            basePrice={offer.cheapest_prices.base}
            minPrice={offer.cheapest_prices.min}
            minPricePerNight={offer.cheapest_prices.min_per_night}
            discount={offer.cheapest_prices.discount}
          />
        }
      />
    </Card>
  );
};

interface RoomHeaderProps {
  room: RoomType;
  availableRooms: number | null;
}

const RoomHeader = ({ room, availableRooms }: RoomHeaderProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  return (
    <div className={styles.roomHeader}>
      {!!availableRooms && (
        <div className={styles.availability}>
          {i18n.rooms.availableRooms(availableRooms)}
        </div>
      )}
      <PhotoSlider
        photos={room.photos}
        imageSizes="(min-width: 1125px) 341px, (min-width: 933px) 33vw, (min-width: 626px) 50vw, 100vw"
        arrows={false}
        className={styles.roomHeaderSlider}
      />
    </div>
  );
};

interface CurrentRoomConfigurationProps {
  roomConfiguration: RoomConfiguration;
}

const CurrentRoomConfiguration = ({
  roomConfiguration,
}: CurrentRoomConfigurationProps) => {
  const { adults = 0, children = [], roomIndex = 0 } = roomConfiguration;
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const visualIndex = roomIndex + 1;

  return (
    <div className={styles.currentConfiguration}>
      <Headline
        size={3}
        title={i18n.rooms.roomIndex(site.room_term, visualIndex)}
      />
      <div>
        {adults && i18n.roomConfigurations.adults(adults)}
        {children.length > 0 &&
          `, ${i18n.roomConfigurations.children(children.length)}`}
      </div>
    </div>
  );
};

export default Rooms;
