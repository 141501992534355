/* eslint css-modules/no-unused-class: ["error", { markAsUsed: ["size1", "size2", "size3", "size4", "size5", "size6", "size7"] }] */

import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./Headline.module.css";

interface HeadlineProps {
  title: ReactNode;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "div" | "span";
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  className?: string;
}

const Headline = ({
  title,
  as: Tag = "div",
  size = 1,
  className,
}: HeadlineProps) => (
  <Tag className={clsx(className, styles[`size${size}`])}>{title}</Tag>
);

export default Headline;
