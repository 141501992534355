import { Field, Radio as HeadlessRadio, Label } from "@headlessui/react";
import styles from "./Radio.module.css";

interface RadioProps {
  label: string;
  value: string | number;
  className?: string;
}

const Radio = ({ label, value, className, ...rest }: RadioProps) => (
  <Field className={className}>
    <Label
      className={styles.label}
      // TODO: HeadlessUI currently has a bug that generates an invalid for attribute. We hope this issue will be resolved soon
    >
      <HeadlessRadio {...rest} className={styles.radio} value={value} />
      {label}
    </Label>
  </Field>
);

export default Radio;
