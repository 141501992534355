import { create } from "zustand";

interface ButtonGroupStoreState {
  arrival: Date | null;
  departure: Date | null;
}

interface ButtonGroupStoreActions {
  setArrival: (arrival: Date | null) => void;
  setDeparture: (departure: Date | null) => void;
  reset: () => void;
}

type ButtonGroupStore = ButtonGroupStoreState & ButtonGroupStoreActions;

const defaultState: ButtonGroupStoreState = { arrival: null, departure: null };

export const useButtonGroupStore = create<ButtonGroupStore>()((set) => ({
  ...defaultState,
  setArrival: (arrival: Date | null) => set(() => ({ arrival })),
  setDeparture: (departure: Date | null) => set(() => ({ departure })),
  reset: () => set(() => defaultState),
}));
