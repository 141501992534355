/* eslint css-modules/no-undef-class: "off" */

import { Field } from "@headlessui/react";
import clsx from "clsx";
import {
  AnimationEvent,
  AnimationEventHandler,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useId,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import FormError from "./FormError";
import styles from "./FormField.module.css";
import { FormLabel } from "./FormLabel";

interface FormFieldChildrenProps {
  inputClassName: string;
  focused: boolean;
  setFocused: Dispatch<SetStateAction<boolean>>;
  autoFilled: boolean;
  setAutoFilled: Dispatch<SetStateAction<boolean>>;
  errorId: string;
  onAnimationStart: AnimationEventHandler;
}

interface FormFieldProps {
  label: string;
  required?: boolean | undefined;
  isEmpty?: boolean | undefined;
  className?: string | undefined;
  error?: FieldError | undefined;
  children: (childrenProps: FormFieldChildrenProps) => ReactNode;
}

const FormField = ({
  label,
  required,
  isEmpty,
  className,
  error,
  children,
}: FormFieldProps) => {
  const [focused, setFocused] = useState(false);
  const [autoFilled, setAutoFilled] = useState(false);
  const errorId = useId();

  const onAnimationStart = useCallback(
    (e: AnimationEvent) => {
      switch (e.animationName) {
        case styles["on-auto-fill-start"]: {
          setAutoFilled(true);
          break;
        }

        case styles["on-auto-fill-cancel"]: {
          setAutoFilled(false);
          break;
        }
      }
    },
    [setAutoFilled],
  );

  return (
    <Field
      className={clsx(styles.formFieldWrapper, className, {
        [styles.isInvalid]: !!error,
      })}
    >
      <FormLabel
        className={clsx(styles.label, {
          [styles.focused]: focused,
          [styles.filled]: !isEmpty || autoFilled,
        })}
        required={required}
      >
        {label}
      </FormLabel>
      {children({
        inputClassName: styles.input,
        focused,
        setFocused,
        autoFilled,
        setAutoFilled,
        errorId,
        onAnimationStart,
      })}

      <FormError id={errorId} errorMessage={error?.message ?? ""} />
    </Field>
  );
};

export default FormField;
