import clsx from "clsx";
import useMediaQuery from "../hooks/useMediaQuery";
import styles from "./Skeleton.module.css";

interface SkeletonProps {
  desktopItems?: number;
  mobileItems?: number;
  className?: string;
  layout?: "grid" | "single";
}

const Skeleton = ({
  desktopItems = 6,
  mobileItems = 3,
  className,
  layout = "grid",
}: SkeletonProps) => {
  const isDesktop = useMediaQuery("(min-width: 1125px)");

  return Array.from({ length: isDesktop ? desktopItems : mobileItems }).map(
    (_, index) => (
      <div key={index} className={clsx(styles.skeleton, className)}>
        <div
          className={clsx(styles.rect, styles.rect1, {
            [styles.single]: layout === "single",
          })}
        />
        {layout === "grid" && (
          <>
            <div className={clsx(styles.rect, styles.rect2)} />
            <div className={clsx(styles.rect, styles.rect3)} />
            <div className={clsx(styles.rect, styles.rect4)} />
          </>
        )}
      </div>
    ),
  );
};

export default Skeleton;
