import { useLayoutEffect, useState } from "react";

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(
    () => globalThis.matchMedia(query).matches,
  );

  useLayoutEffect(() => {
    const matchMedia = globalThis.matchMedia(query);
    setMatches(matchMedia.matches);

    const handleChange = ({ matches }: MediaQueryListEvent) => {
      setMatches(matches);
    };

    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
};

export default useMediaQuery;
