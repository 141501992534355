import { useBookingStore } from "../../hooks/useBookingStore";
import { translate } from "../../i18n";
import Headline from "../../ui/Headline";
import Switch from "../../ui/Switch";
import { site } from "../../utils/site";
import ExtraControlledQuantityField from "./ExtraControlledQuantityField";
import styles from "./ExtraPerRoom.module.css";
import { useQuantitiesFormFields } from "./utils";

const ExtraPerRoom = () => {
  const checkoutData = useBookingStore((state) => state.checkoutData);
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const { control, setValue, fields } = useQuantitiesFormFields();

  const allChecked = fields.every((field) => field.quantity === 1);
  const toggleInputs = () => {
    fields.forEach((_field, index) => {
      setValue(`quantities.${index}.quantity`, allChecked ? 0 : 1);
    });
  };

  return (
    <>
      {fields.length > 1 && (
        <div className={styles.switchAll}>
          <div>{i18n.extras.allRooms}</div>
          <Switch checked={allChecked} onChange={() => toggleInputs()} />
        </div>
      )}
      <div className={styles.rooms}>
        {fields.map((quantity, index) => {
          const room = checkoutData?.rooms[index];
          if (!room) {
            return null;
          }

          return (
            <ExtraControlledQuantityField
              key={quantity.id}
              control={control}
              index={index}
              render={({ field: { value } }) => (
                <div className={styles.room}>
                  <div className={styles.headlineWrapper}>
                    <Headline
                      size={2}
                      title={i18n.extras.room(index + 1, site.room_term)}
                    />
                    <div className={styles.roomName}>{room.name}</div>
                  </div>
                  <Switch
                    checked={value === 1}
                    onChange={(checked) =>
                      setValue(
                        `quantities.${index}.quantity`,
                        checked ? 1 : 0,
                        {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        },
                      )
                    }
                  />
                </div>
              )}
            />
          );
        })}
      </div>
    </>
  );
};

export default ExtraPerRoom;
