import { useBookingStore } from "../../hooks/useBookingStore";
import { useBooking } from "../../http/booking";
import { translate } from "../../i18n";
import Error, { ErrorType } from "../../pages/Error";
import StaticPage from "../../pages/StaticPage";
import Button from "../../ui/Button";
import CenterWrapper from "../../ui/CenterWrapper";
import Headline from "../../ui/Headline";
import Insurance from "../../ui/icon/insurance.svg?react";
import Party from "../../ui/icon/party.svg?react";
import Skeleton from "../../ui/Skeleton";
import { site } from "../../utils/site";
import styles from "./BookingSuccess.module.css";

const BookingSuccess = () => {
  const searchParams = new URLSearchParams(globalThis.location.search);
  const bookingId = searchParams.get("booking_id");
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { data: booking, error, isLoading } = useBooking(bookingId ?? "");

  if (error || (!booking && !isLoading)) {
    return <Error type={ErrorType.Warning} />;
  }

  return (
    <StaticPage>
      {isLoading && <Skeleton desktopItems={1} mobileItems={1} />}
      {booking && (
        <>
          <div className={styles.content}>
            <Party className={styles.icon} aria-hidden />
            <Headline
              size={2}
              title={i18n.booking.success.headline}
              className={styles.headline}
            />
            <Headline
              size={4}
              title={i18n.booking.success.text[site.guest_interaction](
                booking.guest.email_address,
              )}
              className={styles.text}
            />
          </div>
          <CenterWrapper>
            <div className={styles.insuranceBlock}>
              <Insurance className={styles.insuranceIcon} aria-hidden />
              <Headline
                size={2}
                title={
                  i18n.booking.success.insuranceHeadline[site.guest_interaction]
                }
                className={styles.insuranceHeadline}
              />
              <Headline
                size={4}
                title={
                  i18n.booking.success.insuranceText[site.guest_interaction]
                }
                className={styles.insuranceText}
              />
              <Button
                buttonProps={{
                  // TODO: Implement insurance logic
                  onClick: () => alert("TODO: Implement insurance logic"),
                  className: styles.insuranceButton,
                }}
              >
                {i18n.booking.success.insuranceButtonText}
              </Button>
            </div>
          </CenterWrapper>
        </>
      )}
    </StaticPage>
  );
};

export default BookingSuccess;
