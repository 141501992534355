import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, Ref, useFieldArray } from "react-hook-form";
import { useBookingStore } from "../../hooks/useBookingStore";
import {
  isModalActive,
  selectModalIdentifierValue,
  useModalHistoryStore,
  useModalHistoryToggle,
} from "../../hooks/useModalHistory";
import { Room, useRoomTypes } from "../../http/room";
import { translate } from "../../i18n";
import { ActionFooter } from "../../ui/ActionFooter";
import Button from "../../ui/Button";
import CheckboxButton from "../../ui/CheckboxButton";
import Headline from "../../ui/Headline";
import Children from "../../ui/icon/children.svg?react";
import Guests from "../../ui/icon/guests.svg?react";
import Plus from "../../ui/icon/plus.svg?react";
import { MobileModalContent } from "../../ui/MobileModal";
import NumberInput from "../../ui/NumberInput";
import Tooltip from "../../ui/Tooltip";
import { maxRooms } from "../../utils/constants";
import { site } from "../../utils/site";
import CollapsibleRoomOccupancy from "../occupancy/CollapsibleRoomOccupancy";
import RoomDetailModal from "../room/RoomDetailModal";
import RoomInfos from "../room/RoomInfos";
import styles from "./IndividualAssignmentForm.module.css";
import {
  getOccupancyLimits,
  getRoomConfigurationError,
  getUnassignedOccupancy,
  scrollToFirstError,
  useIndividualAssignmentFormContext,
} from "./utils";

const setValueOptions = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true,
};

const IndividualAssignmentForm = () => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const totalAdultsCount = autoOccupancy?.adults ?? 0;
  const totalChildrenCount = autoOccupancy?.children.length ?? 0;
  const [openedRoomIndex, setOpenedRoomIndex] = useState(0);
  const [addRoomButtonTooltipVisible, setAddRoomButtonTooltipVisible] =
    useState(false);
  const [applyButtonTooltipVisible, setApplyButtonTooltipVisible] =
    useState(false);
  const addRoomRef = useRef<HTMLButtonElement | null>(null);
  const applyRef = useRef<HTMLButtonElement | null>(null);
  const i18n = translate(language);
  const { control, formState, register, setValue, trigger, watch } =
    useIndividualAssignmentFormContext();
  const [userClickedOnAdd, setUserClickedOnAdd] = useState(false);
  const [userClickedOnApply, setUserClickedOnApply] = useState(false);
  const scrollToErrorRef = useRef<Ref | null>(null);

  const {
    fields: roomConfigurationFields,
    append,
    remove,
  } = useFieldArray({ name: "roomConfigurations", control });

  const [unassignedOccupancy] = watch(["unassignedOccupancy"]);

  useEffect(() => {
    const { unsubscribe } = watch((values, { name }) => {
      if (name && values.roomConfigurations && name === "roomConfigurations") {
        const unassignedOccupancy = getUnassignedOccupancy(
          values.roomConfigurations,
          totalAdultsCount,
          totalChildrenCount,
        );

        setValue("unassignedOccupancy", unassignedOccupancy, setValueOptions);
      }
    });
    return () => unsubscribe();
  }, [setValue, totalAdultsCount, totalChildrenCount, watch]);

  useEffect(() => {
    trigger();
  }, [trigger]);

  const { error: roomConfigError, index: roomConfigErrorIndex } =
    getRoomConfigurationError(formState.errors, roomConfigurationFields.length);

  useEffect(() => {
    if (
      (!userClickedOnAdd && !userClickedOnApply) ||
      roomConfigErrorIndex !== openedRoomIndex
    ) {
      return;
    }

    scrollToFirstError(scrollToErrorRef.current);
    scrollToErrorRef.current = null;
  }, [
    openedRoomIndex,
    userClickedOnAdd,
    userClickedOnApply,
    roomConfigErrorIndex,
  ]);

  const canAddRoom =
    !formState.errors.roomConfigurations && unassignedOccupancy.adults > 0;

  const addRoomTooltip =
    roomConfigError?.adults?.message ??
    roomConfigError?.children?.message ??
    (unassignedOccupancy.adults === 0 ? i18n.autoOccupancy.adultsRequired : "");

  const applyTooltip =
    !formState.isValid &&
    (formState.errors.roomConfigurations
      ? i18n.autoOccupancy.invalidOccupancy
      : (formState.errors.unassignedOccupancy?.adults?.message ??
        formState.errors.unassignedOccupancy?.children?.message ??
        ""));

  const setErrorRef = useCallback(() => {
    if (roomConfigErrorIndex >= 0) {
      setOpenedRoomIndex(roomConfigErrorIndex);
    }

    const errorRef =
      roomConfigError?.roomTypeId?.ref ??
      roomConfigError?.adults?.ref ??
      roomConfigError?.children?.ref ??
      roomConfigError?.childAgeIndices?.ref;

    if (errorRef) {
      if (roomConfigErrorIndex === openedRoomIndex) {
        scrollToFirstError(errorRef);
      } else {
        scrollToErrorRef.current = errorRef;
      }
    }
  }, [
    openedRoomIndex,
    roomConfigError?.adults?.ref,
    roomConfigError?.childAgeIndices?.ref,
    roomConfigError?.children?.ref,
    roomConfigError?.roomTypeId?.ref,
    roomConfigErrorIndex,
  ]);

  return (
    <>
      <input
        hidden
        disabled
        {...register(`unassignedOccupancy.adults`, {
          validate: (value) =>
            value === 0 || i18n.autoOccupancy.notAllGuestsAssigned,
        })}
      />
      <input
        hidden
        disabled
        {...register(`unassignedOccupancy.children`, {
          validate: (value) =>
            value === 0 || i18n.autoOccupancy.notAllGuestsAssigned,
        })}
      />
      <div className={styles.remainingGuests}>
        <RemainingGuestGroup type="adults" count={unassignedOccupancy.adults} />
        <RemainingGuestGroup
          type="children"
          count={unassignedOccupancy.children}
        />
      </div>
      <MobileModalContent>
        {roomConfigurationFields.map((roomConfiguration, index) => (
          <RoomComponent
            key={roomConfiguration.id}
            index={index}
            open={index === openedRoomIndex}
            userClickedOnAdd={userClickedOnAdd}
            userClickedOnApply={userClickedOnApply}
            onOpen={setOpenedRoomIndex}
            onDelete={() => {
              remove(index);
              setOpenedRoomIndex((prev) => (prev >= index ? prev - 1 : prev));
            }}
          />
        ))}
      </MobileModalContent>
      <ActionFooter>
        {roomConfigurationFields.length < maxRooms && (
          <>
            <Button
              disabledStyle={!canAddRoom}
              buttonProps={{
                ref: addRoomRef,
                onClick: () => {
                  setUserClickedOnApply(false);

                  if (!canAddRoom) {
                    setUserClickedOnAdd(true);
                    if (addRoomTooltip) {
                      setAddRoomButtonTooltipVisible(true);
                    }

                    setErrorRef();
                    return;
                  }

                  setUserClickedOnAdd(false);
                  append({
                    roomTypeId: null,
                    adults: 0,
                    children: 0,
                    childAgeIndices: [],
                  });
                  trigger("roomConfigurations");
                  setOpenedRoomIndex(roomConfigurationFields.length);
                },
              }}
              layout="secondary"
              glyph={Plus}
            >
              {i18n.autoOccupancy.roomIndex(
                site.room_term,
                roomConfigurationFields.length + 1,
              )}
            </Button>
            {addRoomTooltip && (
              <Tooltip
                isVisible={addRoomButtonTooltipVisible}
                anchor={addRoomRef.current}
                title={addRoomTooltip}
                onClose={() => setAddRoomButtonTooltipVisible(false)}
                duration={6000}
                className={styles.tooltip}
                onIsPositionChange={(floating) => {
                  floating.scrollIntoView({
                    block: "nearest",
                    behavior: "smooth",
                  });
                }}
              />
            )}
          </>
        )}
        <Button
          disabledStyle={!formState.isValid}
          buttonProps={{
            ref: applyRef,
            type: formState.isValid ? "submit" : "button",
            onClick: formState.isValid
              ? undefined
              : () => {
                  setUserClickedOnApply(true);
                  setUserClickedOnAdd(false);
                  setApplyButtonTooltipVisible(true);
                  setErrorRef();
                },
          }}
          layout="primary"
        >
          {i18n.autoOccupancy.apply}
        </Button>
        {applyTooltip && (
          <Tooltip
            isVisible={applyButtonTooltipVisible}
            anchor={applyRef.current}
            title={applyTooltip}
            onClose={() => setApplyButtonTooltipVisible(false)}
            duration={6000}
            className={styles.tooltip}
            onIsPositionChange={(floating) => {
              floating.scrollIntoView({ block: "nearest", behavior: "smooth" });
            }}
          />
        )}
      </ActionFooter>
    </>
  );
};

interface RemainingGuestGroupProps {
  type: "adults" | "children";
  count: number;
}

const RemainingGuestGroup = ({ type, count }: RemainingGuestGroupProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);

  const Icon = type === "adults" ? Guests : Children;
  const iconTitle =
    type === "adults" ? i18n.autoOccupancy.adults : i18n.autoOccupancy.children;

  return (
    <div className={styles.remainingGuestGroup}>
      <Icon
        className={styles.remainingGuestGroupIcon}
        title={iconTitle}
        role="img"
      />
      <div className={styles.number}>
        <div className={styles.countWrapper}>
          <Headline
            className={styles.count}
            size={1}
            as="div"
            title={Math.floor(count / 10)}
          />
        </div>
        <div className={styles.countWrapper}>
          <Headline
            className={styles.count}
            size={1}
            as="div"
            title={count % 10}
          />
        </div>
      </div>
    </div>
  );
};

interface RoomProps {
  index: number;
  open: boolean;
  userClickedOnAdd: boolean;
  userClickedOnApply: boolean;
  onOpen: (index: number) => void;
  onDelete: () => void;
}

const RoomComponent = ({
  index,
  open,
  userClickedOnAdd,
  userClickedOnApply,
  onOpen,
  onDelete,
}: RoomProps) => {
  const language = useBookingStore((state) => state.language);
  const i18n = translate(language);
  const { roomTypes, roomTypesMap } = useRoomTypes();
  const { register, watch, getValues } = useIndividualAssignmentFormContext();
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);

  const modalIdentifier = `IndividualAssignmentForm_room_detail`;
  const toggleRoomModal = useModalHistoryToggle(modalIdentifier);
  const showRoomModal = useModalHistoryStore(isModalActive(modalIdentifier));
  const modalRoomTypeId: string =
    useModalHistoryStore(selectModalIdentifierValue(modalIdentifier)) ?? "";

  const roomDetail = roomTypesMap?.[modalRoomTypeId];

  const [roomConfigurations, roomTypeId, adults, children] = watch([
    `roomConfigurations`,
    `roomConfigurations.${index}.roomTypeId`,
    `roomConfigurations.${index}.adults`,
    `roomConfigurations.${index}.children`,
  ]);

  const alreadyAssignedOccupancy = roomConfigurations.reduce(
    (data, roomConfiguration, i) => {
      if (index !== i) {
        data.adults += roomConfiguration.adults;
        data.children += roomConfiguration.children;
      }

      return data;
    },
    { adults: 0, children: 0 },
  );

  const roomType = roomTypesMap?.[roomTypeId ?? ""];
  const guestCount = adults + children;
  const roomSummaryParts: string[] = [];
  if (guestCount > 0) {
    roomSummaryParts.push(i18n.autoOccupancy.guestsCountSummary(guestCount));
  }
  if (roomType) {
    roomSummaryParts.push(roomType.name);
  }

  const visualIndex = index + 1;

  const toggleRoomDetail = (room: Room | null) => {
    if (room?.id === modalRoomTypeId) {
      return;
    }
    toggleRoomModal(!!room, room?.id);
  };

  return (
    <>
      <input
        hidden
        disabled
        {...register(`roomConfigurations.${index}.roomTypeId`, {
          required: true,
        })}
      />
      <input
        hidden
        disabled
        {...register(`roomConfigurations.${index}.adults`, {
          required: true,
          validate: (value) => {
            const [roomTypeId, children] = getValues([
              `roomConfigurations.${index}.roomTypeId`,
              `roomConfigurations.${index}.children`,
            ]);
            const { minAdults, maxAdults } = getOccupancyLimits(
              roomTypesMap?.[roomTypeId ?? ""],
              autoOccupancy,
              { adults, children },
              alreadyAssignedOccupancy,
            );

            if (value < minAdults) {
              return i18n.autoOccupancy.minOccupancyNotReached[
                site.guest_interaction
              ];
            }

            return value >= minAdults && value <= maxAdults;
          },
        })}
      />
      <input
        hidden
        disabled
        {...register(`roomConfigurations.${index}.children`, {
          validate: (value) => {
            const [roomTypeId, adults] = getValues([
              `roomConfigurations.${index}.roomTypeId`,
              `roomConfigurations.${index}.adults`,
            ]);
            const { minChildren, maxChildren } = getOccupancyLimits(
              roomTypesMap?.[roomTypeId ?? ""],
              autoOccupancy,
              { adults, children },
              alreadyAssignedOccupancy,
            );

            if (value < minChildren) {
              return i18n.autoOccupancy.minOccupancyNotReached[
                site.guest_interaction
              ];
            }

            return value >= minChildren && value <= maxChildren;
          },
        })}
      />
      <input
        hidden
        disabled
        {...register(`roomConfigurations.${index}.childAgeIndices`, {
          validate: (value) => {
            const [children] = getValues([
              `roomConfigurations.${index}.children`,
            ]);
            return value.length === children;
          },
        })}
      />
      <CollapsibleRoomOccupancy
        index={index}
        open={open}
        onOpen={onOpen}
        openText={i18n.autoOccupancy.roomIndex(site.room_term, visualIndex)}
        toggleDisabled={open}
        summary={
          !open && roomSummaryParts.length > 0
            ? `: ${roomSummaryParts.join(", ")}`
            : undefined
        }
        hideActions={open}
        onDelete={onDelete}
      >
        <div className={styles.roomTypes}>
          <RoomDetailModal
            room={roomDetail}
            open={showRoomModal}
            onClose={() => toggleRoomDetail(null)}
          />
          {roomTypes?.map((roomType) => (
            <RoomTypeOption
              key={roomType.id}
              configurationIndex={index}
              roomType={roomType}
              alreadyAssignedOccupancy={alreadyAssignedOccupancy}
              userClickedOnAdd={userClickedOnAdd}
              userClickedOnApply={userClickedOnApply}
              setRoomDetail={toggleRoomDetail}
            />
          ))}
        </div>
      </CollapsibleRoomOccupancy>
    </>
  );
};

interface RoomTypeOptionProps {
  configurationIndex: number;
  roomType: Room;
  alreadyAssignedOccupancy: { adults: number; children: number };
  userClickedOnAdd: boolean;
  userClickedOnApply: boolean;
  setRoomDetail: (roomType: Room | null) => void;
}

const RoomTypeOption = ({
  configurationIndex,
  roomType,
  alreadyAssignedOccupancy,
  userClickedOnAdd,
  userClickedOnApply,
  setRoomDetail,
}: RoomTypeOptionProps) => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const totalAdultsCount = autoOccupancy?.adults ?? 0;
  const totalChildrenCount = autoOccupancy?.children.length ?? 0;
  const i18n = translate(language);
  const { control, setValue, watch, trigger } =
    useIndividualAssignmentFormContext();

  const [roomTypeId, adults, children, childAgeIndices, unassignedOccupancy] =
    watch([
      `roomConfigurations.${configurationIndex}.roomTypeId`,
      `roomConfigurations.${configurationIndex}.adults`,
      `roomConfigurations.${configurationIndex}.children`,
      `roomConfigurations.${configurationIndex}.childAgeIndices`,
      "unassignedOccupancy",
    ]);

  useEffect(() => {
    const { unsubscribe } = watch((values, { name }) => {
      if (
        name &&
        values.roomConfigurations &&
        [
          `roomConfigurations.${configurationIndex}.adults`,
          `roomConfigurations.${configurationIndex}.children`,
        ].includes(name)
      ) {
        const unassignedOccupancy = getUnassignedOccupancy(
          values.roomConfigurations,
          totalAdultsCount,
          totalChildrenCount,
        );

        setValue("unassignedOccupancy", unassignedOccupancy, setValueOptions);
      }
    });
    return () => unsubscribe();
  }, [
    configurationIndex,
    setValue,
    totalAdultsCount,
    totalChildrenCount,
    watch,
  ]);

  const isDisabled = !!roomTypeId && roomType.id !== roomTypeId;

  const { maxAdults, maxChildren } = getOccupancyLimits(
    roomType,
    autoOccupancy,
    { adults, children },
    alreadyAssignedOccupancy,
  );

  return (
    <div>
      <Button
        layout="link"
        buttonProps={{
          className: styles.roomTypeName,
          disabled: isDisabled,
          onClick: () => setRoomDetail(roomType),
        }}
      >
        {roomType.name}
      </Button>
      <RoomInfos
        room={roomType}
        className={clsx({ [styles.disabledRoomInfos]: isDisabled })}
      />
      <div className={styles.inputs}>
        <Controller
          name={`roomConfigurations.${configurationIndex}.options.${roomType.id}.adults`}
          control={control}
          rules={{ min: 0, max: maxAdults }}
          disabled={isDisabled}
          render={({ formState: { errors } }) => (
            <NumberInput
              title={i18n.autoOccupancy.adults}
              value={isDisabled ? 0 : adults}
              min={0}
              max={maxAdults}
              disabled={isDisabled}
              invalid={
                (userClickedOnAdd &&
                  !!errors.roomConfigurations?.[configurationIndex]?.adults) ||
                (userClickedOnApply &&
                  !!errors.roomConfigurations?.[configurationIndex])
              }
              description={i18n.autoOccupancy.adultsInfo}
              maxReachedTooltip={
                unassignedOccupancy.adults === 0
                  ? i18n.autoOccupancy.maxAdultsReached
                  : isDisabled
                    ? i18n.autoOccupancy.addAdditionalRoom[
                        site.guest_interaction
                      ]
                    : i18n.autoOccupancy.maxOccupancyReached[
                        site.guest_interaction
                      ]
              }
              onChange={(value) => {
                const roomTypeId = value + children ? roomType.id : null;
                setValue(
                  `roomConfigurations.${configurationIndex}.roomTypeId`,
                  roomTypeId,
                  setValueOptions,
                );
                setValue(
                  `roomConfigurations.${configurationIndex}.adults`,
                  value,
                  setValueOptions,
                );
                trigger([
                  `unassignedOccupancy.adults`,
                  `roomConfigurations.${configurationIndex}.children`,
                ]);
              }}
            />
          )}
        />
        {totalChildrenCount > 0 && (
          <Controller
            name={`roomConfigurations.${configurationIndex}.options.${roomType.id}.children`}
            control={control}
            rules={{ min: 0, max: maxChildren }}
            render={({ formState: { errors } }) => (
              <NumberInput
                title={i18n.autoOccupancy.children}
                value={isDisabled ? 0 : children}
                min={0}
                max={maxChildren}
                disabled={isDisabled}
                invalid={
                  (userClickedOnAdd &&
                    (!!errors.roomConfigurations?.[configurationIndex]
                      ?.children ||
                      !!errors.roomConfigurations?.[configurationIndex]
                        ?.childAgeIndices)) ||
                  (userClickedOnApply &&
                    !!errors.roomConfigurations?.[configurationIndex])
                }
                description={i18n.autoOccupancy.childrenInfo(
                  site.children_min_age,
                )}
                maxReachedTooltip={
                  unassignedOccupancy.children === 0
                    ? i18n.autoOccupancy.maxChildrenReached
                    : isDisabled
                      ? i18n.autoOccupancy.addAdditionalRoom[
                          site.guest_interaction
                        ]
                      : i18n.autoOccupancy.maxOccupancyReached[
                          site.guest_interaction
                        ]
                }
                onChange={(value) => {
                  const roomTypeId = value + adults > 0 ? roomType.id : null;
                  setValue(
                    `roomConfigurations.${configurationIndex}.roomTypeId`,
                    roomTypeId,
                    setValueOptions,
                  );
                  setValue(
                    `roomConfigurations.${configurationIndex}.children`,
                    value,
                    setValueOptions,
                  );
                  if (value < childAgeIndices.length) {
                    setValue(
                      `roomConfigurations.${configurationIndex}.childAgeIndices`,
                      childAgeIndices.slice(0, value),
                      setValueOptions,
                    );
                  }

                  trigger([
                    `unassignedOccupancy.children`,
                    `roomConfigurations.${configurationIndex}.adults`,
                    `roomConfigurations.${configurationIndex}.childAgeIndices`,
                  ]);
                }}
              >
                {!isDisabled && (
                  <ChildAgeCheckboxGroup
                    configurationIndex={configurationIndex}
                  />
                )}
              </NumberInput>
            )}
          />
        )}
      </div>
    </div>
  );
};

interface ChildAgeCheckboxGroupProps {
  configurationIndex: number;
}

interface ChildAgeOption {
  index: number;
  value: number;
  disabled: boolean;
  checked: boolean;
}

const ChildAgeCheckboxGroup = ({
  configurationIndex,
}: ChildAgeCheckboxGroupProps) => {
  const language = useBookingStore((state) => state.language);
  const autoOccupancy = useBookingStore((state) => state.autoOccupancy);
  const i18n = translate(language);
  const totalChildren = autoOccupancy?.children ?? [];
  const { control, setValue, watch, trigger } =
    useIndividualAssignmentFormContext();

  const [roomConfigurations, roomTypeId, childAgeIndices, childrenCount] =
    watch([
      `roomConfigurations`,
      `roomConfigurations.${configurationIndex}.roomTypeId`,
      `roomConfigurations.${configurationIndex}.childAgeIndices`,
      `roomConfigurations.${configurationIndex}.children`,
    ]);

  if (childrenCount === 0 || !roomTypeId) {
    return null;
  }

  const assignedChildAgesToOther = roomConfigurations.reduce(
    (childAges: number[], roomConfiguration, index) => {
      if (configurationIndex === index) {
        return childAges;
      }

      childAges.push(...roomConfiguration.childAgeIndices);
      return childAges;
    },
    [],
  );

  const assignedChildAges = [...childAgeIndices];
  const options: ChildAgeOption[] = totalChildren.map((value, index) => {
    const assignedChildAgesToOtherIndex =
      assignedChildAgesToOther.indexOf(index);
    const alreadyAssigned = assignedChildAgesToOtherIndex !== -1;
    if (alreadyAssigned) {
      assignedChildAgesToOther.splice(assignedChildAgesToOtherIndex, 1);
    }

    const assignedChildAgesIndex = assignedChildAges.indexOf(index);
    const checked = assignedChildAgesIndex !== -1;
    if (checked) {
      assignedChildAges.splice(assignedChildAgesIndex, 1);
    }

    return {
      index,
      value,
      checked,
      disabled:
        alreadyAssigned ||
        (childAgeIndices.length >= childrenCount && !checked),
    };
  });

  const handleChange = (option: ChildAgeOption) => {
    const newArray = [...childAgeIndices];

    const index = newArray.indexOf(option.index);
    if (index !== -1 && option.checked) {
      newArray.splice(index, 1);
    } else {
      newArray.push(option.index);
    }

    setValue(
      `roomConfigurations.${configurationIndex}.childAgeIndices`,
      newArray,
      setValueOptions,
    );

    trigger([
      `unassignedOccupancy.children`,
      `roomConfigurations.${configurationIndex}.children`,
    ]);
  };

  return (
    <div className={styles.childrenContainer}>
      <Controller
        name={`roomConfigurations.${configurationIndex}.options.${roomTypeId}.childAgeIndices`}
        control={control}
        render={() => (
          <>
            {options.map((option, index) => (
              <CheckboxButton
                key={index}
                label={i18n.childAgeSelect.childrenAge(option.value)}
                name={`roomConfigurations.${configurationIndex}.options.${roomTypeId}.childAgeIndices`}
                checked={option.checked}
                onChange={() => handleChange(option)}
                disabled={option.disabled}
              />
            ))}
          </>
        )}
      />
    </div>
  );
};

export default IndividualAssignmentForm;
