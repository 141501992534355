import { ReactNode } from "react";
import { useShallow } from "zustand/react/shallow";
import { BookingStep, RoomConfiguration } from "../../@types";
import {
  isAutoOfferRoomConfiguration,
  selectCurrentRoomConfiguration,
  useBookingStore,
  useBookingStoreSetStep,
} from "../../hooks/useBookingStore";
import { Rates, useAutoOffers, useOffers } from "../../http/offer";
import { RatePlan, useRatePlans } from "../../http/ratePlan";
import Error from "../../pages/Error";
import PageWithHeader from "../../pages/PageWithHeader";
import CenterWrapper from "../../ui/CenterWrapper";
import Skeleton from "../../ui/Skeleton";
import RatePlanDetailModal from "./RatePlanDetailModal";

interface RatePlanDetailChildrenProps {
  ratePlan: RatePlan | undefined;
  rate: Rates | undefined;
  bookingStep: BookingStep;
  isLoading: boolean;
}

interface RatePlanDetailProps {
  currentRoomConfiguration: RoomConfiguration;
  children: (childrenProps: RatePlanDetailChildrenProps) => ReactNode;
}

const RatePlanDetail = () => {
  const isAutoOffer = useBookingStore(isAutoOfferRoomConfiguration);
  const currentRoomConfiguration = useBookingStore(
    useShallow(selectCurrentRoomConfiguration),
  );
  const updateRoomConfiguration = useBookingStore(
    (state) => state.updateRoomConfiguration,
  );
  const setStep = useBookingStoreSetStep();

  if (!currentRoomConfiguration) {
    return <Error />;
  }

  const Component = isAutoOffer
    ? RatePlanDetailAutoOffers
    : RatePlanDetailOffers;

  return (
    <Component currentRoomConfiguration={currentRoomConfiguration}>
      {({ ratePlan, rate, bookingStep, isLoading }) => (
        <PageWithHeader>
          <CenterWrapper>
            {ratePlan && rate && (
              <RatePlanDetailModal
                ratePlan={ratePlan}
                boardTypes={rate.board_types}
                open
                onClose={() => {
                  updateRoomConfiguration({ ratePlanId: null });
                  setStep(bookingStep);
                }}
              />
            )}
            {isLoading && <Skeleton desktopItems={1} mobileItems={1} />}
          </CenterWrapper>
        </PageWithHeader>
      )}
    </Component>
  );
};

const RatePlanDetailOffers = ({
  currentRoomConfiguration,
  children,
}: RatePlanDetailProps) => {
  const { offersMap, isLoading: isLoadingOffers } = useOffers();
  const { ratePlansMap, isLoading: isLoadingRatePlans } = useRatePlans();

  const offer =
    offersMap?.[
      `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
    ];
  const ratePlan = ratePlansMap?.[currentRoomConfiguration.ratePlanId ?? ""];
  const rate = offer?.rates.find((x) => x.rate_plan_id === ratePlan?.id);

  return children({
    ratePlan,
    rate,
    bookingStep: BookingStep.RatePlans,
    isLoading: isLoadingOffers || isLoadingRatePlans,
  });
};

const RatePlanDetailAutoOffers = ({
  currentRoomConfiguration,
  children,
}: RatePlanDetailProps) => {
  const autoOfferIndex = useBookingStore((state) => state.autoOfferIndex);
  const { ratePlansMap, isLoading: isLoadingRatePlans } = useRatePlans();
  const { autoOffers, isLoading: isLoadingOffers } = useAutoOffers();

  if (autoOfferIndex === null) {
    return <Error />;
  }

  const ratePlan = ratePlansMap?.[currentRoomConfiguration.ratePlanId ?? ""];
  const rate = autoOffers?.[autoOfferIndex]?.roomsMap[
    `${currentRoomConfiguration.roomIndex}_${currentRoomConfiguration.roomTypeId}`
  ]?.rates.find((x) => x.rate_plan_id === currentRoomConfiguration.ratePlanId);

  return children({
    ratePlan,
    rate,
    bookingStep: BookingStep.RatePlansAutoOffer,
    isLoading: isLoadingOffers || isLoadingRatePlans,
  });
};

export default RatePlanDetail;
